import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, is_staff, ...rest }) => {
  if (is_staff !== null) {
    return (
      <Route
        {...rest}
        render={(props) =>
          is_staff ? <Component {...props} /> : <Redirect to="/routes/" />
        }
      />
    );
  }
  return <div></div>;
};

export default PrivateRoute;
