import React, { useState } from 'react';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';

export const ButtonSort = ({
  type,
  iType,
  children,
  onChange,
  name,
  ...rest
}) => {
  const [value, setValue] = useState('');
  const [isLight, setisLight] = useState(' is-light');
  const [icon, setIcon] = useState('');
  const handleClick = () => {
    if (value === '' || value === `-${name}`) {
      setValue(name);
      onChange(name, name);
      setIcon('+');
    }
    if (value === name) {
      setValue(`-${name}`);
      onChange(name, `-${name}`);
      setIcon('-');
    }
    setisLight('');
  };

  const removeFilter = () => {
    setValue('');
    setisLight(' is-light');
    onChange(name, '');
    setIcon('');
  };

  if (icon === '+') {
    return (
      <>
        <span className={'tag ' + iType + isLight} onClick={handleClick}>
          {icon === '+' && <FontAwesome name="minus" />}
          {children}
        </span>
        {!isLight && (
          <span className={'tag ' + iType} onClick={removeFilter}>
            <FontAwesome name="times" />
          </span>
        )}
      </>
    );
  } else {
    return (
      <>
        <span className={'tag ' + type + isLight} onClick={handleClick}>
          {icon === '-' && <FontAwesome name="plus" />}
          {children}
        </span>
        {!isLight && (
          <span className={'tag ' + type} onClick={removeFilter}>
            <FontAwesome name="times" />
          </span>
        )}
      </>
    );
  }
};
