/** @format */

import React from 'react';

export default function BodyTable(props) {
    const { data } = props;
    const parcial = data.slice(0, 20);

    return (
        <tbody>
            {parcial.map((row, i) => (
                <tr key={i}>
                    {row.data.map((td, i) => (
                        <td key={i}>{td}</td>
                    ))}
                </tr>
            ))}
        </tbody>
    );
}
