import React, { useEffect, useState } from 'react';

import { Municipality } from './Municipality';

export const Municipalities = ({ data, ...rest }) => {
  const [municipalities, setMunicipalities] = useState([]);

  useEffect(() => {
    if (data) {
      const newMunicipalities = data.features.sort((a, b) => {
        return b.properties.percentile - a.properties.percentile;
      });
      setMunicipalities(newMunicipalities);
    }
  }, [data]);

  return municipalities.map((municipality) => (
    <Municipality
      key={municipality.properties.gid}
      percentil={municipality.properties.percentile}
      goverment={municipality.properties.government}
      name={municipality.properties.name}
      duration={municipality.properties.duration}
    />
  ));
};
