import React from "react";
import { Redirect } from "react-router-dom";

const Home = ({ is_staff }) => {
  if (is_staff) {
    return <Redirect to="/incidentes/" />;
  } else {
    return <Redirect to="/estudio/" />;
  }
};

export default Home;
