import React, { useEffect, useState } from 'react';
import ReactMapGL, {
  Layer,
  Marker,
  Popup,
  Source,
  WebMercatorViewport,
} from 'react-map-gl';
import AsyncSelect from 'react-select/async';
import { Pin } from './Pin';

import 'mapbox-gl/dist/mapbox-gl.css';
import './Map.css';
import '../print.css';

import MapboxService from '../../services/MapboxService';

const mapboxService = new MapboxService();

const AccessToken =
  'pk.eyJ1IjoiYW55bWV4IiwiYSI6ImNrZW0wNTgybjJjNzcyeW16eG0xNW9tczcifQ.4o1Et26arVQ7SlgVosezFA';

const routeLayer = {
  id: 'lines',
  type: 'line',
  source: 'lines',
  paint: {
    'line-width': 5,
    'line-color': ['get', 'color'],
  },
};

export const zoneLayer = {
  id: 'zones',
  type: 'fill',
  paint: {
    'fill-color': {
      property: 'percentile',
      stops: [
        [-10, '#2166ac'],
        [0, '#67a9cf'],
        [5, '#d1e5f0'],
        [10, '#fddbc7'],
        [20, '#ef8a62'],
        [100, '#b2182b'],
      ],
    },
    'fill-opacity': 0.7,
  },
};

export const Map = ({
  bbox,
  routes,
  onClickMap,
  markers,
  zones,
  onMarkerDragEnd,
  draggable,
}) => {
  const [viewport, setViewport] = useState({
    width: 400,
    height: 400,
    latitude: 23.783596493387073,
    longitude: -102.33434483304082,
    zoom: 4.480042425167121,
  });
  useEffect(() => {
    if (bbox) {
      if (bbox.minLat) {
        const v = new WebMercatorViewport(viewport);
        const { zoom, latitude, longitude } = v.fitBounds(
          [
            [bbox.minLng, bbox.minLat],
            [bbox.maxLng, bbox.maxLat],
          ],
          {
            padding: 160,
          }
        );

        const newViewport = {
          ...viewport,
          latitude,
          longitude,
          zoom,
        };

        setViewport(newViewport);
      }
    }
    // eslint-disable-next-line
  }, [bbox]);

  const handleChange = (option) => {
    let z = 15;
    if (!(option.bbox === undefined)) {
      const [minLng, minLat, maxLng, maxLat] = option.bbox;
      const v = new WebMercatorViewport(viewport);
      const { zoom } = v.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: 40,
        }
      );
      z = zoom;
    }

    const newViewport = {
      ...viewport,
      longitude: option.lon,
      latitude: option.lat,
      zoom: z,
    };

    setViewport(newViewport);
  };

  const addLabels = (search) => {
    const options = search.features.map((feture) => {
      const option = {
        name: feture.place_name,
        label: feture.place_name,
        lon: feture.center[0],
        lat: feture.center[1],
        bbox: feture.bbox,
      };

      return option;
    });

    return options;
  };

  const loadOptions = async (search, callback) => {
    const searchValue = search.replace(/\W/g, '%20');
    const response = await mapboxService.search(searchValue);
    callback(addLabels(response));
  };

  const [searchValue, setSearchValue] = useState('');
  const handleInputSearch = (newValue) => {
    setSearchValue(newValue);
  };

  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [y, setY] = useState(null);
  const [x, setX] = useState(null);
  const handleHover = (e) => {
    const {
      features,
      srcEvent: { offsetX, offsetY },
    } = e;
    const newHoveredFeature =
      features && features.find((f) => f.layer.id === 'zones');

    setHoveredFeature(newHoveredFeature);
    setX(offsetX);
    setY(offsetY);
  };

  const renderTooltip = () => {
    return (
      hoveredFeature && (
        <div className="tooltip" style={{ left: x, top: y }}>
          <div>Municipio: {hoveredFeature.properties.name}</div>
          <div>Estado: {hoveredFeature.properties.government}</div>
          <div>
            {Math.trunc(hoveredFeature.properties.percentile)} incidentes sobre
            la mediana
          </div>
          <div>
            Tiempo:
            {Math.trunc(hoveredFeature.properties.duration / 60)}min
          </div>
        </div>
      )
    );
  };

  const [popupInfo, setPopupInfo] = useState(null);

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <ReactMapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/anymex/cklv2nyjv3gfr17l5c1togwne"
        onViewportChange={(viewport) => setViewport(viewport)}
        mapboxApiAccessToken={AccessToken}
        onClick={onClickMap}
        onHover={handleHover}
      >
        {zones && (
          <>
            <Source type="geojson" data={zones}>
              <Layer {...zoneLayer} />
            </Source>
            {renderTooltip()}
          </>
        )}
        {routes && (
          <Source type="geojson" data={routes}>
            <Layer {...routeLayer} />
          </Source>
        )}
        {markers &&
          markers.map((marker) => (
            <Marker
              key={marker.name}
              longitude={marker.longitude}
              latitude={marker.latitude}
              offsetTop={-20}
              offsetLeft={-10}
              onClick={() => setPopupInfo(marker)}
              draggable={draggable}
              onDragEnd={
                draggable
                  ? (event) => onMarkerDragEnd(event, marker.name)
                  : null
              }
            >
              <Pin size={20} />
            </Marker>
          ))}
        {popupInfo && (
          <Popup
            tipSize={5}
            anchor="top"
            longitude={popupInfo.longitude}
            latitude={popupInfo.latitude}
            closeOnClick={false}
            onClose={setPopupInfo}
          >
            {popupInfo.category}
            {popupInfo.name}
          </Popup>
        )}
      </ReactMapGL>
      <div className="sidebarStyle no-print">
        <AsyncSelect
          value={searchValue}
          onInputChange={handleInputSearch}
          placeholder="Buscar dirección"
          cacheOptions
          loadOptions={loadOptions}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
