import apiClient from '../utils/apiClient';
import {
  GET_GROUP,
  GET_GEOMETRIES,
  GET_TIMETABLES,
} from '../types/estudiosTypes';

export const getReportData = (groupId) => async (dispatch) => {
  let group = [];
  try {
    const response = await apiClient.get(`route/?group=${groupId}`);
    group = [...group, ...response.data.results];
  } catch (e) {
    console.log(e);
  }

  dispatch({ type: GET_GROUP, group });

  let routes = [];
  for (const route of group) {
    try {
      const response = await apiClient.get(`route/${route.pk}/`);
      const pk = await response.data.pk;
      const raw_municipalities = await response.data.municipalities;
      const municipalities = raw_municipalities.reduce((a, b) => {
        return {
          type: 'FeatureCollection',
          features: [...a.features, ...b.features],
        };
      });

      const geometry = await response.data.geometry;
      routes = [...routes, { pk, municipalities, geometry }];
    } catch (e) {
      console.log(e);
    }
  }

  dispatch({ type: GET_GEOMETRIES, routes });

  let timetables = [];
  for (const route of group) {
    try {
      const response = await apiClient.get(
        `route/${route.pk}/get_timetable_days/`
      );
      timetables = [...timetables, response.data];
    } catch (e) {
      console.log(e);
    }
  }

  dispatch({ type: GET_TIMETABLES, timetables });
};
