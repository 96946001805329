/** @format */

var municipalities = [
    { value: 1001, label: 'Aguascalientes' },
    { value: 1002, label: 'Asientos' },
    { value: 1003, label: 'Calvillo' },
    { value: 1004, label: 'Cosío' },
    { value: 1005, label: 'Jesús María' },
    { value: 1006, label: 'Pabellón de Arteaga' },
    { value: 1007, label: 'Rincón de Romos' },
    { value: 1008, label: 'San José de Gracia' },
    { value: 1009, label: 'Tepezalá' },
    { value: 1010, label: 'El Llano' },
    { value: 1011, label: 'San Francisco de los Romo' },
    { value: 2001, label: 'Ensenada' },
    { value: 2002, label: 'Mexicali' },
    { value: 2003, label: 'Tecate' },
    { value: 2004, label: 'Tijuana' },
    { value: 2005, label: 'Playas de Rosarito' },
    { value: 3001, label: 'Comondú' },
    { value: 3002, label: 'Mulegé' },
    { value: 3003, label: 'La Paz' },
    { value: 3008, label: 'Los Cabos' },
    { value: 3009, label: 'Loreto' },
    { value: 4001, label: 'Calkiní' },
    { value: 4002, label: 'Campeche' },
    { value: 4003, label: 'Carmen' },
    { value: 4004, label: 'Champotón' },
    { value: 4005, label: 'Hecelchakán' },
    { value: 4006, label: 'Hopelchén' },
    { value: 4007, label: 'Palizada' },
    { value: 4008, label: 'Tenabo' },
    { value: 4009, label: 'Escárcega' },
    { value: 4010, label: 'Calakmul' },
    { value: 4011, label: 'Candelaria' },
    { value: 5001, label: 'Abasolo' },
    { value: 5002, label: 'Acuña' },
    { value: 5003, label: 'Allende' },
    { value: 5004, label: 'Arteaga' },
    { value: 5005, label: 'Candela' },
    { value: 5006, label: 'Castaños' },
    { value: 5007, label: 'Cuatro Ciénegas' },
    { value: 5008, label: 'Escobedo' },
    { value: 5009, label: 'Francisco I. Madero' },
    { value: 5010, label: 'Frontera' },
    { value: 5011, label: 'General Cepeda' },
    { value: 5012, label: 'Guerrero' },
    { value: 5013, label: 'Hidalgo' },
    { value: 5014, label: 'Jiménez' },
    { value: 5015, label: 'Juárez' },
    { value: 5016, label: 'Lamadrid' },
    { value: 5017, label: 'Matamoros' },
    { value: 5018, label: 'Monclova' },
    { value: 5019, label: 'Morelos' },
    { value: 5020, label: 'Múzquiz' },
    { value: 5021, label: 'Nadadores' },
    { value: 5022, label: 'Nava' },
    { value: 5023, label: 'Ocampo' },
    { value: 5024, label: 'Parras' },
    { value: 5025, label: 'Piedras Negras' },
    { value: 5026, label: 'Progreso' },
    { value: 5027, label: 'Ramos Arizpe' },
    { value: 5028, label: 'Sabinas' },
    { value: 5029, label: 'Sacramento' },
    { value: 5030, label: 'Saltillo' },
    { value: 5031, label: 'San Buenaventura' },
    { value: 5032, label: 'San Juan de Sabinas' },
    { value: 5033, label: 'San Pedro' },
    { value: 5034, label: 'Sierra Mojada' },
    { value: 5035, label: 'Torreón' },
    { value: 5036, label: 'Viesca' },
    { value: 5037, label: 'Villa Unión' },
    { value: 5038, label: 'Zaragoza' },
    { value: 6001, label: 'Armería' },
    { value: 6002, label: 'Colima' },
    { value: 6003, label: 'Comala' },
    { value: 6004, label: 'Coquimatlán' },
    { value: 6005, label: 'Cuauhtémoc' },
    { value: 6006, label: 'Ixtlahuacán' },
    { value: 6007, label: 'Manzanillo' },
    { value: 6008, label: 'Minatitlán' },
    { value: 6009, label: 'Tecomán' },
    { value: 6010, label: 'Villa de Álvarez' },
    { value: 7001, label: 'Acacoyagua' },
    { value: 7002, label: 'Acala' },
    { value: 7003, label: 'Acapetahua' },
    { value: 7004, label: 'Altamirano' },
    { value: 7005, label: 'Amatán' },
    { value: 7006, label: 'Amatenango de la Frontera' },
    { value: 7007, label: 'Amatenango del Valle' },
    { value: 7008, label: 'Angel Albino Corzo' },
    { value: 7009, label: 'Arriaga' },
    { value: 7010, label: 'Bejucal de Ocampo' },
    { value: 7011, label: 'Bella Vista' },
    { value: 7012, label: 'Berriozábal' },
    { value: 7013, label: 'Bochil' },
    { value: 7014, label: 'El Bosque' },
    { value: 7015, label: 'Cacahoatán' },
    { value: 7016, label: 'Catazajá' },
    { value: 7017, label: 'Cintalapa' },
    { value: 7018, label: 'Coapilla' },
    { value: 7019, label: 'Comitán de Domínguez' },
    { value: 7020, label: 'La Concordia' },
    { value: 7021, label: 'Copainalá' },
    { value: 7022, label: 'Chalchihuitán' },
    { value: 7023, label: 'Chamula' },
    { value: 7024, label: 'Chanal' },
    { value: 7025, label: 'Chapultenango' },
    { value: 7026, label: 'Chenalhó' },
    { value: 7027, label: 'Chiapa de Corzo' },
    { value: 7028, label: 'Chiapilla' },
    { value: 7029, label: 'Chicoasén' },
    { value: 7030, label: 'Chicomuselo' },
    { value: 7031, label: 'Chilón' },
    { value: 7032, label: 'Escuintla' },
    { value: 7033, label: 'Francisco León' },
    { value: 7034, label: 'Frontera Comalapa' },
    { value: 7035, label: 'Frontera Hidalgo' },
    { value: 7036, label: 'La Grandeza' },
    { value: 7037, label: 'Huehuetán' },
    { value: 7038, label: 'Huixtán' },
    { value: 7039, label: 'Huitiupán' },
    { value: 7040, label: 'Huixtla' },
    { value: 7041, label: 'La Independencia' },
    { value: 7042, label: 'Ixhuatán' },
    { value: 7043, label: 'Ixtacomitán' },
    { value: 7044, label: 'Ixtapa' },
    { value: 7045, label: 'Ixtapangajoya' },
    { value: 7046, label: 'Jiquipilas' },
    { value: 7047, label: 'Jitotol' },
    { value: 7048, label: 'Larráinzar' },
    { value: 7049, label: 'La Libertad' },
    { value: 7050, label: 'Mapastepec' },
    { value: 7051, label: 'Las Margaritas' },
    { value: 7052, label: 'Mazapa de Madero' },
    { value: 7053, label: 'Mazatán' },
    { value: 7054, label: 'Metapa' },
    { value: 7055, label: 'Mitontic' },
    { value: 7056, label: 'Motozintla' },
    { value: 7057, label: 'Nicolás Ruíz' },
    { value: 7058, label: 'Ocosingo' },
    { value: 7059, label: 'Ocotepec' },
    { value: 7060, label: 'Ocozocoautla de Espinosa' },
    { value: 7061, label: 'Ostuacán' },
    { value: 7062, label: 'Osumacinta' },
    { value: 7063, label: 'Oxchuc' },
    { value: 7064, label: 'Palenque' },
    { value: 7065, label: 'Pantelhó' },
    { value: 7066, label: 'Pantepec' },
    { value: 7067, label: 'Pichucalco' },
    { value: 7068, label: 'Pijijiapan' },
    { value: 7069, label: 'El Porvenir' },
    { value: 7070, label: 'Villa Comaltitlán' },
    { value: 7071, label: 'Pueblo Nuevo Solistahuacán' },
    { value: 7072, label: 'Rayón' },
    { value: 7073, label: 'Reforma' },
    { value: 7074, label: 'Las Rosas' },
    { value: 7075, label: 'Sabanilla' },
    { value: 7076, label: 'Salto de Agua' },
    { value: 7077, label: 'San Cristóbal de las Casas' },
    { value: 7078, label: 'San Fernando' },
    { value: 7079, label: 'Siltepec' },
    { value: 7080, label: 'Simojovel' },
    { value: 7081, label: 'Sitalá' },
    { value: 7082, label: 'Socoltenango' },
    { value: 7083, label: 'Solosuchiapa' },
    { value: 7084, label: 'Soyaló' },
    { value: 7085, label: 'Suchiapa' },
    { value: 7086, label: 'Suchiate' },
    { value: 7087, label: 'Sunuapa' },
    { value: 7088, label: 'Tapachula' },
    { value: 7089, label: 'Tapalapa' },
    { value: 7090, label: 'Tapilula' },
    { value: 7091, label: 'Tecpatán' },
    { value: 7092, label: 'Tenejapa' },
    { value: 7093, label: 'Teopisca' },
    { value: 7094, label: 'Tila' },
    { value: 7096, label: 'Tonalá' },
    { value: 7097, label: 'Totolapa' },
    { value: 7098, label: 'La Trinitaria' },
    { value: 7099, label: 'Tumbalá' },
    { value: 7100, label: 'Tuxtla Gutiérrez' },
    { value: 7101, label: 'Tuxtla Chico' },
    { value: 7102, label: 'Tuzantán' },
    { value: 7103, label: 'Tzimol' },
    { value: 7104, label: 'Unión Juárez' },
    { value: 7105, label: 'Venustiano Carranza' },
    { value: 7106, label: 'Villa Corzo' },
    { value: 7107, label: 'Villaflores' },
    { value: 7108, label: 'Yajalón' },
    { value: 7109, label: 'San Lucas' },
    { value: 7110, label: 'Zinacantán' },
    { value: 7111, label: 'San Juan Cancuc' },
    { value: 7112, label: 'Aldama' },
    { value: 7113, label: 'Benemérito de las Américas' },
    { value: 7114, label: 'Maravilla Tenejapa' },
    { value: 7115, label: 'Marqués de Comillas' },
    { value: 7116, label: 'Montecristo de Guerrero' },
    { value: 7117, label: 'San Andrés Duraznal' },
    { value: 7118, label: 'Santiago el Pinar' },
    { value: 7119, label: 'Capitán Luis Ángel Vidal' },
    { value: 7120, label: 'Rincón Chamula San Pedro' },
    { value: 7121, label: 'El Parral' },
    { value: 7122, label: 'Emiliano Zapata' },
    { value: 7123, label: 'Mezcalapa' },
    { value: 7124, label: 'Ahumada' },
    { value: 8001, label: 'Aquiles Serdán' },
    { value: 8002, label: 'Ascensión' },
    { value: 8003, label: 'Bachíniva' },
    { value: 8004, label: 'Balleza' },
    { value: 8005, label: 'Batopilas de Manuel Gómez Morín' },
    { value: 8006, label: 'Bocoyna' },
    { value: 8007, label: 'Buenaventura' },
    { value: 8008, label: 'Camargo' },
    { value: 8009, label: 'Carichí' },
    { value: 8010, label: 'Casas Grandes' },
    { value: 8011, label: 'Coronado' },
    { value: 8012, label: 'Coyame del Sotol' },
    { value: 8013, label: 'La Cruz' },
    { value: 8014, label: 'Cusihuiriachi' },
    { value: 8015, label: 'Chihuahua' },
    { value: 8016, label: 'Chínipas' },
    { value: 8017, label: 'Delicias' },
    { value: 8018, label: 'Dr. Belisario Domínguez' },
    { value: 8019, label: 'Galeana' },
    { value: 8020, label: 'Santa Isabel' },
    { value: 8021, label: 'Gómez Farías' },
    { value: 8022, label: 'Gran Morelos' },
    { value: 8023, label: 'Guachochi' },
    { value: 8024, label: 'Guadalupe' },
    { value: 8025, label: 'Guadalupe y Calvo' },
    { value: 8026, label: 'Guazapares' },
    { value: 8027, label: 'Hidalgo del Parral' },
    { value: 8028, label: 'Huejotitán' },
    { value: 8029, label: 'Ignacio Zaragoza' },
    { value: 8030, label: 'Janos' },
    { value: 8031, label: 'Julimes' },
    { value: 8032, label: 'López' },
    { value: 8033, label: 'Madera' },
    { value: 8034, label: 'Maguarichi' },
    { value: 8035, label: 'Manuel Benavides' },
    { value: 8036, label: 'Matachí' },
    { value: 8037, label: 'Meoqui' },
    { value: 8038, label: 'Moris' },
    { value: 8039, label: 'Namiquipa' },
    { value: 8040, label: 'Nonoava' },
    { value: 8041, label: 'Nuevo Casas Grandes' },
    { value: 8042, label: 'Ojinaga' },
    { value: 8043, label: 'Praxedis G. Guerrero' },
    { value: 8044, label: 'Riva Palacio' },
    { value: 8045, label: 'Rosales' },
    { value: 8046, label: 'Rosario' },
    { value: 8047, label: 'San Francisco de Borja' },
    { value: 8048, label: 'San Francisco de Conchos' },
    { value: 8049, label: 'San Francisco del Oro' },
    { value: 8050, label: 'Santa Bárbara' },
    { value: 8051, label: 'Satevó' },
    { value: 8052, label: 'Saucillo' },
    { value: 8053, label: 'Temósachic' },
    { value: 8054, label: 'El Tule' },
    { value: 8055, label: 'Urique' },
    { value: 8056, label: 'Uruachi' },
    { value: 8057, label: 'Valle de Zaragoza' },
    { value: 8058, label: 'Azcapotzalco' },
    { value: 8059, label: 'Coyoacán' },
    { value: 8060, label: 'Cuajimalpa de Morelos' },
    { value: 8061, label: 'Gustavo A. Madero' },
    { value: 8062, label: 'Iztacalco' },
    { value: 8063, label: 'Iztapalapa' },
    { value: 8064, label: 'La Magdalena Contreras' },
    { value: 8065, label: 'Milpa Alta' },
    { value: 8066, label: 'Álvaro Obregón' },
    { value: 8067, label: 'Tláhuac' },
    { value: 9002, label: 'Tlalpan' },
    { value: 9003, label: 'Xochimilco' },
    { value: 9004, label: 'Benito Juárez' },
    { value: 9005, label: 'Miguel Hidalgo' },
    { value: 9006, label: 'No Especificado' },
    { value: 9007, label: 'Canatlán' },
    { value: 9008, label: 'Canelas' },
    { value: 9009, label: 'Coneto de Comonfort' },
    { value: 9010, label: 'Cuencamé' },
    { value: 9011, label: 'Durango' },
    { value: 9012, label: 'General Simón Bolívar' },
    { value: 9013, label: 'Gómez Palacio' },
    { value: 9014, label: 'Guadalupe Victoria' },
    { value: 9015, label: 'Guanaceví' },
    { value: 9016, label: 'Indé' },
    { value: 9017, label: 'Lerdo' },
    { value: 9998, label: 'Mapimí' },
    { value: 10001, label: 'Mezquital' },
    { value: 10002, label: 'Nazas' },
    { value: 10003, label: 'Nombre de Dios' },
    { value: 10004, label: 'El Oro' },
    { value: 10005, label: 'Otáez' },
    { value: 10006, label: 'Pánuco de Coronado' },
    { value: 10007, label: 'Peñón Blanco' },
    { value: 10008, label: 'Poanas' },
    { value: 10009, label: 'Pueblo Nuevo' },
    { value: 10010, label: 'Rodeo' },
    { value: 10011, label: 'San Bernardo' },
    { value: 10012, label: 'San Dimas' },
    { value: 10013, label: 'San Juan de Guadalupe' },
    { value: 10014, label: 'San Juan del Río' },
    { value: 10015, label: 'San Luis del Cordero' },
    { value: 10016, label: 'San Pedro del Gallo' },
    { value: 10017, label: 'Santa Clara' },
    { value: 10018, label: 'Santiago Papasquiaro' },
    { value: 10019, label: 'Súchil' },
    { value: 10020, label: 'Tamazula' },
    { value: 10021, label: 'Tepehuanes' },
    { value: 10022, label: 'Tlahualilo' },
    { value: 10023, label: 'Topia' },
    { value: 10024, label: 'Vicente Guerrero' },
    { value: 10025, label: 'Nuevo Ideal' },
    { value: 10026, label: 'Acámbaro' },
    { value: 10027, label: 'San Miguel de Allende' },
    { value: 10028, label: 'Apaseo el Alto' },
    { value: 10029, label: 'Apaseo el Grande' },
    { value: 10030, label: 'Atarjea' },
    { value: 10031, label: 'Celaya' },
    { value: 10032, label: 'Manuel Doblado' },
    { value: 10033, label: 'Comonfort' },
    { value: 10034, label: 'Coroneo' },
    { value: 10035, label: 'Cortazar' },
    { value: 10036, label: 'Cuerámaro' },
    { value: 10037, label: 'Doctor Mora' },
    { value: 10038, label: 'Dolores Hidalgo Cuna de la Independencia Nacional' },
    { value: 10039, label: 'Guanajuato' },
    { value: 11001, label: 'Huanímaro' },
    { value: 11002, label: 'Irapuato' },
    { value: 11003, label: 'Jaral del Progreso' },
    { value: 11004, label: 'Jerécuaro' },
    { value: 11005, label: 'León' },
    { value: 11006, label: 'Moroleón' },
    { value: 11007, label: 'Pénjamo' },
    { value: 11008, label: 'Purísima del Rincón' },
    { value: 11009, label: 'Romita' },
    { value: 11010, label: 'Salamanca' },
    { value: 11011, label: 'Salvatierra' },
    { value: 11012, label: 'San Diego de la Unión' },
    { value: 11013, label: 'San Felipe' },
    { value: 11014, label: 'San Francisco del Rincón' },
    { value: 11015, label: 'San José Iturbide' },
    { value: 11016, label: 'San Luis de la Paz' },
    { value: 11017, label: 'Santa Catarina' },
    { value: 11018, label: 'Santa Cruz de Juventino Rosas' },
    { value: 11019, label: 'Santiago Maravatío' },
    { value: 11020, label: 'Silao de la Victoria' },
    { value: 11021, label: 'Tarandacuao' },
    { value: 11022, label: 'Tarimoro' },
    { value: 11023, label: 'Tierra Blanca' },
    { value: 11024, label: 'Uriangato' },
    { value: 11025, label: 'Valle de Santiago' },
    { value: 11026, label: 'Victoria' },
    { value: 11027, label: 'Villagrán' },
    { value: 11028, label: 'Xichú' },
    { value: 11029, label: 'Yuriria' },
    { value: 11030, label: 'Acapulco de Juárez' },
    { value: 11031, label: 'Ahuacuotzingo' },
    { value: 11032, label: 'Ajuchitlán del Progreso' },
    { value: 11033, label: 'Alcozauca de Guerrero' },
    { value: 11034, label: 'Alpoyeca' },
    { value: 11035, label: 'Apaxtla' },
    { value: 11036, label: 'Arcelia' },
    { value: 11037, label: 'Atenango del Río' },
    { value: 11038, label: 'Atlamajalcingo del Monte' },
    { value: 11039, label: 'Atlixtac' },
    { value: 11040, label: 'Atoyac de Álvarez' },
    { value: 11041, label: 'Ayutla de los Libres' },
    { value: 11042, label: 'Azoyú' },
    { value: 11043, label: 'Buenavista de Cuéllar' },
    { value: 11044, label: 'Coahuayutla de José María Izazaga' },
    { value: 11045, label: 'Cocula' },
    { value: 11046, label: 'Copala' },
    { value: 12001, label: 'Copalillo' },
    { value: 12002, label: 'Copanatoyac' },
    { value: 12003, label: 'Coyuca de Benítez' },
    { value: 12004, label: 'Coyuca de Catalán' },
    { value: 12005, label: 'Cuajinicuilapa' },
    { value: 12006, label: 'Cualác' },
    { value: 12007, label: 'Cuautepec' },
    { value: 12008, label: 'Cuetzala del Progreso' },
    { value: 12009, label: 'Cutzamala de Pinzón' },
    { value: 12010, label: 'Chilapa de Álvarez' },
    { value: 12011, label: 'Chilpancingo de los Bravo' },
    { value: 12012, label: 'Florencio Villarreal' },
    { value: 12013, label: 'General Canuto A. Neri' },
    { value: 12014, label: 'General Heliodoro Castillo' },
    { value: 12015, label: 'Huamuxtitlán' },
    { value: 12016, label: 'Huitzuco de los Figueroa' },
    { value: 12017, label: 'Iguala de la Independencia' },
    { value: 12018, label: 'Igualapa' },
    { value: 12019, label: 'Ixcateopan de Cuauhtémoc' },
    { value: 12020, label: 'Zihuatanejo de Azueta' },
    { value: 12021, label: 'Juan R. Escudero' },
    { value: 12022, label: 'Leonardo Bravo' },
    { value: 12023, label: 'Malinaltepec' },
    { value: 12024, label: 'Mártir de Cuilapan' },
    { value: 12025, label: 'Metlatónoc' },
    { value: 12026, label: 'Mochitlán' },
    { value: 12027, label: 'Olinalá' },
    { value: 12028, label: 'Ometepec' },
    { value: 12029, label: 'Pedro Ascencio Alquisiras' },
    { value: 12030, label: 'Petatlán' },
    { value: 12031, label: 'Pilcaya' },
    { value: 12032, label: 'Pungarabato' },
    { value: 12033, label: 'Quechultenango' },
    { value: 12034, label: 'San Luis Acatlán' },
    { value: 12035, label: 'San Marcos' },
    { value: 12036, label: 'San Miguel Totolapan' },
    { value: 12037, label: 'Taxco de Alarcón' },
    { value: 12038, label: 'Tecoanapa' },
    { value: 12039, label: 'Técpan de Galeana' },
    { value: 12040, label: 'Teloloapan' },
    { value: 12041, label: 'Tepecoacuilco de Trujano' },
    { value: 12042, label: 'Tetipac' },
    { value: 12043, label: 'Tixtla de Guerrero' },
    { value: 12044, label: 'Tlacoachistlahuaca' },
    { value: 12045, label: 'Tlacoapa' },
    { value: 12046, label: 'Tlalchapa' },
    { value: 12047, label: 'Tlalixtaquilla de Maldonado' },
    { value: 12048, label: 'Tlapa de Comonfort' },
    { value: 12049, label: 'Tlapehuala' },
    { value: 12050, label: 'La Unión de Isidoro Montes de Oca' },
    { value: 12051, label: 'Xalpatláhuac' },
    { value: 12052, label: 'Xochihuehuetlán' },
    { value: 12053, label: 'Xochistlahuaca' },
    { value: 12054, label: 'Zapotitlán Tablas' },
    { value: 12055, label: 'Zirándaro' },
    { value: 12056, label: 'Zitlala' },
    { value: 12057, label: 'Eduardo Neri' },
    { value: 12058, label: 'Acatepec' },
    { value: 12059, label: 'Marquelia' },
    { value: 12060, label: 'Cochoapa el Grande' },
    { value: 12061, label: 'José Joaquín de Herrera' },
    { value: 12062, label: 'Juchitán' },
    { value: 12063, label: 'Iliatenco' },
    { value: 12064, label: 'Acatlán' },
    { value: 12065, label: 'Acaxochitlán' },
    { value: 12066, label: 'Actopan' },
    { value: 12067, label: 'Agua Blanca de Iturbide' },
    { value: 12068, label: 'Ajacuba' },
    { value: 12069, label: 'Alfajayucan' },
    { value: 12070, label: 'Almoloya' },
    { value: 12071, label: 'Apan' },
    { value: 12072, label: 'El Arenal' },
    { value: 12073, label: 'Atitalaquia' },
    { value: 12074, label: 'Atlapexco' },
    { value: 12075, label: 'Atotonilco el Grande' },
    { value: 12076, label: 'Atotonilco de Tula' },
    { value: 12077, label: 'Calnali' },
    { value: 12078, label: 'Cardonal' },
    { value: 12079, label: 'Cuautepec de Hinojosa' },
    { value: 12080, label: 'Chapantongo' },
    { value: 12081, label: 'Chapulhuacán' },
    { value: 13001, label: 'Chilcuautla' },
    { value: 13002, label: 'Eloxochitlán' },
    { value: 13003, label: 'Epazoyucan' },
    { value: 13004, label: 'Huasca de Ocampo' },
    { value: 13005, label: 'Huautla' },
    { value: 13006, label: 'Huazalingo' },
    { value: 13007, label: 'Huehuetla' },
    { value: 13008, label: 'Huejutla de Reyes' },
    { value: 13009, label: 'Huichapan' },
    { value: 13010, label: 'Ixmiquilpan' },
    { value: 13011, label: 'Jacala de Ledezma' },
    { value: 13012, label: 'Jaltocán' },
    { value: 13013, label: 'Juárez Hidalgo' },
    { value: 13014, label: 'Lolotla' },
    { value: 13015, label: 'Metepec' },
    { value: 13016, label: 'San Agustín Metzquititlán' },
    { value: 13017, label: 'Metztitlán' },
    { value: 13018, label: 'Mineral del Chico' },
    { value: 13019, label: 'Mineral del Monte' },
    { value: 13020, label: 'La Misión' },
    { value: 13021, label: 'Mixquiahuala de Juárez' },
    { value: 13022, label: 'Molango de Escamilla' },
    { value: 13023, label: 'Nicolás Flores' },
    { value: 13024, label: 'Nopala de Villagrán' },
    { value: 13025, label: 'Omitlán de Juárez' },
    { value: 13026, label: 'San Felipe Orizatlán' },
    { value: 13027, label: 'Pacula' },
    { value: 13028, label: 'Pachuca de Soto' },
    { value: 13029, label: 'Pisaflores' },
    { value: 13030, label: 'Progreso de Obregón' },
    { value: 13031, label: 'Mineral de la Reforma' },
    { value: 13032, label: 'San Agustín Tlaxiaca' },
    { value: 13033, label: 'San Bartolo Tutotepec' },
    { value: 13034, label: 'San Salvador' },
    { value: 13035, label: 'Santiago de Anaya' },
    { value: 13036, label: 'Santiago Tulantepec de Lugo Guerrero' },
    { value: 13037, label: 'Singuilucan' },
    { value: 13038, label: 'Tasquillo' },
    { value: 13039, label: 'Tecozautla' },
    { value: 13040, label: 'Tenango de Doria' },
    { value: 13041, label: 'Tepeapulco' },
    { value: 13042, label: 'Tepehuacán de Guerrero' },
    { value: 13043, label: 'Tepeji del Río de Ocampo' },
    { value: 13044, label: 'Tepetitlán' },
    { value: 13045, label: 'Tetepango' },
    { value: 13046, label: 'Villa de Tezontepec' },
    { value: 13047, label: 'Tezontepec de Aldama' },
    { value: 13048, label: 'Tianguistengo' },
    { value: 13049, label: 'Tizayuca' },
    { value: 13050, label: 'Tlahuelilpan' },
    { value: 13051, label: 'Tlahuiltepa' },
    { value: 13052, label: 'Tlanalapa' },
    { value: 13053, label: 'Tlanchinol' },
    { value: 13054, label: 'Tlaxcoapan' },
    { value: 13055, label: 'Tolcayuca' },
    { value: 13056, label: 'Tula de Allende' },
    { value: 13057, label: 'Tulancingo de Bravo' },
    { value: 13058, label: 'Xochiatipan' },
    { value: 13059, label: 'Xochicoatlán' },
    { value: 13060, label: 'Yahualica' },
    { value: 13061, label: 'Zacualtipán de Ángeles' },
    { value: 13062, label: 'Zapotlán de Juárez' },
    { value: 13063, label: 'Zempoala' },
    { value: 13064, label: 'Zimapán' },
    { value: 13065, label: 'Acatic' },
    { value: 13066, label: 'Acatlán de Juárez' },
    { value: 13067, label: 'Ahualulco de Mercado' },
    { value: 13068, label: 'Amacueca' },
    { value: 13069, label: 'Amatitán' },
    { value: 13070, label: 'Ameca' },
    { value: 13071, label: 'San Juanito de Escobedo' },
    { value: 13072, label: 'Arandas' },
    { value: 13073, label: 'Atemajac de Brizuela' },
    { value: 13074, label: 'Atengo' },
    { value: 13075, label: 'Atenguillo' },
    { value: 13076, label: 'Atotonilco el Alto' },
    { value: 13077, label: 'Atoyac' },
    { value: 13078, label: 'Autlán de Navarro' },
    { value: 13079, label: 'Ayotlán' },
    { value: 13080, label: 'Ayutla' },
    { value: 13081, label: 'La Barca' },
    { value: 13082, label: 'Bolaños' },
    { value: 13083, label: 'Cabo Corrientes' },
    { value: 13084, label: 'Casimiro Castillo' },
    { value: 14001, label: 'Cihuatlán' },
    { value: 14002, label: 'Zapotlán el Grande' },
    { value: 14003, label: 'Colotlán' },
    { value: 14004, label: 'Concepción de Buenos Aires' },
    { value: 14005, label: 'Cuautitlán de García Barragán' },
    { value: 14006, label: 'Cuautla' },
    { value: 14007, label: 'Cuquío' },
    { value: 14008, label: 'Chapala' },
    { value: 14009, label: 'Chimaltitán' },
    { value: 14010, label: 'Chiquilistlán' },
    { value: 14011, label: 'Degollado' },
    { value: 14012, label: 'Ejutla' },
    { value: 14013, label: 'Encarnación de Díaz' },
    { value: 14014, label: 'Etzatlán' },
    { value: 14015, label: 'El Grullo' },
    { value: 14016, label: 'Guachinango' },
    { value: 14017, label: 'Guadalajara' },
    { value: 14018, label: 'Hostotipaquillo' },
    { value: 14019, label: 'Huejúcar' },
    { value: 14020, label: 'Huejuquilla el Alto' },
    { value: 14021, label: 'La Huerta' },
    { value: 14022, label: 'Ixtlahuacán de los Membrillos' },
    { value: 14023, label: 'Ixtlahuacán del Río' },
    { value: 14024, label: 'Jalostotitlán' },
    { value: 14025, label: 'Jamay' },
    { value: 14026, label: 'Jilotlán de los Dolores' },
    { value: 14027, label: 'Jocotepec' },
    { value: 14028, label: 'Juanacatlán' },
    { value: 14029, label: 'Juchitlán' },
    { value: 14030, label: 'Lagos de Moreno' },
    { value: 14031, label: 'El Limón' },
    { value: 14032, label: 'Magdalena' },
    { value: 14033, label: 'Santa María del Oro' },
    { value: 14034, label: 'La Manzanilla de la Paz' },
    { value: 14035, label: 'Mascota' },
    { value: 14036, label: 'Mazamitla' },
    { value: 14037, label: 'Mexticacán' },
    { value: 14038, label: 'Mezquitic' },
    { value: 14039, label: 'Mixtlán' },
    { value: 14040, label: 'Ocotlán' },
    { value: 14041, label: 'Ojuelos de Jalisco' },
    { value: 14042, label: 'Pihuamo' },
    { value: 14043, label: 'Poncitlán' },
    { value: 14044, label: 'Puerto Vallarta' },
    { value: 14045, label: 'Villa Purificación' },
    { value: 14046, label: 'Quitupan' },
    { value: 14047, label: 'El Salto' },
    { value: 14048, label: 'San Cristóbal de la Barranca' },
    { value: 14049, label: 'San Diego de Alejandría' },
    { value: 14050, label: 'San Juan de los Lagos' },
    { value: 14051, label: 'San Julián' },
    { value: 14052, label: 'San Martín de Bolaños' },
    { value: 14053, label: 'San Martín Hidalgo' },
    { value: 14054, label: 'San Miguel el Alto' },
    { value: 14055, label: 'San Sebastián del Oeste' },
    { value: 14056, label: 'Santa María de los Ángeles' },
    { value: 14057, label: 'Sayula' },
    { value: 14058, label: 'Tala' },
    { value: 14059, label: 'Talpa de Allende' },
    { value: 14060, label: 'Tamazula de Gordiano' },
    { value: 14061, label: 'Tapalpa' },
    { value: 14062, label: 'Tecalitlán' },
    { value: 14063, label: 'Tecolotlán' },
    { value: 14064, label: 'Techaluta de Montenegro' },
    { value: 14065, label: 'Tenamaxtlán' },
    { value: 14066, label: 'Teocaltiche' },
    { value: 14067, label: 'Teocuitatlán de Corona' },
    { value: 14068, label: 'Tepatitlán de Morelos' },
    { value: 14069, label: 'Tequila' },
    { value: 14070, label: 'Teuchitlán' },
    { value: 14071, label: 'Tizapán el Alto' },
    { value: 14072, label: 'Tlajomulco de Zúñiga' },
    { value: 14073, label: 'San Pedro Tlaquepaque' },
    { value: 14074, label: 'Tolimán' },
    { value: 14075, label: 'Tomatlán' },
    { value: 14076, label: 'Tonaya' },
    { value: 14077, label: 'Tonila' },
    { value: 14078, label: 'Totatiche' },
    { value: 14079, label: 'Tototlán' },
    { value: 14080, label: 'Tuxcacuesco' },
    { value: 14081, label: 'Tuxcueca' },
    { value: 14082, label: 'Tuxpan' },
    { value: 14083, label: 'Unión de San Antonio' },
    { value: 14084, label: 'Unión de Tula' },
    { value: 14085, label: 'Valle de Guadalupe' },
    { value: 14086, label: 'Valle de Juárez' },
    { value: 14087, label: 'San Gabriel' },
    { value: 14088, label: 'Villa Corona' },
    { value: 14089, label: 'Villa Guerrero' },
    { value: 14090, label: 'Villa Hidalgo' },
    { value: 14091, label: 'Cañadas de Obregón' },
    { value: 14092, label: 'Yahualica de González Gallo' },
    { value: 14093, label: 'Zacoalco de Torres' },
    { value: 14094, label: 'Zapopan' },
    { value: 14095, label: 'Zapotiltic' },
    { value: 14096, label: 'Zapotitlán de Vadillo' },
    { value: 14097, label: 'Zapotlán del Rey' },
    { value: 14098, label: 'Zapotlanejo' },
    { value: 14099, label: 'San Ignacio Cerro Gordo' },
    { value: 14100, label: 'Acambay de Ruíz Castañeda' },
    { value: 14101, label: 'Acolman' },
    { value: 14102, label: 'Aculco' },
    { value: 14103, label: 'Almoloya de Alquisiras' },
    { value: 14104, label: 'Almoloya de Juárez' },
    { value: 14105, label: 'Almoloya del Río' },
    { value: 14106, label: 'Amanalco' },
    { value: 14107, label: 'Amatepec' },
    { value: 14108, label: 'Amecameca' },
    { value: 14109, label: 'Apaxco' },
    { value: 14110, label: 'Atenco' },
    { value: 14111, label: 'Atizapán' },
    { value: 14112, label: 'Atizapán de Zaragoza' },
    { value: 14113, label: 'Atlacomulco' },
    { value: 14114, label: 'Atlautla' },
    { value: 14115, label: 'Axapusco' },
    { value: 14116, label: 'Ayapango' },
    { value: 14117, label: 'Calimaya' },
    { value: 14118, label: 'Capulhuac' },
    { value: 14119, label: 'Coacalco de Berriozábal' },
    { value: 14120, label: 'Coatepec Harinas' },
    { value: 14121, label: 'Cocotitlán' },
    { value: 14122, label: 'Coyotepec' },
    { value: 14123, label: 'Cuautitlán' },
    { value: 14124, label: 'Chalco' },
    { value: 14125, label: 'Chapa de Mota' },
    { value: 14998, label: 'Chapultepec' },
    { value: 15001, label: 'Chiautla' },
    { value: 15002, label: 'Chicoloapan' },
    { value: 15003, label: 'Chiconcuac' },
    { value: 15004, label: 'Chimalhuacán' },
    { value: 15005, label: 'Donato Guerra' },
    { value: 15006, label: 'Ecatepec de Morelos' },
    { value: 15007, label: 'Ecatzingo' },
    { value: 15008, label: 'Huehuetoca' },
    { value: 15009, label: 'Hueypoxtla' },
    { value: 15010, label: 'Huixquilucan' },
    { value: 15011, label: 'Isidro Fabela' },
    { value: 15012, label: 'Ixtapaluca' },
    { value: 15013, label: 'Ixtapan de la Sal' },
    { value: 15014, label: 'Ixtapan del Oro' },
    { value: 15015, label: 'Ixtlahuaca' },
    { value: 15016, label: 'Xalatlaco' },
    { value: 15017, label: 'Jaltenco' },
    { value: 15018, label: 'Jilotepec' },
    { value: 15019, label: 'Jilotzingo' },
    { value: 15020, label: 'Jiquipilco' },
    { value: 15021, label: 'Jocotitlán' },
    { value: 15022, label: 'Joquicingo' },
    { value: 15023, label: 'Juchitepec' },
    { value: 15024, label: 'Lerma' },
    { value: 15025, label: 'Malinalco' },
    { value: 15026, label: 'Melchor Ocampo' },
    { value: 15027, label: 'Mexicaltzingo' },
    { value: 15028, label: 'Naucalpan de Juárez' },
    { value: 15029, label: 'Nezahualcóyotl' },
    { value: 15030, label: 'Nextlalpan' },
    { value: 15031, label: 'Nicolás Romero' },
    { value: 15032, label: 'Nopaltepec' },
    { value: 15033, label: 'Ocoyoacac' },
    { value: 15034, label: 'Ocuilan' },
    { value: 15035, label: 'Otumba' },
    { value: 15036, label: 'Otzoloapan' },
    { value: 15037, label: 'Otzolotepec' },
    { value: 15038, label: 'Ozumba' },
    { value: 15039, label: 'Papalotla' },
    { value: 15040, label: 'Polotitlán' },
    { value: 15041, label: 'San Antonio la Isla' },
    { value: 15042, label: 'San Felipe del Progreso' },
    { value: 15043, label: 'San Martín de las Pirámides' },
    { value: 15044, label: 'San Mateo Atenco' },
    { value: 15045, label: 'San Simón de Guerrero' },
    { value: 15046, label: 'Santo Tomás' },
    { value: 15047, label: 'Soyaniquilpan de Juárez' },
    { value: 15048, label: 'Sultepec' },
    { value: 15049, label: 'Tecámac' },
    { value: 15050, label: 'Tejupilco' },
    { value: 15051, label: 'Temamatla' },
    { value: 15052, label: 'Temascalapa' },
    { value: 15053, label: 'Temascalcingo' },
    { value: 15054, label: 'Temascaltepec' },
    { value: 15055, label: 'Temoaya' },
    { value: 15056, label: 'Tenancingo' },
    { value: 15057, label: 'Tenango del Aire' },
    { value: 15058, label: 'Tenango del Valle' },
    { value: 15059, label: 'Teoloyucan' },
    { value: 15060, label: 'Teotihuacán' },
    { value: 15061, label: 'Tepetlaoxtoc' },
    { value: 15062, label: 'Tepetlixpa' },
    { value: 15063, label: 'Tepotzotlán' },
    { value: 15064, label: 'Tequixquiac' },
    { value: 15065, label: 'Texcaltitlán' },
    { value: 15066, label: 'Texcalyacac' },
    { value: 15067, label: 'Texcoco' },
    { value: 15068, label: 'Tezoyuca' },
    { value: 15069, label: 'Tianguistenco' },
    { value: 15070, label: 'Timilpan' },
    { value: 15071, label: 'Tlalmanalco' },
    { value: 15072, label: 'Tlalnepantla de Baz' },
    { value: 15073, label: 'Tlatlaya' },
    { value: 15074, label: 'Toluca' },
    { value: 15075, label: 'Tonatico' },
    { value: 15076, label: 'Tultepec' },
    { value: 15077, label: 'Tultitlán' },
    { value: 15078, label: 'Valle de Bravo' },
    { value: 15079, label: 'Villa de Allende' },
    { value: 15080, label: 'Villa del Carbón' },
    { value: 15081, label: 'Villa Victoria' },
    { value: 15082, label: 'Xonacatlán' },
    { value: 15083, label: 'Zacazonapan' },
    { value: 15084, label: 'Zacualpan' },
    { value: 15085, label: 'Zinacantepec' },
    { value: 15086, label: 'Zumpahuacán' },
    { value: 15087, label: 'Zumpango' },
    { value: 15088, label: 'Cuautitlán Izcalli' },
    { value: 15089, label: 'Valle de Chalco Solidaridad' },
    { value: 15090, label: 'Luvianos' },
    { value: 15091, label: 'San José del Rincón' },
    { value: 15092, label: 'Tonanitla' },
    { value: 15093, label: 'Acuitzio' },
    { value: 15094, label: 'Aguililla' },
    { value: 15095, label: 'Angamacutiro' },
    { value: 15096, label: 'Angangueo' },
    { value: 15097, label: 'Apatzingán' },
    { value: 15098, label: 'Aporo' },
    { value: 15099, label: 'Aquila' },
    { value: 15100, label: 'Ario' },
    { value: 15101, label: 'Briseñas' },
    { value: 15102, label: 'Buenavista' },
    { value: 15103, label: 'Carácuaro' },
    { value: 15104, label: 'Coahuayana' },
    { value: 15105, label: 'Coalcomán de Vázquez Pallares' },
    { value: 15106, label: 'Coeneo' },
    { value: 15107, label: 'Contepec' },
    { value: 15108, label: 'Copándaro' },
    { value: 15109, label: 'Cotija' },
    { value: 15110, label: 'Cuitzeo' },
    { value: 15111, label: 'Charapan' },
    { value: 15112, label: 'Charo' },
    { value: 15113, label: 'Chavinda' },
    { value: 15114, label: 'Cherán' },
    { value: 15115, label: 'Chilchota' },
    { value: 15116, label: 'Chinicuila' },
    { value: 15117, label: 'Chucándiro' },
    { value: 15118, label: 'Churintzio' },
    { value: 15119, label: 'Churumuco' },
    { value: 15120, label: 'Ecuandureo' },
    { value: 15121, label: 'Epitacio Huerta' },
    { value: 15122, label: 'Erongarícuaro' },
    { value: 15123, label: 'Gabriel Zamora' },
    { value: 15124, label: 'La Huacana' },
    { value: 15125, label: 'Huandacareo' },
    { value: 16001, label: 'Huaniqueo' },
    { value: 16002, label: 'Huetamo' },
    { value: 16003, label: 'Huiramba' },
    { value: 16004, label: 'Indaparapeo' },
    { value: 16005, label: 'Irimbo' },
    { value: 16006, label: 'Ixtlán' },
    { value: 16007, label: 'Jacona' },
    { value: 16008, label: 'Jiquilpan' },
    { value: 16009, label: 'Jungapeo' },
    { value: 16010, label: 'Lagunillas' },
    { value: 16011, label: 'Madero' },
    { value: 16012, label: 'Maravatío' },
    { value: 16013, label: 'Marcos Castellanos' },
    { value: 16014, label: 'Lázaro Cárdenas' },
    { value: 16015, label: 'Morelia' },
    { value: 16016, label: 'Múgica' },
    { value: 16017, label: 'Nahuatzen' },
    { value: 16018, label: 'Nocupétaro' },
    { value: 16019, label: 'Nuevo Parangaricutiro' },
    { value: 16020, label: 'Nuevo Urecho' },
    { value: 16021, label: 'Numarán' },
    { value: 16022, label: 'Pajacuarán' },
    { value: 16023, label: 'Panindícuaro' },
    { value: 16024, label: 'Parácuaro' },
    { value: 16025, label: 'Paracho' },
    { value: 16026, label: 'Pátzcuaro' },
    { value: 16027, label: 'Penjamillo' },
    { value: 16028, label: 'Peribán' },
    { value: 16029, label: 'La Piedad' },
    { value: 16030, label: 'Purépero' },
    { value: 16031, label: 'Puruándiro' },
    { value: 16032, label: 'Queréndaro' },
    { value: 16033, label: 'Quiroga' },
    { value: 16034, label: 'Cojumatlán de Régules' },
    { value: 16035, label: 'Los Reyes' },
    { value: 16036, label: 'Sahuayo' },
    { value: 16037, label: 'Santa Ana Maya' },
    { value: 16038, label: 'Salvador Escalante' },
    { value: 16039, label: 'Senguio' },
    { value: 16040, label: 'Susupuato' },
    { value: 16041, label: 'Tacámbaro' },
    { value: 16042, label: 'Tancítaro' },
    { value: 16043, label: 'Tangamandapio' },
    { value: 16044, label: 'Tangancícuaro' },
    { value: 16045, label: 'Tanhuato' },
    { value: 16046, label: 'Taretan' },
    { value: 16047, label: 'Tarímbaro' },
    { value: 16048, label: 'Tepalcatepec' },
    { value: 16049, label: 'Tingambato' },
    { value: 16050, label: 'Tingüindín' },
    { value: 16051, label: 'Tiquicheo de Nicolás Romero' },
    { value: 16052, label: 'Tlalpujahua' },
    { value: 16053, label: 'Tlazazalca' },
    { value: 16054, label: 'Tocumbo' },
    { value: 16055, label: 'Tumbiscatío' },
    { value: 16056, label: 'Turicato' },
    { value: 16057, label: 'Tuzantla' },
    { value: 16058, label: 'Tzintzuntzan' },
    { value: 16059, label: 'Tzitzio' },
    { value: 16060, label: 'Uruapan' },
    { value: 16061, label: 'Villamar' },
    { value: 16062, label: 'Vista Hermosa' },
    { value: 16063, label: 'Yurécuaro' },
    { value: 16064, label: 'Zacapu' },
    { value: 16065, label: 'Zamora' },
    { value: 16066, label: 'Zináparo' },
    { value: 16067, label: 'Zinapécuaro' },
    { value: 16068, label: 'Ziracuaretiro' },
    { value: 16069, label: 'Zitácuaro' },
    { value: 16070, label: 'José Sixto Verduzco' },
    { value: 16071, label: 'Amacuzac' },
    { value: 16072, label: 'Atlatlahucan' },
    { value: 16073, label: 'Axochiapan' },
    { value: 16074, label: 'Ayala' },
    { value: 16075, label: 'Coatlán del Río' },
    { value: 16076, label: 'Cuernavaca' },
    { value: 16077, label: 'Huitzilac' },
    { value: 16078, label: 'Jantetelco' },
    { value: 16079, label: 'Jiutepec' },
    { value: 16080, label: 'Jojutla' },
    { value: 16081, label: 'Jonacatepec de Leandro Valle' },
    { value: 16082, label: 'Mazatepec' },
    { value: 16083, label: 'Miacatlán' },
    { value: 16084, label: 'Ocuituco' },
    { value: 16085, label: 'Puente de Ixtla' },
    { value: 16086, label: 'Temixco' },
    { value: 16087, label: 'Tepalcingo' },
    { value: 16088, label: 'Tepoztlán' },
    { value: 16089, label: 'Tetecala' },
    { value: 16090, label: 'Tetela del Volcán' },
    { value: 16091, label: 'Tlalnepantla' },
    { value: 16092, label: 'Tlaltizapán de Zapata' },
    { value: 16093, label: 'Tlaquiltenango' },
    { value: 16094, label: 'Tlayacapan' },
    { value: 16095, label: 'Totolapan' },
    { value: 16096, label: 'Xochitepec' },
    { value: 16097, label: 'Yautepec' },
    { value: 16098, label: 'Yecapixtla' },
    { value: 16099, label: 'Zacatepec' },
    { value: 16100, label: 'Zacualpan de Amilpas' },
    { value: 16101, label: 'Temoac' },
    { value: 16102, label: 'Acaponeta' },
    { value: 16103, label: 'Ahuacatlán' },
    { value: 16104, label: 'Amatlán de Cañas' },
    { value: 16105, label: 'Compostela' },
    { value: 16106, label: 'Huajicori' },
    { value: 16107, label: 'Ixtlán del Río' },
    { value: 16108, label: 'Jala' },
    { value: 16109, label: 'Xalisco' },
    { value: 16110, label: 'Del Nayar' },
    { value: 16111, label: 'Rosamorada' },
    { value: 16112, label: 'Ruíz' },
    { value: 16113, label: 'San Blas' },
    { value: 17001, label: 'San Pedro Lagunillas' },
    { value: 17002, label: 'Santiago Ixcuintla' },
    { value: 17003, label: 'Tecuala' },
    { value: 17004, label: 'Tepic' },
    { value: 17005, label: 'La Yesca' },
    { value: 17006, label: 'Bahía de Banderas' },
    { value: 17007, label: 'Agualeguas' },
    { value: 17008, label: 'Los Aldamas' },
    { value: 17009, label: 'Anáhuac' },
    { value: 17010, label: 'Apodaca' },
    { value: 17011, label: 'Aramberri' },
    { value: 17012, label: 'Bustamante' },
    { value: 17013, label: 'Cadereyta Jiménez' },
    { value: 17014, label: 'El Carmen' },
    { value: 17015, label: 'Cerralvo' },
    { value: 17016, label: 'Ciénega de Flores' },
    { value: 17017, label: 'China' },
    { value: 17018, label: 'Doctor Arroyo' },
    { value: 17019, label: 'Doctor Coss' },
    { value: 17020, label: 'Doctor González' },
    { value: 17021, label: 'García' },
    { value: 17022, label: 'San Pedro Garza García' },
    { value: 17023, label: 'General Bravo' },
    { value: 17024, label: 'General Escobedo' },
    { value: 17025, label: 'General Terán' },
    { value: 17026, label: 'General Treviño' },
    { value: 17027, label: 'General Zaragoza' },
    { value: 17028, label: 'General Zuazua' },
    { value: 17029, label: 'Los Herreras' },
    { value: 17030, label: 'Higueras' },
    { value: 17031, label: 'Hualahuises' },
    { value: 17032, label: 'Iturbide' },
    { value: 17033, label: 'Lampazos de Naranjo' },
    { value: 18001, label: 'Linares' },
    { value: 18002, label: 'Marín' },
    { value: 18003, label: 'Mier y Noriega' },
    { value: 18004, label: 'Mina' },
    { value: 18005, label: 'Montemorelos' },
    { value: 18006, label: 'Monterrey' },
    { value: 18007, label: 'Parás' },
    { value: 18008, label: 'Pesquería' },
    { value: 18009, label: 'Los Ramones' },
    { value: 18010, label: 'Rayones' },
    { value: 18011, label: 'Sabinas Hidalgo' },
    { value: 18012, label: 'Salinas Victoria' },
    { value: 18013, label: 'San Nicolás de los Garza' },
    { value: 18014, label: 'Santiago' },
    { value: 18015, label: 'Vallecillo' },
    { value: 18016, label: 'Villaldama' },
    { value: 18017, label: 'Abejones' },
    { value: 18018, label: 'Acatlán de Pérez Figueroa' },
    { value: 18019, label: 'Asunción Cacalotepec' },
    { value: 18020, label: 'Asunción Cuyotepeji' },
    { value: 19001, label: 'Asunción Ixtaltepec' },
    { value: 19002, label: 'Asunción Nochixtlán' },
    { value: 19003, label: 'Asunción Ocotlán' },
    { value: 19004, label: 'Asunción Tlacolulita' },
    { value: 19005, label: 'Ayotzintepec' },
    { value: 19006, label: 'El Barrio de la Soledad' },
    { value: 19007, label: 'Calihualá' },
    { value: 19008, label: 'Candelaria Loxicha' },
    { value: 19009, label: 'Ciénega de Zimatlán' },
    { value: 19010, label: 'Ciudad Ixtepec' },
    { value: 19011, label: 'Coatecas Altas' },
    { value: 19012, label: 'Coicoyán de las Flores' },
    { value: 19013, label: 'La Compañía' },
    { value: 19014, label: 'Concepción Buenavista' },
    { value: 19015, label: 'Concepción Pápalo' },
    { value: 19016, label: 'Constancia del Rosario' },
    { value: 19017, label: 'Cosolapa' },
    { value: 19018, label: 'Cosoltepec' },
    { value: 19019, label: 'Cuilápam de Guerrero' },
    { value: 19020, label: 'Cuyamecalco Villa de Zaragoza' },
    { value: 19021, label: 'Chahuites' },
    { value: 19022, label: 'Chalcatongo de Hidalgo' },
    { value: 19023, label: 'Chiquihuitlán de Benito Juárez' },
    { value: 19024, label: 'Heroica Ciudad de Ejutla de Crespo' },
    { value: 19025, label: 'Eloxochitlán de Flores Magón' },
    { value: 19026, label: 'El Espinal' },
    { value: 19027, label: 'Tamazulápam del Espíritu Santo' },
    { value: 19028, label: 'Fresnillo de Trujano' },
    { value: 19029, label: 'Guadalupe Etla' },
    { value: 19030, label: 'Guadalupe de Ramírez' },
    { value: 19031, label: 'Guelatao de Juárez' },
    { value: 19032, label: 'Guevea de Humboldt' },
    { value: 19033, label: 'Mesones Hidalgo' },
    { value: 19034, label: 'Heroica Ciudad de Huajuapan de León' },
    { value: 19035, label: 'Huautepec' },
    { value: 19036, label: 'Huautla de Jiménez' },
    { value: 19037, label: 'Ixtlán de Juárez' },
    { value: 19038, label: 'Heroica Ciudad de Juchitán de Zaragoza' },
    { value: 19039, label: 'Loma Bonita' },
    { value: 19040, label: 'Magdalena Apasco' },
    { value: 19041, label: 'Magdalena Jaltepec' },
    { value: 19042, label: 'Santa Magdalena Jicotlán' },
    { value: 19043, label: 'Magdalena Mixtepec' },
    { value: 19044, label: 'Magdalena Ocotlán' },
    { value: 19045, label: 'Magdalena Peñasco' },
    { value: 19046, label: 'Magdalena Teitipac' },
    { value: 19047, label: 'Magdalena Tequisistlán' },
    { value: 19048, label: 'Magdalena Tlacotepec' },
    { value: 19049, label: 'Magdalena Zahuatlán' },
    { value: 19050, label: 'Mariscala de Juárez' },
    { value: 19051, label: 'Mártires de Tacubaya' },
    { value: 20001, label: 'Matías Romero Avendaño' },
    { value: 20002, label: 'Mazatlán Villa de Flores' },
    { value: 20003, label: 'Miahuatlán de Porfirio Díaz' },
    { value: 20004, label: 'Mixistlán de la Reforma' },
    { value: 20005, label: 'Monjas' },
    { value: 20006, label: 'Natividad' },
    { value: 20007, label: 'Nazareno Etla' },
    { value: 20008, label: 'Nejapa de Madero' },
    { value: 20009, label: 'Ixpantepec Nieves' },
    { value: 20010, label: 'Santiago Niltepec' },
    { value: 20011, label: 'Oaxaca de Juárez' },
    { value: 20012, label: 'Ocotlán de Morelos' },
    { value: 20013, label: 'La Pe' },
    { value: 20014, label: 'Pinotepa de Don Luis' },
    { value: 20015, label: 'Pluma Hidalgo' },
    { value: 20016, label: 'San José del Progreso' },
    { value: 20017, label: 'Putla Villa de Guerrero' },
    { value: 20018, label: 'Santa Catarina Quioquitani' },
    { value: 20019, label: 'Reforma de Pineda' },
    { value: 20020, label: 'La Reforma' },
    { value: 20021, label: 'Reyes Etla' },
    { value: 20022, label: 'Rojas de Cuauhtémoc' },
    { value: 20023, label: 'Salina Cruz' },
    { value: 20024, label: 'San Agustín Amatengo' },
    { value: 20025, label: 'San Agustín Atenango' },
    { value: 20026, label: 'San Agustín Chayuco' },
    { value: 20027, label: 'San Agustín de las Juntas' },
    { value: 20028, label: 'San Agustín Etla' },
    { value: 20029, label: 'San Agustín Loxicha' },
    { value: 20030, label: 'San Agustín Tlacotepec' },
    { value: 20031, label: 'San Agustín Yatareni' },
    { value: 20032, label: 'San Andrés Cabecera Nueva' },
    { value: 20033, label: 'San Andrés Dinicuiti' },
    { value: 20034, label: 'San Andrés Huaxpaltepec' },
    { value: 20035, label: 'San Andrés Huayápam' },
    { value: 20036, label: 'San Andrés Ixtlahuaca' },
    { value: 20037, label: 'San Andrés Lagunas' },
    { value: 20038, label: 'San Andrés Nuxiño' },
    { value: 20039, label: 'San Andrés Paxtlán' },
    { value: 20040, label: 'San Andrés Sinaxtla' },
    { value: 20041, label: 'San Andrés Solaga' },
    { value: 20042, label: 'San Andrés Teotilálpam' },
    { value: 20043, label: 'San Andrés Tepetlapa' },
    { value: 20044, label: 'San Andrés Yaá' },
    { value: 20045, label: 'San Andrés Zabache' },
    { value: 20046, label: 'San Andrés Zautla' },
    { value: 20047, label: 'San Antonino Castillo Velasco' },
    { value: 20048, label: 'San Antonino el Alto' },
    { value: 20049, label: 'San Antonino Monte Verde' },
    { value: 20050, label: 'San Antonio Acutla' },
    { value: 20051, label: 'San Antonio de la Cal' },
    { value: 20052, label: 'San Antonio Huitepec' },
    { value: 20053, label: 'San Antonio Nanahuatípam' },
    { value: 20054, label: 'San Antonio Sinicahua' },
    { value: 20055, label: 'San Antonio Tepetlapa' },
    { value: 20056, label: 'San Baltazar Chichicápam' },
    { value: 20057, label: 'San Baltazar Loxicha' },
    { value: 20058, label: 'San Baltazar Yatzachi el Bajo' },
    { value: 20059, label: 'San Bartolo Coyotepec' },
    { value: 20060, label: 'San Bartolomé Ayautla' },
    { value: 20061, label: 'San Bartolomé Loxicha' },
    { value: 20062, label: 'San Bartolomé Quialana' },
    { value: 20063, label: 'San Bartolomé Yucuañe' },
    { value: 20064, label: 'San Bartolomé Zoogocho' },
    { value: 20065, label: 'San Bartolo Soyaltepec' },
    { value: 20066, label: 'San Bartolo Yautepec' },
    { value: 20067, label: 'San Bernardo Mixtepec' },
    { value: 20068, label: 'San Blas Atempa' },
    { value: 20069, label: 'San Carlos Yautepec' },
    { value: 20070, label: 'San Cristóbal Amatlán' },
    { value: 20071, label: 'San Cristóbal Amoltepec' },
    { value: 20072, label: 'San Cristóbal Lachirioag' },
    { value: 20073, label: 'San Cristóbal Suchixtlahuaca' },
    { value: 20074, label: 'San Dionisio del Mar' },
    { value: 20075, label: 'San Dionisio Ocotepec' },
    { value: 20076, label: 'San Dionisio Ocotlán' },
    { value: 20077, label: 'San Esteban Atatlahuca' },
    { value: 20078, label: 'San Felipe Jalapa de Díaz' },
    { value: 20079, label: 'San Felipe Tejalápam' },
    { value: 20080, label: 'San Felipe Usila' },
    { value: 20081, label: 'San Francisco Cahuacuá' },
    { value: 20082, label: 'San Francisco Cajonos' },
    { value: 20083, label: 'San Francisco Chapulapa' },
    { value: 20084, label: 'San Francisco Chindúa' },
    { value: 20085, label: 'San Francisco del Mar' },
    { value: 20086, label: 'San Francisco Huehuetlán' },
    { value: 20087, label: 'San Francisco Ixhuatán' },
    { value: 20088, label: 'San Francisco Jaltepetongo' },
    { value: 20089, label: 'San Francisco Lachigoló' },
    { value: 20090, label: 'San Francisco Logueche' },
    { value: 20091, label: 'San Francisco Nuxaño' },
    { value: 20092, label: 'San Francisco Ozolotepec' },
    { value: 20093, label: 'San Francisco Sola' },
    { value: 20094, label: 'San Francisco Telixtlahuaca' },
    { value: 20095, label: 'San Francisco Teopan' },
    { value: 20096, label: 'San Francisco Tlapancingo' },
    { value: 20097, label: 'San Gabriel Mixtepec' },
    { value: 20098, label: 'San Ildefonso Amatlán' },
    { value: 20099, label: 'San Ildefonso Sola' },
    { value: 20100, label: 'San Ildefonso Villa Alta' },
    { value: 20101, label: 'San Jacinto Amilpas' },
    { value: 20102, label: 'San Jacinto Tlacotepec' },
    { value: 20103, label: 'San Jerónimo Coatlán' },
    { value: 20104, label: 'San Jerónimo Silacayoapilla' },
    { value: 20105, label: 'San Jerónimo Sosola' },
    { value: 20106, label: 'San Jerónimo Taviche' },
    { value: 20107, label: 'San Jerónimo Tecóatl' },
    { value: 20108, label: 'San Jorge Nuchita' },
    { value: 20109, label: 'San José Ayuquila' },
    { value: 20110, label: 'San José Chiltepec' },
    { value: 20111, label: 'San José del Peñasco' },
    { value: 20112, label: 'San José Estancia Grande' },
    { value: 20113, label: 'San José Independencia' },
    { value: 20114, label: 'San José Lachiguiri' },
    { value: 20115, label: 'San José Tenango' },
    { value: 20116, label: 'San Juan Achiutla' },
    { value: 20117, label: 'San Juan Atepec' },
    { value: 20118, label: 'Ánimas Trujano' },
    { value: 20119, label: 'San Juan Bautista Atatlahuca' },
    { value: 20120, label: 'San Juan Bautista Coixtlahuaca' },
    { value: 20121, label: 'San Juan Bautista Cuicatlán' },
    { value: 20122, label: 'San Juan Bautista Guelache' },
    { value: 20123, label: 'San Juan Bautista Jayacatlán' },
    { value: 20124, label: 'San Juan Bautista Lo de Soto' },
    { value: 20125, label: 'San Juan Bautista Suchitepec' },
    { value: 20126, label: 'San Juan Bautista Tlacoatzintepec' },
    { value: 20127, label: 'San Juan Bautista Tlachichilco' },
    { value: 20128, label: 'San Juan Bautista Tuxtepec' },
    { value: 20129, label: 'San Juan Cacahuatepec' },
    { value: 20130, label: 'San Juan Cieneguilla' },
    { value: 20131, label: 'San Juan Coatzóspam' },
    { value: 20132, label: 'San Juan Colorado' },
    { value: 20133, label: 'San Juan Comaltepec' },
    { value: 20134, label: 'San Juan Cotzocón' },
    { value: 20135, label: 'San Juan Chicomezúchil' },
    { value: 20136, label: 'San Juan Chilateca' },
    { value: 20137, label: 'San Juan del Estado' },
    { value: 20138, label: 'San Juan Diuxi' },
    { value: 20139, label: 'San Juan Evangelista Analco' },
    { value: 20140, label: 'San Juan Guelavía' },
    { value: 20141, label: 'San Juan Guichicovi' },
    { value: 20142, label: 'San Juan Ihualtepec' },
    { value: 20143, label: 'San Juan Juquila Mixes' },
    { value: 20144, label: 'San Juan Juquila Vijanos' },
    { value: 20145, label: 'San Juan Lachao' },
    { value: 20146, label: 'San Juan Lachigalla' },
    { value: 20147, label: 'San Juan Lajarcia' },
    { value: 20148, label: 'San Juan Lalana' },
    { value: 20149, label: 'San Juan de los Cués' },
    { value: 20150, label: 'San Juan Mazatlán' },
    { value: 20151, label: 'San Juan Mixtepec Distrito 08' },
    { value: 20152, label: 'San Juan Mixtepec Distrito 26' },
    { value: 20153, label: 'San Juan Ñumí' },
    { value: 20154, label: 'San Juan Ozolotepec' },
    { value: 20155, label: 'San Juan Petlapa' },
    { value: 20156, label: 'San Juan Quiahije' },
    { value: 20157, label: 'San Juan Quiotepec' },
    { value: 20158, label: 'San Juan Sayultepec' },
    { value: 20159, label: 'San Juan Tabaá' },
    { value: 20160, label: 'San Juan Tamazola' },
    { value: 20161, label: 'San Juan Teita' },
    { value: 20162, label: 'San Juan Teitipac' },
    { value: 20163, label: 'San Juan Tepeuxila' },
    { value: 20164, label: 'San Juan Teposcolula' },
    { value: 20165, label: 'San Juan Yaeé' },
    { value: 20166, label: 'San Juan Yatzona' },
    { value: 20167, label: 'San Juan Yucuita' },
    { value: 20168, label: 'San Lorenzo' },
    { value: 20169, label: 'San Lorenzo Albarradas' },
    { value: 20170, label: 'San Lorenzo Cacaotepec' },
    { value: 20171, label: 'San Lorenzo Cuaunecuiltitla' },
    { value: 20172, label: 'San Lorenzo Texmelúcan' },
    { value: 20173, label: 'San Lorenzo Victoria' },
    { value: 20174, label: 'San Lucas Camotlán' },
    { value: 20175, label: 'San Lucas Ojitlán' },
    { value: 20176, label: 'San Lucas Quiaviní' },
    { value: 20177, label: 'San Lucas Zoquiápam' },
    { value: 20178, label: 'San Luis Amatlán' },
    { value: 20179, label: 'San Marcial Ozolotepec' },
    { value: 20180, label: 'San Marcos Arteaga' },
    { value: 20181, label: 'San Martín de los Cansecos' },
    { value: 20182, label: 'San Martín Huamelúlpam' },
    { value: 20183, label: 'San Martín Itunyoso' },
    { value: 20184, label: 'San Martín Lachilá' },
    { value: 20185, label: 'San Martín Peras' },
    { value: 20186, label: 'San Martín Tilcajete' },
    { value: 20187, label: 'San Martín Toxpalan' },
    { value: 20188, label: 'San Martín Zacatepec' },
    { value: 20189, label: 'San Mateo Cajonos' },
    { value: 20190, label: 'Capulálpam de Méndez' },
    { value: 20191, label: 'San Mateo del Mar' },
    { value: 20192, label: 'San Mateo Yoloxochitlán' },
    { value: 20193, label: 'San Mateo Etlatongo' },
    { value: 20194, label: 'San Mateo Nejápam' },
    { value: 20195, label: 'San Mateo Peñasco' },
    { value: 20196, label: 'San Mateo Piñas' },
    { value: 20197, label: 'San Mateo Río Hondo' },
    { value: 20198, label: 'San Mateo Sindihui' },
    { value: 20199, label: 'San Mateo Tlapiltepec' },
    { value: 20200, label: 'San Melchor Betaza' },
    { value: 20201, label: 'San Miguel Achiutla' },
    { value: 20202, label: 'San Miguel Ahuehuetitlán' },
    { value: 20203, label: 'San Miguel Aloápam' },
    { value: 20204, label: 'San Miguel Amatitlán' },
    { value: 20205, label: 'San Miguel Amatlán' },
    { value: 20206, label: 'San Miguel Coatlán' },
    { value: 20207, label: 'San Miguel Chicahua' },
    { value: 20208, label: 'San Miguel Chimalapa' },
    { value: 20209, label: 'San Miguel del Puerto' },
    { value: 20210, label: 'San Miguel del Río' },
    { value: 20211, label: 'San Miguel Ejutla' },
    { value: 20212, label: 'San Miguel el Grande' },
    { value: 20213, label: 'San Miguel Huautla' },
    { value: 20214, label: 'San Miguel Mixtepec' },
    { value: 20215, label: 'San Miguel Panixtlahuaca' },
    { value: 20216, label: 'San Miguel Peras' },
    { value: 20217, label: 'San Miguel Piedras' },
    { value: 20218, label: 'San Miguel Quetzaltepec' },
    { value: 20219, label: 'San Miguel Santa Flor' },
    { value: 20220, label: 'Villa Sola de Vega' },
    { value: 20221, label: 'San Miguel Soyaltepec' },
    { value: 20222, label: 'San Miguel Suchixtepec' },
    { value: 20223, label: 'Villa Talea de Castro' },
    { value: 20224, label: 'San Miguel Tecomatlán' },
    { value: 20225, label: 'San Miguel Tenango' },
    { value: 20226, label: 'San Miguel Tequixtepec' },
    { value: 20227, label: 'San Miguel Tilquiápam' },
    { value: 20228, label: 'San Miguel Tlacamama' },
    { value: 20229, label: 'San Miguel Tlacotepec' },
    { value: 20230, label: 'San Miguel Tulancingo' },
    { value: 20231, label: 'San Miguel Yotao' },
    { value: 20232, label: 'San Nicolás' },
    { value: 20233, label: 'San Nicolás Hidalgo' },
    { value: 20234, label: 'San Pablo Coatlán' },
    { value: 20235, label: 'San Pablo Cuatro Venados' },
    { value: 20236, label: 'San Pablo Etla' },
    { value: 20237, label: 'San Pablo Huitzo' },
    { value: 20238, label: 'San Pablo Huixtepec' },
    { value: 20239, label: 'San Pablo Macuiltianguis' },
    { value: 20240, label: 'San Pablo Tijaltepec' },
    { value: 20241, label: 'San Pablo Villa de Mitla' },
    { value: 20242, label: 'San Pablo Yaganiza' },
    { value: 20243, label: 'San Pedro Amuzgos' },
    { value: 20244, label: 'San Pedro Apóstol' },
    { value: 20245, label: 'San Pedro Atoyac' },
    { value: 20246, label: 'San Pedro Cajonos' },
    { value: 20247, label: 'San Pedro Coxcaltepec Cántaros' },
    { value: 20248, label: 'San Pedro Comitancillo' },
    { value: 20249, label: 'San Pedro el Alto' },
    { value: 20250, label: 'San Pedro Huamelula' },
    { value: 20251, label: 'San Pedro Huilotepec' },
    { value: 20252, label: 'San Pedro Ixcatlán' },
    { value: 20253, label: 'San Pedro Ixtlahuaca' },
    { value: 20254, label: 'San Pedro Jaltepetongo' },
    { value: 20255, label: 'San Pedro Jicayán' },
    { value: 20256, label: 'San Pedro Jocotipac' },
    { value: 20257, label: 'San Pedro Juchatengo' },
    { value: 20258, label: 'San Pedro Mártir' },
    { value: 20259, label: 'San Pedro Mártir Quiechapa' },
    { value: 20260, label: 'San Pedro Mártir Yucuxaco' },
    { value: 20261, label: 'San Pedro Mixtepec Distrito 22' },
    { value: 20262, label: 'San Pedro Mixtepec Distrito 26' },
    { value: 20263, label: 'San Pedro Molinos' },
    { value: 20264, label: 'San Pedro Nopala' },
    { value: 20265, label: 'San Pedro Ocopetatillo' },
    { value: 20266, label: 'San Pedro Ocotepec' },
    { value: 20267, label: 'San Pedro Pochutla' },
    { value: 20268, label: 'San Pedro Quiatoni' },
    { value: 20269, label: 'San Pedro Sochiápam' },
    { value: 20270, label: 'San Pedro Tapanatepec' },
    { value: 20271, label: 'San Pedro Taviche' },
    { value: 20272, label: 'San Pedro Teozacoalco' },
    { value: 20273, label: 'San Pedro Teutila' },
    { value: 20274, label: 'San Pedro Tidaá' },
    { value: 20275, label: 'San Pedro Topiltepec' },
    { value: 20276, label: 'San Pedro Totolápam' },
    { value: 20277, label: 'Villa de Tututepec' },
    { value: 20278, label: 'San Pedro Yaneri' },
    { value: 20279, label: 'San Pedro Yólox' },
    { value: 20280, label: 'San Pedro y San Pablo Ayutla' },
    { value: 20281, label: 'Villa de Etla' },
    { value: 20282, label: 'San Pedro y San Pablo Teposcolula' },
    { value: 20283, label: 'San Pedro y San Pablo Tequixtepec' },
    { value: 20284, label: 'San Pedro Yucunama' },
    { value: 20285, label: 'San Raymundo Jalpan' },
    { value: 20286, label: 'San Sebastián Abasolo' },
    { value: 20287, label: 'San Sebastián Coatlán' },
    { value: 20288, label: 'San Sebastián Ixcapa' },
    { value: 20289, label: 'San Sebastián Nicananduta' },
    { value: 20290, label: 'San Sebastián Río Hondo' },
    { value: 20291, label: 'San Sebastián Tecomaxtlahuaca' },
    { value: 20292, label: 'San Sebastián Teitipac' },
    { value: 20293, label: 'San Sebastián Tutla' },
    { value: 20294, label: 'San Simón Almolongas' },
    { value: 20295, label: 'San Simón Zahuatlán' },
    { value: 20296, label: 'Santa Ana' },
    { value: 20297, label: 'Santa Ana Ateixtlahuaca' },
    { value: 20298, label: 'Santa Ana Cuauhtémoc' },
    { value: 20299, label: 'Santa Ana del Valle' },
    { value: 20300, label: 'Santa Ana Tavela' },
    { value: 20301, label: 'Santa Ana Tlapacoyan' },
    { value: 20302, label: 'Santa Ana Yareni' },
    { value: 20303, label: 'Santa Ana Zegache' },
    { value: 20304, label: 'Santa Catalina Quierí' },
    { value: 20305, label: 'Santa Catarina Cuixtla' },
    { value: 20306, label: 'Santa Catarina Ixtepeji' },
    { value: 20307, label: 'Santa Catarina Juquila' },
    { value: 20308, label: 'Santa Catarina Lachatao' },
    { value: 20309, label: 'Santa Catarina Loxicha' },
    { value: 20310, label: 'Santa Catarina Mechoacán' },
    { value: 20311, label: 'Santa Catarina Minas' },
    { value: 20312, label: 'Santa Catarina Quiané' },
    { value: 20313, label: 'Santa Catarina Tayata' },
    { value: 20314, label: 'Santa Catarina Ticuá' },
    { value: 20315, label: 'Santa Catarina Yosonotú' },
    { value: 20316, label: 'Santa Catarina Zapoquila' },
    { value: 20317, label: 'Santa Cruz Acatepec' },
    { value: 20318, label: 'Santa Cruz Amilpas' },
    { value: 20319, label: 'Santa Cruz de Bravo' },
    { value: 20320, label: 'Santa Cruz Itundujia' },
    { value: 20321, label: 'Santa Cruz Mixtepec' },
    { value: 20322, label: 'Santa Cruz Nundaco' },
    { value: 20323, label: 'Santa Cruz Papalutla' },
    { value: 20324, label: 'Santa Cruz Tacache de Mina' },
    { value: 20325, label: 'Santa Cruz Tacahua' },
    { value: 20326, label: 'Santa Cruz Tayata' },
    { value: 20327, label: 'Santa Cruz Xitla' },
    { value: 20328, label: 'Santa Cruz Xoxocotlán' },
    { value: 20329, label: 'Santa Cruz Zenzontepec' },
    { value: 20330, label: 'Santa Gertrudis' },
    { value: 20331, label: 'Santa Inés del Monte' },
    { value: 20332, label: 'Santa Inés Yatzeche' },
    { value: 20333, label: 'Santa Lucía del Camino' },
    { value: 20334, label: 'Santa Lucía Miahuatlán' },
    { value: 20335, label: 'Santa Lucía Monteverde' },
    { value: 20336, label: 'Santa Lucía Ocotlán' },
    { value: 20337, label: 'Santa María Alotepec' },
    { value: 20338, label: 'Santa María Apazco' },
    { value: 20339, label: 'Santa María la Asunción' },
    { value: 20340, label: 'Heroica Ciudad de Tlaxiaco' },
    { value: 20341, label: 'Ayoquezco de Aldama' },
    { value: 20342, label: 'Santa María Atzompa' },
    { value: 20343, label: 'Santa María Camotlán' },
    { value: 20344, label: 'Santa María Colotepec' },
    { value: 20345, label: 'Santa María Cortijo' },
    { value: 20346, label: 'Santa María Coyotepec' },
    { value: 20347, label: 'Santa María Chachoápam' },
    { value: 20348, label: 'Villa de Chilapa de Díaz' },
    { value: 20349, label: 'Santa María Chilchotla' },
    { value: 20350, label: 'Santa María Chimalapa' },
    { value: 20351, label: 'Santa María del Rosario' },
    { value: 20352, label: 'Santa María del Tule' },
    { value: 20353, label: 'Santa María Ecatepec' },
    { value: 20354, label: 'Santa María Guelacé' },
    { value: 20355, label: 'Santa María Guienagati' },
    { value: 20356, label: 'Santa María Huatulco' },
    { value: 20357, label: 'Santa María Huazolotitlán' },
    { value: 20358, label: 'Santa María Ipalapa' },
    { value: 20359, label: 'Santa María Ixcatlán' },
    { value: 20360, label: 'Santa María Jacatepec' },
    { value: 20361, label: 'Santa María Jalapa del Marqués' },
    { value: 20362, label: 'Santa María Jaltianguis' },
    { value: 20363, label: 'Santa María Lachixío' },
    { value: 20364, label: 'Santa María Mixtequilla' },
    { value: 20365, label: 'Santa María Nativitas' },
    { value: 20366, label: 'Santa María Nduayaco' },
    { value: 20367, label: 'Santa María Ozolotepec' },
    { value: 20368, label: 'Santa María Pápalo' },
    { value: 20369, label: 'Santa María Peñoles' },
    { value: 20370, label: 'Santa María Petapa' },
    { value: 20371, label: 'Santa María Quiegolani' },
    { value: 20372, label: 'Santa María Sola' },
    { value: 20373, label: 'Santa María Tataltepec' },
    { value: 20374, label: 'Santa María Tecomavaca' },
    { value: 20375, label: 'Santa María Temaxcalapa' },
    { value: 20376, label: 'Santa María Temaxcaltepec' },
    { value: 20377, label: 'Santa María Teopoxco' },
    { value: 20378, label: 'Santa María Tepantlali' },
    { value: 20379, label: 'Santa María Texcatitlán' },
    { value: 20380, label: 'Santa María Tlahuitoltepec' },
    { value: 20381, label: 'Santa María Tlalixtac' },
    { value: 20382, label: 'Santa María Tonameca' },
    { value: 20383, label: 'Santa María Totolapilla' },
    { value: 20384, label: 'Santa María Xadani' },
    { value: 20385, label: 'Santa María Yalina' },
    { value: 20386, label: 'Santa María Yavesía' },
    { value: 20387, label: 'Santa María Yolotepec' },
    { value: 20388, label: 'Santa María Yosoyúa' },
    { value: 20389, label: 'Santa María Yucuhiti' },
    { value: 20390, label: 'Santa María Zacatepec' },
    { value: 20391, label: 'Santa María Zaniza' },
    { value: 20392, label: 'Santa María Zoquitlán' },
    { value: 20393, label: 'Santiago Amoltepec' },
    { value: 20394, label: 'Santiago Apoala' },
    { value: 20395, label: 'Santiago Apóstol' },
    { value: 20396, label: 'Santiago Astata' },
    { value: 20397, label: 'Santiago Atitlán' },
    { value: 20398, label: 'Santiago Ayuquililla' },
    { value: 20399, label: 'Santiago Cacaloxtepec' },
    { value: 20400, label: 'Santiago Camotlán' },
    { value: 20401, label: 'Santiago Comaltepec' },
    { value: 20402, label: 'Santiago Chazumba' },
    { value: 20403, label: 'Santiago Choápam' },
    { value: 20404, label: 'Santiago del Río' },
    { value: 20405, label: 'Santiago Huajolotitlán' },
    { value: 20406, label: 'Santiago Huauclilla' },
    { value: 20407, label: 'Santiago Ihuitlán Plumas' },
    { value: 20408, label: 'Santiago Ixcuintepec' },
    { value: 20409, label: 'Santiago Ixtayutla' },
    { value: 20410, label: 'Santiago Jamiltepec' },
    { value: 20411, label: 'Santiago Jocotepec' },
    { value: 20412, label: 'Santiago Juxtlahuaca' },
    { value: 20413, label: 'Santiago Lachiguiri' },
    { value: 20414, label: 'Santiago Lalopa' },
    { value: 20415, label: 'Santiago Laollaga' },
    { value: 20416, label: 'Santiago Laxopa' },
    { value: 20417, label: 'Santiago Llano Grande' },
    { value: 20418, label: 'Santiago Matatlán' },
    { value: 20419, label: 'Santiago Miltepec' },
    { value: 20420, label: 'Santiago Minas' },
    { value: 20421, label: 'Santiago Nacaltepec' },
    { value: 20422, label: 'Santiago Nejapilla' },
    { value: 20423, label: 'Santiago Nundiche' },
    { value: 20424, label: 'Santiago Nuyoó' },
    { value: 20425, label: 'Santiago Pinotepa Nacional' },
    { value: 20426, label: 'Santiago Suchilquitongo' },
    { value: 20427, label: 'Santiago Tamazola' },
    { value: 20428, label: 'Santiago Tapextla' },
    { value: 20429, label: 'Villa Tejúpam de la Unión' },
    { value: 20430, label: 'Santiago Tenango' },
    { value: 20431, label: 'Santiago Tepetlapa' },
    { value: 20432, label: 'Santiago Tetepec' },
    { value: 20433, label: 'Santiago Texcalcingo' },
    { value: 20434, label: 'Santiago Textitlán' },
    { value: 20435, label: 'Santiago Tilantongo' },
    { value: 20436, label: 'Santiago Tillo' },
    { value: 20437, label: 'Santiago Tlazoyaltepec' },
    { value: 20438, label: 'Santiago Xanica' },
    { value: 20439, label: 'Santiago Xiacuí' },
    { value: 20440, label: 'Santiago Yaitepec' },
    { value: 20441, label: 'Santiago Yaveo' },
    { value: 20442, label: 'Santiago Yolomécatl' },
    { value: 20443, label: 'Santiago Yosondúa' },
    { value: 20444, label: 'Santiago Yucuyachi' },
    { value: 20445, label: 'Santiago Zacatepec' },
    { value: 20446, label: 'Santiago Zoochila' },
    { value: 20447, label: 'Nuevo Zoquiápam' },
    { value: 20448, label: 'Santo Domingo Ingenio' },
    { value: 20449, label: 'Santo Domingo Albarradas' },
    { value: 20450, label: 'Santo Domingo Armenta' },
    { value: 20451, label: 'Santo Domingo Chihuitán' },
    { value: 20452, label: 'Santo Domingo de Morelos' },
    { value: 20453, label: 'Santo Domingo Ixcatlán' },
    { value: 20454, label: 'Santo Domingo Nuxaá' },
    { value: 20455, label: 'Santo Domingo Ozolotepec' },
    { value: 20456, label: 'Santo Domingo Petapa' },
    { value: 20457, label: 'Santo Domingo Roayaga' },
    { value: 20458, label: 'Santo Domingo Tehuantepec' },
    { value: 20459, label: 'Santo Domingo Teojomulco' },
    { value: 20460, label: 'Santo Domingo Tepuxtepec' },
    { value: 20461, label: 'Santo Domingo Tlatayápam' },
    { value: 20462, label: 'Santo Domingo Tomaltepec' },
    { value: 20463, label: 'Santo Domingo Tonalá' },
    { value: 20464, label: 'Santo Domingo Tonaltepec' },
    { value: 20465, label: 'Santo Domingo Xagacía' },
    { value: 20466, label: 'Santo Domingo Yanhuitlán' },
    { value: 20467, label: 'Santo Domingo Yodohino' },
    { value: 20468, label: 'Santo Domingo Zanatepec' },
    { value: 20469, label: 'Santos Reyes Nopala' },
    { value: 20470, label: 'Santos Reyes Pápalo' },
    { value: 20471, label: 'Santos Reyes Tepejillo' },
    { value: 20472, label: 'Santos Reyes Yucuná' },
    { value: 20473, label: 'Santo Tomás Jalieza' },
    { value: 20474, label: 'Santo Tomás Mazaltepec' },
    { value: 20475, label: 'Santo Tomás Ocotepec' },
    { value: 20476, label: 'Santo Tomás Tamazulapan' },
    { value: 20477, label: 'San Vicente Coatlán' },
    { value: 20478, label: 'San Vicente Lachixío' },
    { value: 20479, label: 'San Vicente Nuñú' },
    { value: 20480, label: 'Silacayoápam' },
    { value: 20481, label: 'Sitio de Xitlapehua' },
    { value: 20482, label: 'Soledad Etla' },
    { value: 20483, label: 'Villa de Tamazulápam del Progreso' },
    { value: 20484, label: 'Tanetze de Zaragoza' },
    { value: 20485, label: 'Taniche' },
    { value: 20486, label: 'Tataltepec de Valdés' },
    { value: 20487, label: 'Teococuilco de Marcos Pérez' },
    { value: 20488, label: 'Teotitlán de Flores Magón' },
    { value: 20489, label: 'Teotitlán del Valle' },
    { value: 20490, label: 'Teotongo' },
    { value: 20491, label: 'Tepelmeme Villa de Morelos' },
    {
        value: 20492,
        label:
            'Heroica Villa Tezoatlán de Segura y Luna, Cuna de la Independencia de Oaxaca',
    },
    { value: 20493, label: 'San Jerónimo Tlacochahuaya' },
    { value: 20494, label: 'Tlacolula de Matamoros' },
    { value: 20495, label: 'Tlacotepec Plumas' },
    { value: 20496, label: 'Tlalixtac de Cabrera' },
    { value: 20497, label: 'Totontepec Villa de Morelos' },
    { value: 20498, label: 'Trinidad Zaachila' },
    { value: 20499, label: 'La Trinidad Vista Hermosa' },
    { value: 20500, label: 'Unión Hidalgo' },
    { value: 20501, label: 'Valerio Trujano' },
    { value: 20502, label: 'San Juan Bautista Valle Nacional' },
    { value: 20503, label: 'Villa Díaz Ordaz' },
    { value: 20504, label: 'Yaxe' },
    { value: 20505, label: 'Magdalena Yodocono de Porfirio Díaz' },
    { value: 20506, label: 'Yogana' },
    { value: 20507, label: 'Yutanduchi de Guerrero' },
    { value: 20508, label: 'Villa de Zaachila' },
    { value: 20509, label: 'San Mateo Yucutindoo' },
    { value: 20510, label: 'Zapotitlán Lagunas' },
    { value: 20511, label: 'Zapotitlán Palmas' },
    { value: 20512, label: 'Santa Inés de Zaragoza' },
    { value: 20513, label: 'Zimatlán de Álvarez' },
    { value: 20514, label: 'Otros Municipios' },
    { value: 20515, label: 'Acajete' },
    { value: 20516, label: 'Acateno' },
    { value: 20517, label: 'Acatzingo' },
    { value: 20518, label: 'Acteopan' },
    { value: 20519, label: 'Ahuatlán' },
    { value: 20520, label: 'Ahuazotepec' },
    { value: 20521, label: 'Ahuehuetitla' },
    { value: 20522, label: 'Ajalpan' },
    { value: 20523, label: 'Albino Zertuche' },
    { value: 20524, label: 'Aljojuca' },
    { value: 20525, label: 'Altepexi' },
    { value: 20526, label: 'Amixtlán' },
    { value: 20527, label: 'Amozoc' },
    { value: 20528, label: 'Aquixtla' },
    { value: 20529, label: 'Atempan' },
    { value: 20530, label: 'Atexcal' },
    { value: 20531, label: 'Atlixco' },
    { value: 20532, label: 'Atoyatempan' },
    { value: 20533, label: 'Atzala' },
    { value: 20534, label: 'Atzitzihuacán' },
    { value: 20535, label: 'Atzitzintla' },
    { value: 20536, label: 'Axutla' },
    { value: 20537, label: 'Ayotoxco de Guerrero' },
    { value: 20538, label: 'Calpan' },
    { value: 20539, label: 'Caltepec' },
    { value: 20540, label: 'Camocuautla' },
    { value: 20541, label: 'Caxhuacan' },
    { value: 20542, label: 'Coatepec' },
    { value: 20543, label: 'Coatzingo' },
    { value: 20544, label: 'Cohetzala' },
    { value: 20545, label: 'Cohuecan' },
    { value: 20546, label: 'Coronango' },
    { value: 20547, label: 'Coxcatlán' },
    { value: 20548, label: 'Coyomeapan' },
    { value: 20549, label: 'Cuapiaxtla de Madero' },
    { value: 20550, label: 'Cuautempan' },
    { value: 20551, label: 'Cuautinchán' },
    { value: 20552, label: 'Cuautlancingo' },
    { value: 20553, label: 'Cuayuca de Andrade' },
    { value: 20554, label: 'Cuetzalan del Progreso' },
    { value: 20555, label: 'Cuyoaco' },
    { value: 20556, label: 'Chalchicomula de Sesma' },
    { value: 20557, label: 'Chapulco' },
    { value: 20558, label: 'Chiautzingo' },
    { value: 20559, label: 'Chiconcuautla' },
    { value: 20560, label: 'Chichiquila' },
    { value: 20561, label: 'Chietla' },
    { value: 20562, label: 'Chigmecatitlán' },
    { value: 20563, label: 'Chignahuapan' },
    { value: 20564, label: 'Chignautla' },
    { value: 20565, label: 'Chila' },
    { value: 20566, label: 'Chila de la Sal' },
    { value: 20567, label: 'Honey' },
    { value: 20568, label: 'Chilchotla' },
    { value: 20569, label: 'Chinantla' },
    { value: 20570, label: 'Domingo Arenas' },
    { value: 20999, label: 'Epatlán' },
    { value: 21001, label: 'Esperanza' },
    { value: 21002, label: 'Francisco Z. Mena' },
    { value: 21003, label: 'General Felipe Ángeles' },
    { value: 21004, label: 'Hermenegildo Galeana' },
    { value: 21005, label: 'Huaquechula' },
    { value: 21006, label: 'Huatlatlauca' },
    { value: 21007, label: 'Huauchinango' },
    { value: 21008, label: 'Huehuetlán el Chico' },
    { value: 21009, label: 'Huejotzingo' },
    { value: 21010, label: 'Hueyapan' },
    { value: 21011, label: 'Hueytamalco' },
    { value: 21012, label: 'Hueytlalpan' },
    { value: 21013, label: 'Huitzilan de Serdán' },
    { value: 21014, label: 'Huitziltepec' },
    { value: 21015, label: 'Atlequizayan' },
    { value: 21016, label: 'Ixcamilpa de Guerrero' },
    { value: 21017, label: 'Ixcaquixtla' },
    { value: 21018, label: 'Ixtacamaxtitlán' },
    { value: 21019, label: 'Ixtepec' },
    { value: 21020, label: 'Izúcar de Matamoros' },
    { value: 21021, label: 'Jalpan' },
    { value: 21022, label: 'Jolalpan' },
    { value: 21023, label: 'Jonotla' },
    { value: 21024, label: 'Jopala' },
    { value: 21025, label: 'Juan C. Bonilla' },
    { value: 21026, label: 'Juan Galindo' },
    { value: 21027, label: 'Juan N. Méndez' },
    { value: 21028, label: 'Lafragua' },
    { value: 21029, label: 'Libres' },
    { value: 21030, label: 'La Magdalena Tlatlauquitepec' },
    { value: 21031, label: 'Mazapiltepec de Juárez' },
    { value: 21032, label: 'Mixtla' },
    { value: 21033, label: 'Molcaxac' },
    { value: 21034, label: 'Cañada Morelos' },
    { value: 21035, label: 'Naupan' },
    { value: 21036, label: 'Nauzontla' },
    { value: 21037, label: 'Nealtican' },
    { value: 21038, label: 'Nicolás Bravo' },
    { value: 21039, label: 'Nopalucan' },
    { value: 21040, label: 'Ocoyucan' },
    { value: 21041, label: 'Olintla' },
    { value: 21042, label: 'Oriental' },
    { value: 21043, label: 'Pahuatlán' },
    { value: 21044, label: 'Palmar de Bravo' },
    { value: 21045, label: 'Petlalcingo' },
    { value: 21046, label: 'Piaxtla' },
    { value: 21047, label: 'Puebla' },
    { value: 21048, label: 'Quecholac' },
    { value: 21049, label: 'Quimixtlán' },
    { value: 21050, label: 'Rafael Lara Grajales' },
    { value: 21051, label: 'Los Reyes de Juárez' },
    { value: 21052, label: 'San Andrés Cholula' },
    { value: 21053, label: 'San Antonio Cañada' },
    { value: 21054, label: 'San Diego la Mesa Tochimiltzingo' },
    { value: 21055, label: 'San Felipe Teotlalcingo' },
    { value: 21056, label: 'San Felipe Tepatlán' },
    { value: 21057, label: 'San Gabriel Chilac' },
    { value: 21058, label: 'San Gregorio Atzompa' },
    { value: 21059, label: 'San Jerónimo Tecuanipan' },
    { value: 21060, label: 'San Jerónimo Xayacatlán' },
    { value: 21061, label: 'San José Chiapa' },
    { value: 21062, label: 'San José Miahuatlán' },
    { value: 21063, label: 'San Juan Atenco' },
    { value: 21064, label: 'San Juan Atzompa' },
    { value: 21065, label: 'San Martín Texmelucan' },
    { value: 21066, label: 'San Martín Totoltepec' },
    { value: 21067, label: 'San Matías Tlalancaleca' },
    { value: 21068, label: 'San Miguel Ixitlán' },
    { value: 21069, label: 'San Miguel Xoxtla' },
    { value: 21070, label: 'San Nicolás Buenos Aires' },
    { value: 21071, label: 'San Nicolás de los Ranchos' },
    { value: 21072, label: 'San Pablo Anicano' },
    { value: 21073, label: 'San Pedro Cholula' },
    { value: 21074, label: 'San Pedro Yeloixtlahuaca' },
    { value: 21075, label: 'San Salvador el Seco' },
    { value: 21076, label: 'San Salvador el Verde' },
    { value: 21077, label: 'San Salvador Huixcolotla' },
    { value: 21078, label: 'San Sebastián Tlacotepec' },
    { value: 21079, label: 'Santa Catarina Tlaltempan' },
    { value: 21080, label: 'Santa Inés Ahuatempan' },
    { value: 21081, label: 'Santa Isabel Cholula' },
    { value: 21082, label: 'Santiago Miahuatlán' },
    { value: 21083, label: 'Huehuetlán el Grande' },
    { value: 21084, label: 'Santo Tomás Hueyotlipan' },
    { value: 21085, label: 'Soltepec' },
    { value: 21086, label: 'Tecali de Herrera' },
    { value: 21087, label: 'Tecamachalco' },
    { value: 21088, label: 'Tecomatlán' },
    { value: 21089, label: 'Tehuacán' },
    { value: 21090, label: 'Tehuitzingo' },
    { value: 21091, label: 'Tenampulco' },
    { value: 21092, label: 'Teopantlán' },
    { value: 21093, label: 'Teotlalco' },
    { value: 21094, label: 'Tepanco de López' },
    { value: 21095, label: 'Tepango de Rodríguez' },
    { value: 21096, label: 'Tepatlaxco de Hidalgo' },
    { value: 21097, label: 'Tepeaca' },
    { value: 21098, label: 'Tepemaxalco' },
    { value: 21099, label: 'Tepeojuma' },
    { value: 21100, label: 'Tepetzintla' },
    { value: 21101, label: 'Tepexco' },
    { value: 21102, label: 'Tepexi de Rodríguez' },
    { value: 21103, label: 'Tepeyahualco' },
    { value: 21104, label: 'Tepeyahualco de Cuauhtémoc' },
    { value: 21105, label: 'Tetela de Ocampo' },
    { value: 21106, label: 'Teteles de Avila Castillo' },
    { value: 21107, label: 'Teziutlán' },
    { value: 21108, label: 'Tianguismanalco' },
    { value: 21109, label: 'Tilapa' },
    { value: 21110, label: 'Tlacotepec de Benito Juárez' },
    { value: 21111, label: 'Tlacuilotepec' },
    { value: 21112, label: 'Tlachichuca' },
    { value: 21113, label: 'Tlahuapan' },
    { value: 21114, label: 'Tlaltenango' },
    { value: 21115, label: 'Tlanepantla' },
    { value: 21116, label: 'Tlaola' },
    { value: 21117, label: 'Tlapacoya' },
    { value: 21118, label: 'Tlapanalá' },
    { value: 21119, label: 'Tlatlauquitepec' },
    { value: 21120, label: 'Tlaxco' },
    { value: 21121, label: 'Tochimilco' },
    { value: 21122, label: 'Tochtepec' },
    { value: 21123, label: 'Totoltepec de Guerrero' },
    { value: 21124, label: 'Tulcingo' },
    { value: 21125, label: 'Tuzamapan de Galeana' },
    { value: 21126, label: 'Tzicatlacoyan' },
    { value: 21127, label: 'Xayacatlán de Bravo' },
    { value: 21128, label: 'Xicotepec' },
    { value: 21129, label: 'Xicotlán' },
    { value: 21130, label: 'Xiutetelco' },
    { value: 21131, label: 'Xochiapulco' },
    { value: 21132, label: 'Xochiltepec' },
    { value: 21133, label: 'Xochitlán de Vicente Suárez' },
    { value: 21134, label: 'Xochitlán Todos Santos' },
    { value: 21135, label: 'Yaonáhuac' },
    { value: 21136, label: 'Yehualtepec' },
    { value: 21137, label: 'Zacapala' },
    { value: 21138, label: 'Zacapoaxtla' },
    { value: 21139, label: 'Zacatlán' },
    { value: 21140, label: 'Zapotitlán' },
    { value: 21141, label: 'Zapotitlán de Méndez' },
    { value: 21142, label: 'Zautla' },
    { value: 21143, label: 'Zihuateutla' },
    { value: 21144, label: 'Zinacatepec' },
    { value: 21145, label: 'Zongozotla' },
    { value: 21146, label: 'Zoquiapan' },
    { value: 21147, label: 'Zoquitlán' },
    { value: 21148, label: 'Amealco de Bonfil' },
    { value: 21149, label: 'Pinal de Amoles' },
    { value: 21150, label: 'Arroyo Seco' },
    { value: 21151, label: 'Cadereyta de Montes' },
    { value: 21152, label: 'Colón' },
    { value: 21153, label: 'Corregidora' },
    { value: 21154, label: 'Ezequiel Montes' },
    { value: 21155, label: 'Huimilpan' },
    { value: 21156, label: 'Jalpan de Serra' },
    { value: 21157, label: 'Landa de Matamoros' },
    { value: 21158, label: 'El Marqués' },
    { value: 21159, label: 'Pedro Escobedo' },
    { value: 21160, label: 'Peñamiller' },
    { value: 21161, label: 'Querétaro' },
    { value: 21162, label: 'San Joaquín' },
    { value: 21163, label: 'Tequisquiapan' },
    { value: 21164, label: 'Cozumel' },
    { value: 21165, label: 'Felipe Carrillo Puerto' },
    { value: 21166, label: 'Isla Mujeres' },
    { value: 21167, label: 'Othón P. Blanco' },
    { value: 21168, label: 'José María Morelos' },
    { value: 21169, label: 'Solidaridad' },
    { value: 21170, label: 'Tulum' },
    { value: 21171, label: 'Bacalar' },
    { value: 21172, label: 'Puerto Morelos' },
    { value: 21173, label: 'Ahualulco' },
    { value: 21174, label: 'Alaquines' },
    { value: 21175, label: 'Aquismón' },
    { value: 21176, label: 'Armadillo de los Infante' },
    { value: 21177, label: 'Cárdenas' },
    { value: 21178, label: 'Catorce' },
    { value: 21179, label: 'Cedral' },
    { value: 21180, label: 'Cerritos' },
    { value: 21181, label: 'Cerro de San Pedro' },
    { value: 21182, label: 'Ciudad del Maíz' },
    { value: 21183, label: 'Ciudad Fernández' },
    { value: 21184, label: 'Tancanhuitz' },
    { value: 21185, label: 'Ciudad Valles' },
    { value: 21186, label: 'Charcas' },
    { value: 21187, label: 'Ebano' },
    { value: 21188, label: 'Guadalcázar' },
    { value: 21189, label: 'Huehuetlán' },
    { value: 21190, label: 'Matehuala' },
    { value: 21191, label: 'Mexquitic de Carmona' },
    { value: 21192, label: 'Moctezuma' },
    { value: 21193, label: 'Rioverde' },
    { value: 21194, label: 'Salinas' },
    { value: 21195, label: 'San Antonio' },
    { value: 21196, label: 'San Ciro de Acosta' },
    { value: 21197, label: 'San Luis Potosí' },
    { value: 21198, label: 'San Martín Chalchicuautla' },
    { value: 21199, label: 'San Nicolás Tolentino' },
    { value: 21200, label: 'Santa María del Río' },
    { value: 21201, label: 'Santo Domingo' },
    { value: 21202, label: 'San Vicente Tancuayalab' },
    { value: 21203, label: 'Soledad de Graciano Sánchez' },
    { value: 21204, label: 'Tamasopo' },
    { value: 21205, label: 'Tamazunchale' },
    { value: 21206, label: 'Tampacán' },
    { value: 21207, label: 'Tampamolón Corona' },
    { value: 21208, label: 'Tamuín' },
    { value: 21209, label: 'Tanlajás' },
    { value: 21210, label: 'Tanquián de Escobedo' },
    { value: 21211, label: 'Tierra Nueva' },
    { value: 21212, label: 'Vanegas' },
    { value: 21213, label: 'Venado' },
    { value: 21214, label: 'Villa de Arriaga' },
    { value: 21215, label: 'Villa de Guadalupe' },
    { value: 21216, label: 'Villa de la Paz' },
    { value: 21217, label: 'Villa de Ramos' },
    { value: 22001, label: 'Villa de Reyes' },
    { value: 22002, label: 'Villa Juárez' },
    { value: 22003, label: 'Axtla de Terrazas' },
    { value: 22004, label: 'Xilitla' },
    { value: 22005, label: 'Villa de Arista' },
    { value: 22006, label: 'Matlapa' },
    { value: 22007, label: 'El Naranjo' },
    { value: 22008, label: 'Ahome' },
    { value: 22009, label: 'Angostura' },
    { value: 22010, label: 'Badiraguato' },
    { value: 22011, label: 'Concordia' },
    { value: 22012, label: 'Cosalá' },
    { value: 22013, label: 'Culiacán' },
    { value: 22014, label: 'Choix' },
    { value: 22015, label: 'Elota' },
    { value: 22016, label: 'Escuinapa' },
    { value: 22017, label: 'El Fuerte' },
    { value: 22018, label: 'Guasave' },
    { value: 22998, label: 'Mazatlán' },
    { value: 23001, label: 'Mocorito' },
    { value: 23002, label: 'Salvador Alvarado' },
    { value: 23003, label: 'San Ignacio' },
    { value: 23004, label: 'Sinaloa' },
    { value: 23005, label: 'Navolato' },
    { value: 23006, label: 'Aconchi' },
    { value: 23007, label: 'Agua Prieta' },
    { value: 23008, label: 'Alamos' },
    { value: 23009, label: 'Altar' },
    { value: 23010, label: 'Arivechi' },
    { value: 23011, label: 'Arizpe' },
    { value: 24001, label: 'Atil' },
    { value: 24002, label: 'Bacadéhuachi' },
    { value: 24003, label: 'Bacanora' },
    { value: 24004, label: 'Bacerac' },
    { value: 24005, label: 'Bacoachi' },
    { value: 24006, label: 'Bácum' },
    { value: 24007, label: 'Banámichi' },
    { value: 24008, label: 'Baviácora' },
    { value: 24009, label: 'Bavispe' },
    { value: 24010, label: 'Benjamín Hill' },
    { value: 24011, label: 'Caborca' },
    { value: 24012, label: 'Cajeme' },
    { value: 24013, label: 'Cananea' },
    { value: 24014, label: 'Carbó' },
    { value: 24015, label: 'La Colorada' },
    { value: 24016, label: 'Cucurpe' },
    { value: 24017, label: 'Cumpas' },
    { value: 24018, label: 'Divisaderos' },
    { value: 24019, label: 'Empalme' },
    { value: 24020, label: 'Etchojoa' },
    { value: 24021, label: 'Fronteras' },
    { value: 24022, label: 'Granados' },
    { value: 24023, label: 'Guaymas' },
    { value: 24024, label: 'Hermosillo' },
    { value: 24025, label: 'Huachinera' },
    { value: 24026, label: 'Huásabas' },
    { value: 24027, label: 'Huatabampo' },
    { value: 24028, label: 'Huépac' },
    { value: 24029, label: 'Imuris' },
    { value: 24030, label: 'Naco' },
    { value: 24031, label: 'Nácori Chico' },
    { value: 24032, label: 'Nacozari de García' },
    { value: 24033, label: 'Navojoa' },
    { value: 24034, label: 'Nogales' },
    { value: 24035, label: 'Onavas' },
    { value: 24036, label: 'Opodepe' },
    { value: 24037, label: 'Oquitoa' },
    { value: 24038, label: 'Pitiquito' },
    { value: 24039, label: 'Puerto Peñasco' },
    { value: 24040, label: 'Quiriego' },
    { value: 24041, label: 'Sahuaripa' },
    { value: 24042, label: 'San Felipe de Jesús' },
    { value: 24043, label: 'San Javier' },
    { value: 24044, label: 'San Luis Río Colorado' },
    { value: 24045, label: 'San Miguel de Horcasitas' },
    { value: 24046, label: 'San Pedro de la Cueva' },
    { value: 24047, label: 'Santa Cruz' },
    { value: 24048, label: 'Sáric' },
    { value: 24049, label: 'Soyopa' },
    { value: 24050, label: 'Suaqui Grande' },
    { value: 24051, label: 'Tepache' },
    { value: 24052, label: 'Trincheras' },
    { value: 24053, label: 'Tubutama' },
    { value: 24054, label: 'Ures' },
    { value: 24055, label: 'Villa Pesqueira' },
    { value: 24056, label: 'Yécora' },
    { value: 24057, label: 'General Plutarco Elías Calles' },
    { value: 24058, label: 'San Ignacio Río Muerto' },
    { value: 25001, label: 'Balancán' },
    { value: 25002, label: 'Centla' },
    { value: 25003, label: 'Centro' },
    { value: 25004, label: 'Comalcalco' },
    { value: 25005, label: 'Cunduacán' },
    { value: 25006, label: 'Huimanguillo' },
    { value: 25007, label: 'Jalapa' },
    { value: 25008, label: 'Jalpa de Méndez' },
    { value: 25009, label: 'Jonuta' },
    { value: 25010, label: 'Macuspana' },
    { value: 25011, label: 'Nacajuca' },
    { value: 25012, label: 'Paraíso' },
    { value: 25013, label: 'Tacotalpa' },
    { value: 25014, label: 'Teapa' },
    { value: 25015, label: 'Tenosique' },
    { value: 25016, label: 'Altamira' },
    { value: 25017, label: 'Antiguo Morelos' },
    { value: 25018, label: 'Burgos' },
    { value: 26001, label: 'Casas' },
    { value: 26002, label: 'Ciudad Madero' },
    { value: 26003, label: 'Cruillas' },
    { value: 26004, label: 'González' },
    { value: 26005, label: 'Güémez' },
    { value: 26006, label: 'Gustavo Díaz Ordaz' },
    { value: 26007, label: 'Jaumave' },
    { value: 26008, label: 'Llera' },
    { value: 26009, label: 'Mainero' },
    { value: 26010, label: 'El Mante' },
    { value: 26011, label: 'Méndez' },
    { value: 26012, label: 'Mier' },
    { value: 26013, label: 'Miguel Alemán' },
    { value: 26014, label: 'Miquihuana' },
    { value: 26015, label: 'Nuevo Laredo' },
    { value: 26016, label: 'Nuevo Morelos' },
    { value: 26017, label: 'Padilla' },
    { value: 26018, label: 'Palmillas' },
    { value: 26019, label: 'Reynosa' },
    { value: 26020, label: 'Río Bravo' },
    { value: 26021, label: 'San Carlos' },
    { value: 26022, label: 'Soto la Marina' },
    { value: 26023, label: 'Tampico' },
    { value: 26024, label: 'Tula' },
    { value: 26025, label: 'Valle Hermoso' },
    { value: 26026, label: 'Xicoténcatl' },
    { value: 26027, label: 'Amaxac de Guerrero' },
    { value: 26028, label: 'Apetatitlán de Antonio Carvajal' },
    { value: 26029, label: 'Atlangatepec' },
    { value: 26030, label: 'Atltzayanca' },
    { value: 26031, label: 'Apizaco' },
    { value: 26032, label: 'Calpulalpan' },
    { value: 26033, label: 'El Carmen Tequexquitla' },
    { value: 26034, label: 'Cuapiaxtla' },
    { value: 26035, label: 'Cuaxomulco' },
    { value: 26036, label: 'Chiautempan' },
    { value: 26037, label: 'Muñoz de Domingo Arenas' },
    { value: 26038, label: 'Españita' },
    { value: 26039, label: 'Huamantla' },
    { value: 26040, label: 'Hueyotlipan' },
    { value: 26041, label: 'Ixtacuixtla de Mariano Matamoros' },
    { value: 26042, label: 'Ixtenco' },
    { value: 26043, label: 'Mazatecochco de José María Morelos' },
    { value: 26044, label: 'Contla de Juan Cuamatzi' },
    { value: 26045, label: 'Tepetitla de Lardizábal' },
    { value: 26046, label: 'Sanctórum de Lázaro Cárdenas' },
    { value: 26047, label: 'Nanacamilpa de Mariano Arista' },
    { value: 26048, label: 'Acuamanala de Miguel Hidalgo' },
    { value: 26049, label: 'Natívitas' },
    { value: 26050, label: 'Panotla' },
    { value: 26051, label: 'San Pablo del Monte' },
    { value: 26052, label: 'Santa Cruz Tlaxcala' },
    { value: 26053, label: 'Teolocholco' },
    { value: 26054, label: 'Tepeyanco' },
    { value: 26055, label: 'Terrenate' },
    { value: 26056, label: 'Tetla de la Solidaridad' },
    { value: 26057, label: 'Tetlatlahuca' },
    { value: 26058, label: 'Tlaxcala' },
    { value: 26059, label: 'Tocatlán' },
    { value: 26060, label: 'Totolac' },
    { value: 26061, label: 'Ziltlaltépec de Trinidad Sánchez Santos' },
    { value: 26062, label: 'Tzompantepec' },
    { value: 26063, label: 'Xaloztoc' },
    { value: 26064, label: 'Xaltocan' },
    { value: 26065, label: 'Papalotla de Xicohténcatl' },
    { value: 26066, label: 'Xicohtzinco' },
    { value: 26067, label: 'Yauhquemehcan' },
    { value: 26068, label: 'Zacatelco' },
    { value: 26069, label: 'La Magdalena Tlaltelulco' },
    { value: 26070, label: 'San Damián Texóloc' },
    { value: 26071, label: 'San Francisco Tetlanohcan' },
    { value: 26072, label: 'San Jerónimo Zacualpan' },
    { value: 26998, label: 'San José Teacalco' },
    { value: 26999, label: 'San Juan Huactzinco' },
    { value: 27001, label: 'San Lorenzo Axocomanitla' },
    { value: 27002, label: 'San Lucas Tecopilco' },
    { value: 27003, label: 'Santa Ana Nopalucan' },
    { value: 27004, label: 'Santa Apolonia Teacalco' },
    { value: 27005, label: 'Santa Catarina Ayometla' },
    { value: 27006, label: 'Santa Cruz Quilehtla' },
    { value: 27007, label: 'Santa Isabel Xiloxoxtla' },
    { value: 27008, label: 'Acayucan' },
    { value: 27009, label: 'Acula' },
    { value: 27010, label: 'Acultzingo' },
    { value: 27011, label: 'Camarón de Tejeda' },
    { value: 27012, label: 'Alpatláhuac' },
    { value: 27013, label: 'Alto Lucero de Gutiérrez Barrios' },
    { value: 27014, label: 'Altotonga' },
    { value: 27015, label: 'Alvarado' },
    { value: 27016, label: 'Amatitlán' },
    { value: 27017, label: 'Naranjos Amatlán' },
    { value: 28001, label: 'Amatlán de los Reyes' },
    { value: 28002, label: 'Angel R. Cabada' },
    { value: 28003, label: 'La Antigua' },
    { value: 28004, label: 'Apazapan' },
    { value: 28005, label: 'Astacinga' },
    { value: 28006, label: 'Atlahuilco' },
    { value: 28007, label: 'Atzacan' },
    { value: 28008, label: 'Atzalan' },
    { value: 28009, label: 'Tlaltetela' },
    { value: 28010, label: 'Ayahualulco' },
    { value: 28011, label: 'Banderilla' },
    { value: 28012, label: 'Boca del Río' },
    { value: 28013, label: 'Calcahualco' },
    { value: 28014, label: 'Camerino Z. Mendoza' },
    { value: 28015, label: 'Carrillo Puerto' },
    { value: 28016, label: 'Catemaco' },
    { value: 28017, label: 'Cazones de Herrera' },
    { value: 28018, label: 'Cerro Azul' },
    { value: 28019, label: 'Citlaltépetl' },
    { value: 28020, label: 'Coacoatzintla' },
    { value: 28021, label: 'Coahuitlán' },
    { value: 28022, label: 'Coatzacoalcos' },
    { value: 28023, label: 'Coatzintla' },
    { value: 28024, label: 'Coetzala' },
    { value: 28025, label: 'Colipa' },
    { value: 28026, label: 'Comapa' },
    { value: 28027, label: 'Córdoba' },
    { value: 28028, label: 'Cosamaloapan de Carpio' },
    { value: 28029, label: 'Cosautlán de Carvajal' },
    { value: 28030, label: 'Coscomatepec' },
    { value: 28031, label: 'Cosoleacaque' },
    { value: 28032, label: 'Cotaxtla' },
    { value: 28033, label: 'Coxquihui' },
    { value: 28034, label: 'Coyutla' },
    { value: 28035, label: 'Cuichapa' },
    { value: 28036, label: 'Cuitláhuac' },
    { value: 28037, label: 'Chacaltianguis' },
    { value: 28038, label: 'Chalma' },
    { value: 28039, label: 'Chiconamel' },
    { value: 28040, label: 'Chiconquiaco' },
    { value: 28041, label: 'Chicontepec' },
    { value: 28042, label: 'Chinameca' },
    { value: 28043, label: 'Chinampa de Gorostiza' },
    { value: 29001, label: 'Las Choapas' },
    { value: 29002, label: 'Chocamán' },
    { value: 29003, label: 'Chontla' },
    { value: 29004, label: 'Chumatlán' },
    { value: 29005, label: 'Espinal' },
    { value: 29006, label: 'Filomeno Mata' },
    { value: 29007, label: 'Fortín' },
    { value: 29008, label: 'Gutiérrez Zamora' },
    { value: 29009, label: 'Hidalgotitlán' },
    { value: 29010, label: 'Huatusco' },
    { value: 29011, label: 'Huayacocotla' },
    { value: 29012, label: 'Hueyapan de Ocampo' },
    { value: 29013, label: 'Huiloapan de Cuauhtémoc' },
    { value: 29014, label: 'Ignacio de la Llave' },
    { value: 29015, label: 'Ilamatlán' },
    { value: 29016, label: 'Isla' },
    { value: 29017, label: 'Ixcatepec' },
    { value: 29018, label: 'Ixhuacán de los Reyes' },
    { value: 29019, label: 'Ixhuatlán del Café' },
    { value: 29020, label: 'Ixhuatlancillo' },
    { value: 29021, label: 'Ixhuatlán del Sureste' },
    { value: 29022, label: 'Ixhuatlán de Madero' },
    { value: 29023, label: 'Ixmatlahuacan' },
    { value: 29024, label: 'Ixtaczoquitlán' },
    { value: 29025, label: 'Jalacingo' },
    { value: 29026, label: 'Xalapa' },
    { value: 29027, label: 'Jalcomulco' },
    { value: 29028, label: 'Jáltipan' },
    { value: 29029, label: 'Jamapa' },
    { value: 29030, label: 'Jesús Carranza' },
    { value: 29031, label: 'Xico' },
    { value: 29032, label: 'Juan Rodríguez Clara' },
    { value: 29033, label: 'Juchique de Ferrer' },
    { value: 29034, label: 'Landero y Coss' },
    { value: 29035, label: 'Lerdo de Tejada' },
    { value: 29036, label: 'Maltrata' },
    { value: 29037, label: 'Manlio Fabio Altamirano' },
    { value: 29038, label: 'Mariano Escobedo' },
    { value: 29039, label: 'Martínez de la Torre' },
    { value: 29040, label: 'Mecatlán' },
    { value: 29041, label: 'Mecayapan' },
    { value: 29042, label: 'Medellín de Bravo' },
    { value: 29043, label: 'Miahuatlán' },
    { value: 29044, label: 'Las Minas' },
    { value: 29045, label: 'Misantla' },
    { value: 29046, label: 'Mixtla de Altamirano' },
    { value: 29047, label: 'Moloacán' },
    { value: 29048, label: 'Naolinco' },
    { value: 29049, label: 'Naranjal' },
    { value: 29050, label: 'Nautla' },
    { value: 29051, label: 'Oluta' },
    { value: 29052, label: 'Omealca' },
    { value: 29053, label: 'Orizaba' },
    { value: 29054, label: 'Otatitlán' },
    { value: 29055, label: 'Oteapan' },
    { value: 29056, label: 'Ozuluama de Mascareñas' },
    { value: 29057, label: 'Pajapan' },
    { value: 29058, label: 'Pánuco' },
    { value: 29059, label: 'Papantla' },
    { value: 29060, label: 'Paso del Macho' },
    { value: 30001, label: 'Paso de Ovejas' },
    { value: 30002, label: 'La Perla' },
    { value: 30003, label: 'Perote' },
    { value: 30004, label: 'Platón Sánchez' },
    { value: 30005, label: 'Playa Vicente' },
    { value: 30006, label: 'Poza Rica de Hidalgo' },
    { value: 30007, label: 'Las Vigas de Ramírez' },
    { value: 30008, label: 'Pueblo Viejo' },
    { value: 30009, label: 'Puente Nacional' },
    { value: 30010, label: 'Rafael Delgado' },
    { value: 30011, label: 'Rafael Lucio' },
    { value: 30012, label: 'Río Blanco' },
    { value: 30013, label: 'Saltabarranca' },
    { value: 30014, label: 'San Andrés Tenejapan' },
    { value: 30015, label: 'San Andrés Tuxtla' },
    { value: 30016, label: 'San Juan Evangelista' },
    { value: 30017, label: 'Santiago Tuxtla' },
    { value: 30018, label: 'Sayula de Alemán' },
    { value: 30019, label: 'Soconusco' },
    { value: 30020, label: 'Sochiapa' },
    { value: 30021, label: 'Soledad Atzompa' },
    { value: 30022, label: 'Soledad de Doblado' },
    { value: 30023, label: 'Soteapan' },
    { value: 30024, label: 'Tamalín' },
    { value: 30025, label: 'Tamiahua' },
    { value: 30026, label: 'Tampico Alto' },
    { value: 30027, label: 'Tancoco' },
    { value: 30028, label: 'Tantima' },
    { value: 30029, label: 'Tantoyuca' },
    { value: 30030, label: 'Tatatila' },
    { value: 30031, label: 'Castillo de Teayo' },
    { value: 30032, label: 'Tecolutla' },
    { value: 30033, label: 'Tehuipango' },
    { value: 30034, label: 'Álamo Temapache' },
    { value: 30035, label: 'Tempoal' },
    { value: 30036, label: 'Tenampa' },
    { value: 30037, label: 'Tenochtitlán' },
    { value: 30038, label: 'Teocelo' },
    { value: 30039, label: 'Tepatlaxco' },
    { value: 30040, label: 'Tepetlán' },
    { value: 30041, label: 'José Azueta' },
    { value: 30042, label: 'Texcatepec' },
    { value: 30043, label: 'Texhuacán' },
    { value: 30044, label: 'Texistepec' },
    { value: 30045, label: 'Tezonapa' },
    { value: 30046, label: 'Tihuatlán' },
    { value: 30047, label: 'Tlacojalpan' },
    { value: 30048, label: 'Tlacolulan' },
    { value: 30049, label: 'Tlacotalpan' },
    { value: 30050, label: 'Tlacotepec de Mejía' },
    { value: 30051, label: 'Tlachichilco' },
    { value: 30052, label: 'Tlalixcoyan' },
    { value: 30053, label: 'Tlalnelhuayocan' },
    { value: 30054, label: 'Tlapacoyan' },
    { value: 30055, label: 'Tlaquilpa' },
    { value: 30056, label: 'Tlilapan' },
    { value: 30057, label: 'Tonayán' },
    { value: 30058, label: 'Totutla' },
    { value: 30059, label: 'Tuxtilla' },
    { value: 30060, label: 'Ursulo Galván' },
    { value: 30061, label: 'Vega de Alatorre' },
    { value: 30062, label: 'Veracruz' },
    { value: 30063, label: 'Villa Aldama' },
    { value: 30064, label: 'Xoxocotla' },
    { value: 30065, label: 'Yanga' },
    { value: 30066, label: 'Yecuatla' },
    { value: 30067, label: 'Zentla' },
    { value: 30068, label: 'Zongolica' },
    { value: 30069, label: 'Zontecomatlán de López y Fuentes' },
    { value: 30070, label: 'Zozocolco de Hidalgo' },
    { value: 30071, label: 'Agua Dulce' },
    { value: 30072, label: 'El Higo' },
    { value: 30073, label: 'Nanchital de Lázaro Cárdenas del Río' },
    { value: 30074, label: 'Tres Valles' },
    { value: 30075, label: 'Carlos A. Carrillo' },
    { value: 30076, label: 'Tatahuicapan de Juárez' },
    { value: 30077, label: 'Uxpanapa' },
    { value: 30078, label: 'San Rafael' },
    { value: 30079, label: 'Santiago Sochiapan' },
    { value: 30080, label: 'Abalá' },
    { value: 30081, label: 'Acanceh' },
    { value: 30082, label: 'Akil' },
    { value: 30083, label: 'Baca' },
    { value: 30084, label: 'Bokobá' },
    { value: 30085, label: 'Buctzotz' },
    { value: 30086, label: 'Cacalchén' },
    { value: 30087, label: 'Calotmul' },
    { value: 30088, label: 'Cansahcab' },
    { value: 30089, label: 'Cantamayec' },
    { value: 30090, label: 'Celestún' },
    { value: 30091, label: 'Cenotillo' },
    { value: 30092, label: 'Conkal' },
    { value: 30093, label: 'Cuncunul' },
    { value: 30094, label: 'Cuzamá' },
    { value: 30095, label: 'Chacsinkín' },
    { value: 30096, label: 'Chankom' },
    { value: 30097, label: 'Chapab' },
    { value: 30098, label: 'Chemax' },
    { value: 30099, label: 'Chicxulub Pueblo' },
    { value: 30100, label: 'Chichimilá' },
    { value: 30101, label: 'Chikindzonot' },
    { value: 30102, label: 'Chocholá' },
    { value: 30103, label: 'Chumayel' },
    { value: 30104, label: 'Dzán' },
    { value: 30105, label: 'Dzemul' },
    { value: 30106, label: 'Dzidzantún' },
    { value: 30107, label: 'Dzilam de Bravo' },
    { value: 30108, label: 'Dzilam González' },
    { value: 30109, label: 'Dzitás' },
    { value: 30110, label: 'Dzoncauich' },
    { value: 30111, label: 'Espita' },
    { value: 30112, label: 'Halachó' },
    { value: 30113, label: 'Hocabá' },
    { value: 30114, label: 'Hoctún' },
    { value: 30115, label: 'Homún' },
    { value: 30116, label: 'Huhí' },
    { value: 30117, label: 'Hunucmá' },
    { value: 30118, label: 'Ixil' },
    { value: 30119, label: 'Izamal' },
    { value: 30120, label: 'Kanasín' },
    { value: 30121, label: 'Kantunil' },
    { value: 30122, label: 'Kaua' },
    { value: 30123, label: 'Kinchil' },
    { value: 30124, label: 'Kopomá' },
    { value: 30125, label: 'Mama' },
    { value: 30126, label: 'Maní' },
    { value: 30127, label: 'Maxcanú' },
    { value: 30128, label: 'Mayapán' },
    { value: 30129, label: 'Mérida' },
    { value: 30130, label: 'Mocochá' },
    { value: 30131, label: 'Motul' },
    { value: 30132, label: 'Muna' },
    { value: 30133, label: 'Muxupip' },
    { value: 30134, label: 'Opichén' },
    { value: 30135, label: 'Oxkutzcab' },
    { value: 30136, label: 'Panabá' },
    { value: 30137, label: 'Peto' },
    { value: 30138, label: 'Quintana Roo' },
    { value: 30139, label: 'Río Lagartos' },
    { value: 30140, label: 'Sacalum' },
    { value: 30141, label: 'Samahil' },
    { value: 30142, label: 'Sanahcat' },
    { value: 30143, label: 'Santa Elena' },
    { value: 30144, label: 'Seyé' },
    { value: 30145, label: 'Sinanché' },
    { value: 30146, label: 'Sotuta' },
    { value: 30147, label: 'Sucilá' },
    { value: 30148, label: 'Sudzal' },
    { value: 30149, label: 'Suma' },
    { value: 30150, label: 'Tahdziú' },
    { value: 30151, label: 'Tahmek' },
    { value: 30152, label: 'Teabo' },
    { value: 30153, label: 'Tecoh' },
    { value: 30154, label: 'Tekal de Venegas' },
    { value: 30155, label: 'Tekantó' },
    { value: 30156, label: 'Tekax' },
    { value: 30157, label: 'Tekit' },
    { value: 30158, label: 'Tekom' },
    { value: 30159, label: 'Telchac Pueblo' },
    { value: 30160, label: 'Telchac Puerto' },
    { value: 30161, label: 'Temax' },
    { value: 30162, label: 'Temozón' },
    { value: 30163, label: 'Tepakán' },
    { value: 30164, label: 'Tetiz' },
    { value: 30165, label: 'Teya' },
    { value: 30166, label: 'Ticul' },
    { value: 30167, label: 'Timucuy' },
    { value: 30168, label: 'Tinum' },
    { value: 30169, label: 'Tixcacalcupul' },
    { value: 30170, label: 'Tixkokob' },
    { value: 30171, label: 'Tixmehuac' },
    { value: 30172, label: 'Tixpéhual' },
    { value: 30173, label: 'Tizimín' },
    { value: 30174, label: 'Tunkás' },
    { value: 30175, label: 'Tzucacab' },
    { value: 30176, label: 'Uayma' },
    { value: 30177, label: 'Ucú' },
    { value: 30178, label: 'Umán' },
    { value: 30179, label: 'Valladolid' },
    { value: 30180, label: 'Xocchel' },
    { value: 30181, label: 'Yaxcabá' },
    { value: 30182, label: 'Yaxkukul' },
    { value: 30183, label: 'Yobaín' },
    { value: 30184, label: 'Apozol' },
    { value: 30185, label: 'Apulco' },
    { value: 30186, label: 'Atolinga' },
    { value: 30187, label: 'Calera' },
    { value: 30188, label: 'Cañitas de Felipe Pescador' },
    { value: 30189, label: 'Concepción del Oro' },
    { value: 30190, label: 'Chalchihuites' },
    { value: 30191, label: 'Fresnillo' },
    { value: 30192, label: 'Trinidad García de la Cadena' },
    { value: 30193, label: 'Genaro Codina' },
    { value: 30194, label: 'General Enrique Estrada' },
    { value: 30195, label: 'General Francisco R. Murguía' },
    { value: 30196, label: 'El Plateado de Joaquín Amaro' },
    { value: 30197, label: 'General Pánfilo Natera' },
    { value: 30198, label: 'Huanusco' },
    { value: 30199, label: 'Jalpa' },
    { value: 30200, label: 'Jerez' },
    { value: 30201, label: 'Jiménez del Teul' },
    { value: 30202, label: 'Juan Aldama' },
    { value: 30203, label: 'Juchipila' },
    { value: 30204, label: 'Luis Moya' },
    { value: 30205, label: 'Mazapil' },
    { value: 30206, label: 'Mezquital del Oro' },
    { value: 30207, label: 'Miguel Auza' },
    { value: 30208, label: 'Momax' },
    { value: 30209, label: 'Monte Escobedo' },
    { value: 30210, label: 'Moyahua de Estrada' },
    { value: 30211, label: 'Nochistlán de Mejía' },
    { value: 30212, label: 'Noria de Ángeles' },
    { value: 30998, label: 'Ojocaliente' },
    { value: 30999, label: 'Pinos' },
    { value: 31001, label: 'Río Grande' },
    { value: 31002, label: 'Sain Alto' },
    { value: 31003, label: 'El Salvador' },
    { value: 31004, label: 'Sombrerete' },
    { value: 31005, label: 'Susticacán' },
    { value: 31006, label: 'Tabasco' },
    { value: 31007, label: 'Tepechitlán' },
    { value: 31008, label: 'Tepetongo' },
    { value: 31009, label: 'Teúl de González Ortega' },
    { value: 31010, label: 'Tlaltenango de Sánchez Román' },
    { value: 31011, label: 'Valparaíso' },
    { value: 31012, label: 'Vetagrande' },
    { value: 31013, label: 'Villa de Cos' },
    { value: 31014, label: 'Villa García' },
    { value: 31015, label: 'Villa González Ortega' },
    { value: 31016, label: 'Villanueva' },
    { value: 31017, label: 'Zacatecas' },
    { value: 31018, label: 'Trancoso' },
    { value: 31019, label: 'Santa María de la Paz' },
    { value: 31020, label: 'Chicxulub Pueblo' },
    { value: 31021, label: 'Chichimilá' },
    { value: 31022, label: 'Chikindzonot' },
    { value: 31023, label: 'Chocholá' },
    { value: 31024, label: 'Chumayel' },
    { value: 31025, label: 'Dzán' },
    { value: 31026, label: 'Dzemul' },
    { value: 31027, label: 'Dzidzantún' },
    { value: 31028, label: 'Dzilam de Bravo' },
    { value: 31029, label: 'Dzilam González' },
    { value: 31030, label: 'Dzitás' },
    { value: 31031, label: 'Dzoncauich' },
    { value: 31032, label: 'Espita' },
    { value: 31033, label: 'Halachó' },
    { value: 31034, label: 'Hocabá' },
    { value: 31035, label: 'Hoctún' },
    { value: 31036, label: 'Homún' },
    { value: 31037, label: 'Huhí' },
    { value: 31038, label: 'Hunucmá' },
    { value: 31039, label: 'Ixil' },
    { value: 31040, label: 'Izamal' },
    { value: 31041, label: 'Kanasín' },
    { value: 31042, label: 'Kantunil' },
    { value: 31043, label: 'Kaua' },
    { value: 31044, label: 'Kinchil' },
    { value: 31045, label: 'Kopomá' },
    { value: 31046, label: 'Mama' },
    { value: 31047, label: 'Maní' },
    { value: 31048, label: 'Maxcanú' },
    { value: 31049, label: 'Mayapán' },
    { value: 31050, label: 'Mérida' },
    { value: 31051, label: 'Mocochá' },
    { value: 31052, label: 'Motul' },
    { value: 31053, label: 'Muna' },
    { value: 31054, label: 'Muxupip' },
    { value: 31055, label: 'Opichén' },
    { value: 31056, label: 'Oxkutzcab' },
    { value: 31057, label: 'Panabá' },
    { value: 31058, label: 'Peto' },
    { value: 31059, label: 'Progreso' },
    { value: 31060, label: 'Quintana Roo' },
    { value: 31061, label: 'Río Lagartos' },
    { value: 31062, label: 'Sacalum' },
    { value: 31063, label: 'Samahil' },
    { value: 31064, label: 'Sanahcat' },
    { value: 31065, label: 'San Felipe' },
    { value: 31066, label: 'Santa Elena' },
    { value: 31067, label: 'Seyé' },
    { value: 31068, label: 'Sinanché' },
    { value: 31069, label: 'Sotuta' },
    { value: 31070, label: 'Sucilá' },
    { value: 31071, label: 'Sudzal' },
    { value: 31072, label: 'Suma' },
    { value: 31073, label: 'Tahdziú' },
    { value: 31074, label: 'Tahmek' },
    { value: 31075, label: 'Teabo' },
    { value: 31076, label: 'Tecoh' },
    { value: 31077, label: 'Tekal de Venegas' },
    { value: 31078, label: 'Tekantó' },
    { value: 31079, label: 'Tekax' },
    { value: 31080, label: 'Tekit' },
    { value: 31081, label: 'Tekom' },
    { value: 31082, label: 'Telchac Pueblo' },
    { value: 31083, label: 'Telchac Puerto' },
    { value: 31084, label: 'Temax' },
    { value: 31085, label: 'Temozón' },
    { value: 31086, label: 'Tepakán' },
    { value: 31087, label: 'Tetiz' },
    { value: 31088, label: 'Teya' },
    { value: 31089, label: 'Ticul' },
    { value: 31090, label: 'Timucuy' },
    { value: 31091, label: 'Tinum' },
    { value: 31092, label: 'Tixcacalcupul' },
    { value: 31093, label: 'Tixkokob' },
    { value: 31094, label: 'Tixmehuac' },
    { value: 31095, label: 'Tixpéhual' },
    { value: 31096, label: 'Tizimín' },
    { value: 31097, label: 'Tunkás' },
    { value: 31098, label: 'Tzucacab' },
    { value: 31099, label: 'Uayma' },
    { value: 31100, label: 'Ucú' },
    { value: 31101, label: 'Umán' },
    { value: 31102, label: 'Valladolid' },
    { value: 31103, label: 'Xocchel' },
    { value: 31104, label: 'Yaxcabá' },
    { value: 31105, label: 'Yaxkukul' },
    { value: 31106, label: 'Yobaín' },
    { value: 32001, label: 'Apozol' },
    { value: 32002, label: 'Apulco' },
    { value: 32003, label: 'Atolinga' },
    { value: 32004, label: 'Benito Juárez' },
    { value: 32005, label: 'Calera' },
    { value: 32006, label: 'Cañitas de Felipe Pescador' },
    { value: 32007, label: 'Concepción del Oro' },
    { value: 32008, label: 'Cuauhtémoc' },
    { value: 32009, label: 'Chalchihuites' },
    { value: 32010, label: 'Fresnillo' },
    { value: 32011, label: 'Trinidad García de la Cadena' },
    { value: 32012, label: 'Genaro Codina' },
    { value: 32013, label: 'General Enrique Estrada' },
    { value: 32014, label: 'General Francisco R. Murguía' },
    { value: 32015, label: 'El Plateado de Joaquín Amaro' },
    { value: 32016, label: 'General Pánfilo Natera' },
    { value: 32017, label: 'Guadalupe' },
    { value: 32018, label: 'Huanusco' },
    { value: 32019, label: 'Jalpa' },
    { value: 32020, label: 'Jerez' },
    { value: 32021, label: 'Jiménez del Teul' },
    { value: 32022, label: 'Juan Aldama' },
    { value: 32023, label: 'Juchipila' },
    { value: 32024, label: 'Loreto' },
    { value: 32025, label: 'Luis Moya' },
    { value: 32026, label: 'Mazapil' },
    { value: 32027, label: 'Melchor Ocampo' },
    { value: 32028, label: 'Mezquital del Oro' },
    { value: 32029, label: 'Miguel Auza' },
    { value: 32030, label: 'Momax' },
    { value: 32031, label: 'Monte Escobedo' },
    { value: 32032, label: 'Morelos' },
    { value: 32033, label: 'Moyahua de Estrada' },
    { value: 32034, label: 'Nochistlán de Mejía' },
    { value: 32035, label: 'Noria de Ángeles' },
    { value: 32036, label: 'Ojocaliente' },
    { value: 32037, label: 'Pánuco' },
    { value: 32038, label: 'Pinos' },
    { value: 32039, label: 'Río Grande' },
    { value: 32040, label: 'Sain Alto' },
    { value: 32041, label: 'El Salvador' },
    { value: 32042, label: 'Sombrerete' },
    { value: 32043, label: 'Susticacán' },
    { value: 32044, label: 'Tabasco' },
    { value: 32045, label: 'Tepechitlán' },
    { value: 32046, label: 'Tepetongo' },
    { value: 32047, label: 'Teúl de González Ortega' },
    { value: 32048, label: 'Tlaltenango de Sánchez Román' },
    { value: 32049, label: 'Valparaíso' },
    { value: 32050, label: 'Vetagrande' },
    { value: 32051, label: 'Villa de Cos' },
    { value: 32052, label: 'Villa García' },
    { value: 32053, label: 'Villa González Ortega' },
    { value: 32054, label: 'Villa Hidalgo' },
    { value: 32055, label: 'Villanueva' },
    { value: 32056, label: 'Zacatecas' },
    { value: 32057, label: 'Trancoso' },
    { value: 32058, label: 'Santa María de la Paz' },
];

var governments = [
    { value: 'MX01', label: 'Aguascalientes' },
    { value: 'MX02', label: 'Baja California' },
    { value: 'MX03', label: 'Baja California Sur' },
    { value: 'MX04', label: 'Campeche' },
    { value: 'MX05', label: 'Coahuila de Zaragoza' },
    { value: 'MX06', label: 'Colima' },
    { value: 'MX07', label: 'Chiapas' },
    { value: 'MX08', label: 'Chihuahua' },
    { value: 'MX09', label: 'Ciudad de México' },
    { value: 'MX10', label: 'Durango' },
    { value: 'MX11', label: 'Guanajuato' },
    { value: 'MX12', label: 'Guerrero' },
    { value: 'MX13', label: 'Hidalgo' },
    { value: 'MX14', label: 'Jalisco' },
    { value: 'MX15', label: 'México' },
    { value: 'MX16', label: 'Michoacán de Ocampo' },
    { value: 'MX17', label: 'Morelos' },
    { value: 'MX18', label: 'Nayarit' },
    { value: 'MX19', label: 'Nuevo León' },
    { value: 'MX20', label: 'Oaxaca' },
    { value: 'MX21', label: 'Puebla' },
    { value: 'MX22', label: 'Querétaro' },
    { value: 'MX23', label: 'Quintana Roo' },
    { value: 'MX24', label: 'San Luis Potosí' },
    { value: 'MX25', label: 'Sinaloa' },
    { value: 'MX26', label: 'Sonora' },
    { value: 'MX27', label: 'Tabasco' },
    { value: 'MX28', label: 'Tamaulipas' },
    { value: 'MX29', label: 'Tlaxcala' },
    { value: 'MX30', label: 'Veracruz de Ignacio de la Llave' },
    { value: 'MX31', label: 'Yucatán' },
    { value: 'MX32', label: 'Zacatecas' },
];

export { municipalities, governments };
