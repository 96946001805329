import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {create} from '../../actions/groupActions'

export const CreateModal = ({ ...rest }) => {
  const [name, setName] = useState('');

  const [click, setClick] = useState(false);

  const dispatch = useDispatch();
  const handleSave = (e) => {
    e.preventDefault();
    dispatch(create(name))
    setName('');
    setClick(false);
  };

  return (
    <>
      <div className={click ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Crear nuevo estudio</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setClick(false)}
            />
          </header>
          <section className="modal-card-body">
            <form onSubmit={handleSave}>
              <div className="field">
                <label className="label">Nombre del estudio</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Proyecta B"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </div>
              </div>
              <div className="control">
                <button
                  className="button is-success"
                  type="submit"
                  value="Submit"
                >
                  Guardar
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
      <button className="button is-success" onClick={() => setClick(true)}>
        Crear nuevo estudio
      </button>
    </>
  );
};
