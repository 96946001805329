import {
  GET_HEATMAP,
  CATEGORIES_ROUTE,
  CLEAR_GEOMETRY,
  COMMODITIES_ROUTE,
  DAYSWEEK,
  ERROR,
  GET_ALL,
  GET_BY_PAGE,
  GET_PAGINATION,
  LOAD_CATEGORIES,
  LOAD_COMMODITIES,
  LOAD_DAYSWEEK,
  LOAD_TIMETABLE,
  LOAD_VEHICLES,
  LOADING,
  SEARCHING,
  SHOW,
  SET_FILTER_CREATED_AT,
  SET_FILTER_DISTANCE,
  SET_FILTER_DURATION,
  SET_FILTER_NAME,
  SET_FILTER_RISK,
  TIMETABLE_ROUTE,
  UPDATE,
  IS_ROUTE_CREATED,
  UPDATE_GEOMETRY_ROUTE,
  VEHICLES_ROUTE,
  DASHBOARD,
  RISK_CHART,
  RISK_INDICATOR,
} from '../types/routeTypes';

const INITIAL_STATE = {
  //Dashboard
  dashGeometries: { routes: [], municipalities: [] },
  //list
  page: 0,
  loading: false,
  isCreated: false,
  searching: false,
  error: '',
  routes: [],
  filterRisk: '',
  filterDistance: '',
  filterDuration: '',
  filterName: '',
  filterCreateAt: '',
  //show
  before: null,
  after: null,
  route: {},
  geometry: null,
  riskCharts: [],
  riskIndicator: {},
  loadTimetable: true,
  timetable: [],
  loadCommodities: true,
  commodities: [],
  loadDaysweek: true,
  daysweek: [],
  loadVehicles: true,
  vehicles: [],
  loadCategories: true,
  categories: [],
  //create - get
  municipalities: [],
  incidents: [],
};

export const routeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW:
      return {
        ...state,
        route: action.route,
        municipalities: action.municipalities,
        incidents: action.incidents,
        loading: false,
      };
    case GET_HEATMAP:
      return {
        ...state,
        municipalities: action.municipalities,
        loading: action.loading,
      };
    case RISK_CHART:
      return {
        ...state,
        riskCharts: action.data,
      };
    case RISK_INDICATOR:
      return {
        ...state,
        riskIndicator: action.data,
      };
    case GET_ALL:
      return {
        ...state,
        routes: action.routes,
        loading: action.loading,
      };
    case GET_PAGINATION:
      return {
        ...state,
        before: action.before,
        after: action.after,
      };
    case SET_FILTER_RISK:
      return { ...state, filterRisk: action.filterRisk };
    case SET_FILTER_DISTANCE:
      return { ...state, filterDistance: action.filterDistance };
    case SET_FILTER_DURATION:
      return { ...state, filterDuration: action.filterDuration };
    case SET_FILTER_NAME:
      return { ...state, filterName: action.filterName };
    case SET_FILTER_CREATED_AT:
      return { ...state, filterCreateAt: action.filterCreateAt };
    case GET_BY_PAGE:
      return { ...state, page: action.page };
    case ERROR:
      return { ...state, error: action.error };
    case LOADING:
      return { ...state, loading: !state.loading };
    case SEARCHING:
      return { ...state, searching: action.searching };
    case IS_ROUTE_CREATED:
      return { ...state, isCreated: action.isCreated };
    case UPDATE:
      return { ...state, routes: action.routes, loading: false };
    case CLEAR_GEOMETRY:
      return {
        ...state,
        geometry: null,
        municipalities: null,
      };
    case UPDATE_GEOMETRY_ROUTE:
      return {
        ...state,
        geometry: action.geometry,
      };
    case TIMETABLE_ROUTE:
      return {
        ...state,
        timetable: action.timetable,
        loadTimetable: false,
      };
    case LOAD_TIMETABLE:
      return {
        ...state,
        loadTimetable: action.loadTimetable,
      };
    case COMMODITIES_ROUTE:
      return {
        ...state,
        commodities: action.commodities,
        loadCommodities: false,
      };
    case LOAD_COMMODITIES:
      return {
        ...state,
        loadCommodities: action.loadCommodities,
      };
    case DAYSWEEK:
      return {
        ...state,
        daysweek: action.daysweek,
        loadDaysweek: false,
      };
    case LOAD_DAYSWEEK:
      return {
        ...state,
        loadDaysweek: action.loadDaysweek,
      };
    case VEHICLES_ROUTE:
      return {
        ...state,
        vehicles: action.vehicles,
        loadVehicles: false,
      };
    case LOAD_VEHICLES:
      return {
        ...state,
        loadVehicles: action.loadVehicles,
      };
    case CATEGORIES_ROUTE:
      return {
        ...state,
        categories: action.categories,
        loadCategories: false,
      };
    case LOAD_CATEGORIES:
      return {
        ...state,
        loadCategories: action.loadCategories,
      };
    case DASHBOARD:
      return {
        ...state,
        dashGeometries: action.dashGeometries,
      };
    default:
      return state;
  }
};
