import React, {useState} from 'react';
import Switch from 'react-switch';

export const ButtonSelectAll = ({children, ...rest }) => {
  const [click, setClick] = useState(true)

  if(click){
    return <>todos:<Switch onChange={setClick} checked={click} /></>;
  }else{
    return <>{children}</>;
  }
};
