import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import './IncidentCsv.css';

import { createFromCSV } from '../../actions/incidentActions';

export const IncidentCsv = () => {
  const [file, setFile] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const [typeFile, setTypeFile] = useState('otra');

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.incidentReducer.loading);
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', typeFile);
    dispatch(createFromCSV(formData));
    if (loading) {
      setFile(null);
      setTypeFile('otra');
    }
  };

  const handleSelect = (e) => {
    setTypeFile(e.target.value);
  };

  return (
    <div>
      <div className="container mt-2">
        <div className="columns is-vcentered contenido-vertical">
          <div className="column">
            <div className="field has-addons has-addons-centered">
              <div className="select">
                de que fuente es el archivo \?
                <select
                  name="type-file"
                  value={typeFile}
                  onChange={handleSelect}
                >
                  <option value="anerpv">anerpv</option>
                  <option value="anerpv_v2">anerpv version 2</option>
                  <option value="sensi">sensi guard</option>
                  <option value="otra">otra</option>
                </select>
              </div>
            </div>
            <div className="field">
              <div className="file is-centered is-boxed has-name">
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="reporte"
                    onChange={handleFileChange}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <FontAwesome name="fas fa-upload" />
                    </span>
                    <span className="file-label">Seleccionar archivo</span>
                  </span>
                  <span className="file-name">{file && file.name}</span>
                </label>
              </div>
            </div>

            {file && (
              <div className="buttons has-addons is-centered">
                <button
                  className="button is-success is-centered"
                  onClick={handleUpload}
                >
                  Subir archivo
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
