export const ERROR = 'route_error';
export const LOADING = 'route_loading';
export const SEARCHING = 'route_searching';
export const GET_ALL = 'route_get_all';
export const SET_FILTER_RISK = 'route_set_filter_risk';
export const SET_FILTER_DISTANCE = 'route_set_filter_distance';
export const SET_FILTER_DURATION = 'route_set_filter_duration';
export const SET_FILTER_NAME = 'route_set_filter_name';
export const SET_FILTER_CREATED_AT = 'route_set_filter_created_at';
export const GET_BY_PAGE = 'route_get_by_page';
export const DELETE = 'route_delete';
export const UPDATE = 'route_update';
export const IS_ROUTE_CREATED = 'route_is_created';
export const SHOW = 'route_select';
export const CLEAR_GEOMETRY = 'route_clear_geometry';
export const UPDATE_GEOMETRY_ROUTE = 'route_update_geomtry_route';
export const GET_PAGINATION = 'route_get_pagination';
export const LOAD_TIMETABLE = 'route_load_timetable';
export const TIMETABLE_ROUTE = 'route_timetable_route';
export const LOAD_COMMODITIES = 'route_load_commodities';
export const COMMODITIES_ROUTE = 'route_commodities_route';
export const LOAD_DAYSWEEK = 'route_load_days_week';
export const DAYSWEEK = 'route_days_week_route';
export const LOAD_VEHICLES = 'route_load_vehicles';
export const VEHICLES_ROUTE = 'route_vehicles_route';
export const LOAD_CATEGORIES = 'route_load_categories';
export const CATEGORIES_ROUTE = 'route_categories_route';
export const DASHBOARD = 'route_Dashboar_group';
export const GET_HEATMAP = 'route_heatmap_incidentes_per_100k';
export const RISK_CHART = 'route_risk_chart';
export const RISK_INDICATOR = 'route_risk_indicator';
