import apiClient from '../utils/apiClient';

import {
  COMMODITIES_ROUTE,
  GET_ALL,
  GET_BY_PAGE,
  GET_PAGINATION,
  LOAD_COMMODITIES,
  LOAD_TIMETABLE,
  LOADING,
  SEARCHING,
  SHOW,
  SET_FILTER_CREATED_AT,
  SET_FILTER_DISTANCE,
  SET_FILTER_DURATION,
  SET_FILTER_NAME,
  SET_FILTER_RISK,
  TIMETABLE_ROUTE,
  UPDATE,
  IS_ROUTE_CREATED,
  LOAD_DAYSWEEK,
  DAYSWEEK,
  LOAD_VEHICLES,
  VEHICLES_ROUTE,
  LOAD_CATEGORIES,
  CATEGORIES_ROUTE,
  DASHBOARD,
  GET_HEATMAP,
  RISK_CHART,
  RISK_INDICATOR,
} from '../types/routeTypes';

import {
  NOTIFY_ERROR,
  NOTIFY_SUCCESS,
  NOTIFY_WARNING,
} from '../types/notifyTypes';

const API = process.env.REACT_APP_API_URL;
const API_URL = `${API}route/`;

export const getRiskChart = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await apiClient.get(
      `${API_URL}${id}/get_incidents_chart_v2/`
    );
    const new_data = response.data.map((chart) =>
      chart.map((month) => {
        return {
          page: `${month.month}-${month.year}`,
          riesgo: month.risk,
          crecimiento: month.grown,
        };
      })
    );
    dispatch({ type: RISK_CHART, data: new_data });
    const risk = new_data.map((chart) => {
      return chart[chart.length - 1].riesgo;
    });
    dispatch({ type: RISK_INDICATOR, data: risk });
  } catch (e) {
    dispatch({
      type: NOTIFY_ERROR,
      message: 'no se puedo obtener datos de la ruta',
    });
  }
};

export const getHeatmap = (start, end, alternative) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await apiClient.get(
      `${API_URL}get_municipalities_heatmap/?start=[${start}]&end=[${end}]&alternative=${alternative}`
    );
    dispatch({
      type: GET_HEATMAP,
      loading: false,
      municipalities: response.data.municipalities,
    });
  } catch (e) {
    dispatch({
      type: NOTIFY_ERROR,
      message: 'no se puedo obtener datos de la ruta',
    });
  }
};

export const getByGroup = (groupId) => async (dispatch, getState) => {
  const {
    routeReducer: { filterRisk, filterDistance, filterDuration },
  } = getState();

  let endLine = '';
  if (filterRisk.length > 0) {
    endLine = `${endLine}&ordering=${filterRisk}`;
  }
  if (filterDistance.length > 0) {
    endLine = `${endLine}&ordering=${filterDistance}`;
  }
  if (filterDuration.length > 0) {
    endLine = `${endLine}&ordering=${filterDuration}`;
  }

  dispatch({ type: LOADING });
  try {
    const response = await apiClient.get(
      `${API_URL}?group=${groupId}${endLine}`
    );

    const new_routes = response.data.results.map((route) => {
      route.isChecked = false;
      return route;
    });

    dispatch({ type: GET_ALL, routes: new_routes, loading: false });
  } catch (e) {
    dispatch({
      type: NOTIFY_ERROR,
      message: 'no se pudo obtener mas rutas',
    });
  }
};

export const getByPage = (groupId) => async (dispatch, getState) => {
  const {
    routeReducer: { page, routes, filterRisk, filterDistance, filterDuration },
  } = getState();

  let endLine = '';
  if (filterRisk.length > 0) {
    endLine = `${endLine}&ordering=${filterRisk}`;
  }
  if (filterDistance.length > 0) {
    endLine = `${endLine}&ordering=${filterDistance}`;
  }
  if (filterDuration.length > 0) {
    endLine = `${endLine}&ordering=${filterDuration}`;
  }

  dispatch({ type: LOADING });
  try {
    const limit = 20;
    const offset = limit * page;
    const response = await apiClient.get(
      // `${API_URL}?limit=${limit}&offset=${offset}${endLine}`,
      `${API_URL}?limit=${limit}&offset=${offset}&group=${groupId}${endLine}`
    );

    const raw_rotues = [...routes, ...response.data.results];

    const new_routes = raw_rotues.map((route) => {
      route.isChecked = false;
      return route;
    });

    dispatch({ type: GET_ALL, routes: new_routes, loading: false });
    dispatch({ type: GET_BY_PAGE, page: page + 1 });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      message: 'no se pudo obtener mas rutas',
    });
  }
};

export const getGeometriesByGroup = (groupId) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await apiClient.get(
      `${API_URL}get_geometries_by_group/?group=${groupId}`
    );
    dispatch({ type: DASHBOARD, dashGeometries: response.data });
  } catch (e) {
    dispatch({
      type: NOTIFY_ERROR,
      message: 'no se puedo obtener datos de la ruta',
    });
  }
};

export const getByDate = (starDate, endDate) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await apiClient.get(
      `${API_URL}get_by_category/?start_date=${starDate}&end_date=${endDate}`
    );
    const routes = response.data;
    dispatch({ type: UPDATE, routes });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      message: 'Error al cargar rutas',
    });
  }
};

export const deleteRoute = () => async (dispatch, getState) => {
  const {
    routeReducer: { routes },
  } = getState();

  let responses = [];
  for (const route of routes) {
    if (route.isChecked) {
      try {
        const response = await apiClient.delete(`${API_URL}${route.pk}/`);

        if (response.status === 204) {
          responses.push(route.pk);
        }
      } catch (error) {
        dispatch({
          type: NOTIFY_ERROR,
          message: 'Error al borrar ruta',
        });
      }
    }
  }
  const newRoutes = routes
    .map((route) => {
      if (responses.indexOf(route.pk) >= 0) {
        return null;
      } else {
        return route;
      }
    })
    .filter(Boolean);

  dispatch({ type: UPDATE, routes: newRoutes });
};

export const createRoute = (route) => async (dispatch, getState) => {
  const {
    routeReducer: { routes },
  } = getState();

  dispatch({
    type: NOTIFY_WARNING,
    id: 'createRoute',
    message: 'Procesando datos esto puede tardar unos minutos',
  });
  try {
    const response = await apiClient.post(API_URL, route);
    const newRoutes = [response.data, ...routes];
    dispatch({ type: UPDATE, routes: newRoutes });
    // esta doble para que haga la redireccion solamente una vez
    dispatch({ type: IS_ROUTE_CREATED, isCreated: true });
    dispatch({ type: IS_ROUTE_CREATED, isCreated: false });
    dispatch({
      type: NOTIFY_SUCCESS,
      id: 'createRoute',
      message: 'Ruta creada',
    });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      id: 'createRoute',
      message: 'Error al cargar datos',
    });
  }
};

export const updateRoutes = (routes) => (dispatch) => {
  dispatch({ type: UPDATE, routes });
};

export const SearchRoute = (name) => async (dispatch) => {
  if (name) {
    dispatch({ type: SEARCHING, searching: true });
  } else {
    dispatch({ type: SEARCHING, searching: false });
  }
  dispatch({ type: LOADING });
  try {
    const response = await apiClient.get(`${API_URL}?search=${name}`);
    const routes = response.data.results;
    dispatch({ type: UPDATE, routes });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      message: 'Error al buscar ruta',
    });
  }
};

export const sortRoutes = (name, value, groupId) => async (
  dispatch,
  getState
) => {
  const {
    routeReducer: {
      filterRisk,
      filterDistance,
      filterDuration,
      filterName,
      filterCreateAt,
    },
  } = getState();

  if (name === 'risk') dispatch({ type: SET_FILTER_RISK, filterRisk: value });
  if (name === 'distance')
    dispatch({ type: SET_FILTER_DISTANCE, filterDistance: value });
  if (name === 'duration')
    dispatch({ type: SET_FILTER_DURATION, filterDuration: value });
  if (name === 'name') dispatch({ type: SET_FILTER_NAME, filterName: value });
  if (name === 'created_at')
    dispatch({ type: SET_FILTER_CREATED_AT, filterCreateAt: value });

  let endLine = '';
  if (filterRisk.length > 0) {
    endLine = `${endLine}&ordering=${filterRisk}`;
  }
  if (filterDistance.length > 0) {
    endLine = `${endLine}&ordering=${filterDistance}`;
  }
  if (filterDuration.length > 0) {
    endLine = `${endLine}&ordering=${filterDuration}`;
  }
  if (filterName.length > 0) {
    endLine = `${endLine}&ordering=${filterName}`;
  }
  if (filterCreateAt.length > 0) {
    endLine = `${endLine}&ordering=${filterCreateAt}`;
  }
  endLine = `${endLine}&ordering=${value}`;

  try {
    const limit = 20;
    const offset = 0;
    const response = await apiClient.get(
      `${API_URL}?limit=${limit}&offset=${offset}&group=${groupId}${endLine}`
    );

    const new_routes = response.data.results.map((route) => {
      route.isChecked = false;
      return route;
    });

    dispatch({ type: GET_ALL, routes: new_routes, loading: false });
    dispatch({ type: GET_BY_PAGE, page: 1 });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      message: 'no se pudo obtener mas rutas',
    });
  }
};

// TODO: cambiar a axios para agregar los tokens
export const RoutesFromCsv = (formData) => async (dispatch, getState) => {
  dispatch({
    type: NOTIFY_WARNING,
    id: 'routesfromCSV',
    message: 'Procesando datos: esto puede demorar unos minutos',
  });
  const token = apiClient.defaults.headers['Authorization'];
  let response = await fetch(`${API_URL}import_routes_csv/`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: token,
    },
  });

  let data = await response.json();
  let status = response.status;

  if (status === 200) {
    let newRoutes = [];
    for (const route of data) {
      try {
        const response = await apiClient.get(route['Location']);
        newRoutes = [...newRoutes, response.data];
      } catch (error) {
        dispatch({
          type: NOTIFY_ERROR,
          id: 'routesfromCSV',
          message: 'Error en el archivo',
        });
      }
    }

    const {
      routeReducer: { routes },
    } = getState();

    newRoutes = [...newRoutes, ...routes];

    dispatch({ type: UPDATE, routes: newRoutes });
    dispatch({
      type: NOTIFY_SUCCESS,
      id: 'routesfromCSV',
      message: 'Rutas cargadas',
    });
  }
};

export const getById = (pk) => async (dispatch) => {
  dispatch({ type: LOADING });

  let route = {};

  try {
    const response = await apiClient.get(`${API_URL}${pk}`);
    route = response.data;
    dispatch({
      type: SHOW,
      route: response.data,
      municipalities: route.municipalities,
      incidents: route.risk,
    });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      message: 'no se pudo obtener los datos intente mas tarde',
    });
  }

  let existBefore = null;
  let existAfter = null;

  const re = /https?:\/\/[\w:\-.\d]+\/api\/group\/(\d+)\//.exec(route.group);
  if (re) {
    const groupId = re[1];
    try {
      const response = await apiClient.get(`${API_URL}?group=${groupId}`);
      const routes = response.data.results.sort((a, b) => a.pk - b.pk);
      const index = routes.map((x) => x.pk).indexOf(route.pk);
      const total = response.data.count - 1;
      if (index < total) {
        existAfter = routes[index + 1].pk;
      }
      if (index - 1 >= 0) {
        existBefore = routes[index - 1].pk;
      }
      dispatch({ type: GET_ALL, routes, loading: false });
    } catch (e) {
      dispatch({
        type: NOTIFY_ERROR,
        message: 'no se pudo botener el paginador intentelo mas tarde',
      });
    }
  }

  dispatch({
    type: GET_PAGINATION,
    before: existBefore,
    after: existAfter,
  });
};

export const getTimetable = (id) => async (dispatch) => {
  dispatch({
    type: LOAD_TIMETABLE,
    loadTimetable: true,
  });
  try {
    const response = await apiClient.get(`${API_URL}${id}/get_timetable/`);

    dispatch({
      type: TIMETABLE_ROUTE,
      timetable: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCommodities = (id) => async (dispatch) => {
  dispatch({
    type: LOAD_COMMODITIES,
    loadCommodities: true,
  });
  try {
    const response = await apiClient.get(`${API_URL}${id}/get_commodities/`);

    dispatch({
      type: COMMODITIES_ROUTE,
      commodities: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getDaysWeek = (id) => async (dispatch) => {
  dispatch({
    type: LOAD_DAYSWEEK,
    loadDaysweek: true,
  });
  try {
    const response = await apiClient.get(`${API_URL}${id}/get_timetable_days/`);
    dispatch({
      type: DAYSWEEK,
      daysweek: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getVehicles = (id) => async (dispatch) => {
  dispatch({
    type: LOAD_VEHICLES,
    loadVehicles: true,
  });
  try {
    const response = await apiClient.get(`${API_URL}${id}/get_vehicles/`);

    dispatch({
      type: VEHICLES_ROUTE,
      vehicles: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCategories = (id) => async (dispatch) => {
  dispatch({
    type: LOAD_CATEGORIES,
    loadCategories: true,
  });
  try {
    const response = await apiClient.get(`${API_URL}${id}/get_categories/`);

    dispatch({
      type: CATEGORIES_ROUTE,
      categories: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};
