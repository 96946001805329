import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../General/Spinner';
import { ConfirDelete } from '../General/ConfirDelete';
import { CreateModal } from './Create';
import { getAll, update, borrar } from '../../actions/groupActions';

export const Estudio = ({ ...rest }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  const groups = useSelector((state) => state.groupReducer.groups);

  const handleCheckboxes = (pk) => {
    const newGroups = groups.map((group) => {
      if (group.pk === pk) {
        group.isChecked = !group.isChecked;
        return group;
      } else {
        return group;
      }
    });

    dispatch(update(newGroups));
  };

  const handleDelete = () => {
    dispatch(borrar());
  };

  const loading = useSelector((state) => state.groupReducer.loading);
  const defaultGroups = useSelector(
    (state) => state.groupReducer.defaultGroups
  );
  if (loading) {
    return <Spinner />;
  }
  if (groups.length === 1) {
    return <Redirect to={`/estudio/${groups[0].pk}/routes/`} />;
  }
  return (
    <>
      <div className="container">
        <div className="columns margin-top">
          <div className="column">
            <div className="buttons">
              <CreateModal />
              <ConfirDelete handleClick={handleDelete} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h3 className="title is-3 has-text-centered">Estudios</h3>
        <hr />
        <div className="columns">
          {groups &&
            groups.map((group) => (
              <div key={group.pk} className="column">
                <div className="box">
                  <input type="checkbox" onChange={()=> handleCheckboxes(group.pk)} checked={group.isChecked} />
                  <Link to={`/estudio/${group.pk}/routes/`} >
                    <ButtonEstudio>
                      <h5 className="title is-5 has-text-centered">
                        {group.name} <span className="tag is-danger">5</span>
                      </h5>
                      <div className="content">
                        <ul>
                          <li>Prueba</li>
                          <li>CDMX-Puebla</li>
                          <li>CDMX-Monterrey</li>
                        </ul>
                      </div>
                    </ButtonEstudio>
                  </Link>
                </div>
              </div>
            ))}
          {defaultGroups &&
            defaultGroups.map((group) => (
              <Link to={`/estudio/${group.pk}/routes/`} key={group.pk}>
                <div className="column">
                  <div className="box">{group.name}</div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </>
  );
};

const ButtonEstudio = styled.div`
  color: #111;
`;
