import { applyMiddleware, combineReducers, createStore } from 'redux';
import authReducer from './authReducer';
import { routeReducer } from './routeReducer';
import { notifyReducer } from './notifyReducer';
import { categoryReducer } from './categoryReducer';
import { incidentReducer } from './incidentReducer';
import { municipalityReducer } from './municipalityReducer';
import { groupReducer } from './groupReducer.js';
import { estudiosReducer } from './estudiosReducer';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const authPersistConfig = {
  key: 'auth',
  storage,
};

const rootReducer = combineReducers({
  authReducer: persistReducer(authPersistConfig, authReducer),
  routeReducer,
  notifyReducer,
  categoryReducer,
  incidentReducer,
  municipalityReducer,
  groupReducer,
  estudiosReducer,
});

export const generateStore = () =>
  createStore(
    rootReducer, // todos los reducers
    {}, // estado inicial
    composeWithDevTools(applyMiddleware(reduxThunk))
  );
