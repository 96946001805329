import React from 'react';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
} from 'recharts';

export const Chart = ({ timetable, ...rest }) => {
  return (
    <LineChart
      width={450}
      height={300}
      data={timetable}
      className="center-chart"
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="day" />
      <YAxis type="number" domain={['dataMin', 'dataMax']} unit="%" />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="madrugada" stroke="#80b1d3" unit="%"/>
      <Line type="monotone" dataKey="matutino" stroke="#80b1d3" unit="%"/>
      <Line type="monotone" dataKey="vespertino" stroke="#bebada" unit="%"/>
      <Line type="monotone" dataKey="nocturno" stroke="#fb8072" unit="%"/>
    </LineChart>
  );
};
