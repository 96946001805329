import { Link } from 'react-router-dom';
import { DataTime } from '../General/DataTime';
import { Chart } from './Chart';
import { v4 } from 'uuid';

import './Route.css';

export const Route = ({ groupId, route, handleCheckboxes, ...rest }) => {
  const fecha = new Date(route.created_at);
  return (
    <tr>
      <td className="table-header">
        <input
          type="checkbox"
          onChange={() => handleCheckboxes(route.pk)}
          checked={route.isChecked}
        />
      </td>
      <td>
        <Link
          to={`/estudio/${groupId}/routes/${route.pk}/`}
          className="table-header link-to"
        >
          {route.name}
        </Link>
      </td>
      <td>
        {fecha.getDate()}/{fecha.getMonth()}/{fecha.getFullYear()}
      </td>
      <td>
        <span>
          Origen: <b>{route.start_address}</b>
        </span>
        <span className="tag">latitud: {route.start[0]}</span>
        <span> </span>
        <span className="tag">longitud: {route.start[1]}</span>
        <br />
        <span>
          Destino: <b>{route.end_address}</b>
        </span>
        <span className="tag">latitud: {route.end[0]}</span>
        <span> </span>
        <span className="tag">longitud: {route.end[1]}</span>
      </td>
      <td>
        <div className="tags is-centered">
          {route.riesgo &&
            route.riesgo.map((r, i) => (
              <Chart riesgos={route.riesgo[i]} key={v4()} />
            ))}
          {route.distance &&
            route.distance.map((d, i) => (
              <span className="tag is-light" key={v4()}>
                Distancia {i}: {d}
              </span>
            ))}
          {route.duration &&
            route.duration.map((d, i) => (
              <DataTime duration={d} size="small" i={i} key={v4()} />
            ))}
        </div>
      </td>
    </tr>
  );
};
