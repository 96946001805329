import React, { Component } from 'react';

import IncidentForm from './IncidentForm';
import { Map } from '../Map';

import '../sidebar&map.css';
import IncidentService from '../../services/IncidentService';
import { Info } from '../General/Info';

const incidentService = new IncidentService();
const API = process.env.REACT_APP_API_URL;

export default class IncidentCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicle: '',
      category: '',
      commodity: '',
      time: '',
      description: '',
      lngLat: '',
      markers: null,
      code_municipality: null,
      code_government: null,
      errors: {
        vehicle_id: null,
        category: null,
        commodity_id: null,
        time: null,
        description: null,
        lngLat: null,
      },
      load: false,
      finished: false,
    };
  }

  handleCancel = () => {
    this.setState({
      vehicle: '',
      category: '',
      commodity: '',
      time: '',
      location: '',
      description: '',
      lngLat: '',
      markers: null,
      code_municipality: null,
      code_government: null,
      errors: {
        vehicle_id: null,
        category: null,
        commodity_id: null,
        time: null,
        description: null,
        lngLat: null,
      },
    });
  };

  handleSave = async () => {
    const incident = {
      vehicle_id: `${API}vehicle/${this.state.vehicle}/`,
      //
      lat: this.state.lngLat[0],
      lon: this.state.lngLat[1],
      code_government: this.state.code_government,
      name_government: null,
      code_municipality: this.state.code_municipality,
      name_municipality: null,
      //
      time: this.state.time,
      day: null,
      month: null,
      year: null,
      timetable: null,
      //
      category: `${API}category/${this.state.category}/`,
      description: this.state.description,
      commodity_id: `${API}commodity/${this.state.commodity}/`,
      //
    };

    this.setState({ load: true });

    const response = await incidentService.create(incident);
    if (response.status === 201) {
      this.handleCancel();
      this.setState({
        load: false,
        finished: true,
      });
    }
    if (response.status === 400) {
      this.setState((state) => {
        let lngLat = state.errors.lngLat;
        if (state.lngLat === '') {
          lngLat = true;
        }

        return {
          errors: {
            ...response.data,
            lngLat,
          },
        };
      });
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'lngLat') {
      const lngLat = value.split(',');
      const lng = parseFloat(lngLat[1]);
      const lat = parseFloat(lngLat[0]);
      let marker = {
        name: 'incidente',
        latitude: lat,
        longitude: lng,
      };

      if (!lng || !lat || lat > 90 || lat < -90 || lng > 180 || lng < -180) {
        marker = null;
        this.setState((state) => {
          return {
            errors: {
              ...state.errors,
              lngLat: true,
            },
          };
        });
      } else {
        this.setState((state) => {
          return {
            errors: {
              ...state.errors,
              lngLat: null,
            },
          };
        });
      }

      this.setState((state) => {
        let markers = [marker];

        if (marker === null) {
          markers = null;
        }

        return {
          lngLat,
          markers,
        };
      });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleClickMap = (e) => {
    const longitude = e.lngLat[0];
    const latitude = e.lngLat[1];

    this.setState((state) => {
      return {
        markers: [
          {
            name: 'incidente',
            latitude,
            longitude,
          },
        ],
        lngLat: [latitude, longitude],
      };
    });
  };

  render() {
    const {
      lngLat,
      markers,
      vehicle,
      category,
      commodity,
      time,
      description,
      errors,
      load,
      finished,
    } = this.state;

    return (
      <div>
        <div className="sidebar">
          {load && (
            <Info type="notification is-warning" message={load}>
              <strong>Cargando datos:</strong> esto puedo tardar unos minutos
            </Info>
          )}
          {finished && (
            <Info type="notification is-success" message={finished}>
              <strong>Datos Guardados</strong>
            </Info>
          )}
          <IncidentForm
            vehicle={vehicle}
            category={category}
            commodity={commodity}
            time={time}
            description={description}
            lngLat={lngLat}
            onChange={this.handleChange}
            handleCancel={this.handleCancel}
            handleSave={this.handleSave}
            errors={errors}
          />
        </div>

        <div className="map">
          <Map onClickMap={this.handleClickMap} markers={markers} />
        </div>
      </div>
    );
  }
}
