import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Map } from '../../Map';
import { getGeometriesByGroup } from '../../../actions/routeActions';

import './styles.css';

const data_month = [
  { mes: 'enero', robos: 78 },
  { mes: 'febrero', robos: 65 },
  { mes: 'marzo', robos: 56 },
  { mes: 'abril', robos: 56 },
  { mes: 'mayo', robos: 34 },
  { mes: 'junio', robos: 26 },
  { mes: 'julio', robos: 45 },
  { mes: 'agosto', robos: 23 },
  { mes: 'septiembre', robos: 45 },
  { mes: 'octubre', robos: 34 },
  { mes: 'noviembre', robos: 34 },
  { mes: 'diciembre', robos: 67 },
];

const data_vehicle = [
  { name: 'Alimentos', robos: 78 },
  { name: 'Bebidas', robos: 23 },
  { name: 'Materias primas', robos: 78 },
  { name: 'vehiculos', robos: 56 },
];

const data_commodity = [
  { name: 'ligeros', robos: 78 },
  { name: 'Buses', robos: 23 },
  { name: 'Tractocamion', robos: 78 },
  { name: 'Motos', robos: 56 },
];

export const Dashboard = ({ ...rest }) => {
  const { groupId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGeometriesByGroup(groupId));
  }, [dispatch, groupId]);

  const dashGeometries = useSelector(
    (state) => state.routeReducer.dashGeometries
  );
  console.log(dashGeometries);

  return (
    <div className="container is-fluid">
      <div className="columns">
        <div className="column is-6">
          <Map routes={dashGeometries['routes']} zones={dashGeometries['municipalities']}/>
        </div>
        <div className="column is-6">
          <div className="columns">
            <div className="column">
              <h3 className="title is-3 has-text-centered">
                Robos por tipo de carga
              </h3>
              <BarChart
                width={500}
                height={200}
                data={data_commodity}
                className="center-chart"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis dataKey="robos" />
                <Tooltip />
                <Legend />
                <Bar dataKey="robos" fill="#82ca9d" />
              </BarChart>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <h3 className="title is-3 has-text-centered">Robos mensuales</h3>
              <LineChart
                width={500}
                height={200}
                data={data_month}
                className="center-chart"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="mes" />
                <YAxis dataKey="robos" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="robos" stroke="#8884d8" />
              </LineChart>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <h3 className="title is-3 has-text-centered">
                Robos por tipo de vehiculo
              </h3>
              <BarChart
                width={500}
                height={200}
                data={data_vehicle}
                className="center-chart"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis dataKey="robos" />
                <Tooltip />
                <Legend />
                <Bar dataKey="robos" fill="#8884d8" />
              </BarChart>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
