import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from './Table';
import { Import } from './Import';

import {
  deleteRoute,
  SearchRoute,
  getByGroup,
  sortRoutes,
  updateRoutes,
} from '../../../../actions/routeActions';

import './RoutesList.css';

const Routes = ({ ...rest }) => {
  const dispatch = useDispatch();
  // const loader = useRef(null);
  const { groupId } = useParams();

  useEffect(() => {
    dispatch(getByGroup(groupId));
  }, [dispatch, groupId]);

  // useEffect(() => {
  //   if (window.pageYOffset) {
  //     const handleObserver = (entities) => {
  //       const target = entities[0];
  //       if (target.isIntersecting) {
  //         dispatch(getByPage(groupId));
  //       }
  //     };

  //     let options = {
  //       root: null,
  //       rootMargin: '20px',
  //       threshold: 1.0,
  //     };
  //     // initialize IntersectionObserver
  //     // and attaching to Load More
  //     const observer = new IntersectionObserver(handleObserver, options);
  //     if (loader.current) {
  //       observer.observe(loader.current);
  //     }
  //   }
  // }, [groupId, dispatch]);

  const routes = useSelector((state) => state.routeReducer.routes);
  const handleDeleteSelection = () => {
    dispatch(deleteRoute());
    handleConfirmDelete();
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const handleConfirmDelete = () => {
    setShowConfirmDelete(!showConfirmDelete);
  };

  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    const newRoutes = routes.map((route) => {
      route.isChecked = !route.isChecked;
      return route;
    });

    dispatch(updateRoutes(newRoutes));
  };

  const handleCheckboxes = (pk) => {
    const newRoutes = routes.map((route) => {
      if (route.pk === pk) {
        route.isChecked = !route.isChecked;
        return route;
      } else {
        return route;
      }
    });

    dispatch(updateRoutes(newRoutes));
  };

  const [search, setSearch] = useState('');
  const hadleSearch = ({ target: { value } }) => {
    setSearch(value);
    const inputValue = value.replace(/\W/g, '%20');
    dispatch(SearchRoute(inputValue));
  };

  const handleSortSelect = (name, value) => {
    dispatch(sortRoutes(name, value, groupId));
  };

  const groups = useSelector((state) => state.groupReducer.groups);

  const loading = useSelector((state) => state.routeReducer.loading);
  // const searching = useSelector((state) => state.routeReducer.searching);
  return (
    <div>
      <div className={showConfirmDelete ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">¿Estas seguro de borrar la ruta?</p>
            <button
              className="delete"
              aria-label="close"
              onClick={handleConfirmDelete}
            />
          </header>
          <section className="modal-card-body">
            <p className="buttons">
              <button className="button" onClick={handleConfirmDelete}>
                Cancelar
              </button>
              <button
                className="button is-danger"
                onClick={handleDeleteSelection}
              >
                Borrar
              </button>
            </p>
          </section>
        </div>
      </div>
      <div className="container">
        <div className="columns margin-top">
          <div className="column">
            <div className="buttons">
              {groups.length > 1 && (
                <Link to={`/estudio/`} className="button">
                  Atras
                </Link>
              )}
              <Link
                to={`/estudio/${groupId}/routes/create/`}
                className="button is-success"
              >
                Crear nueva ruta
              </Link>
              <button
                className="button is-danger"
                onClick={handleConfirmDelete}
              >
                Borrar
              </button>
              <Import loading={loading} estudio={groupId} />
              <Link
                to={`/estudio/${groupId}/horarios/`}
                className="button is-link is-light"
              >
                Horarios
              </Link>
            </div>
          </div>
          <div className="column">
            <div className="field has-addons has-addons-right">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Buscar por nombre"
                  value={search}
                  onChange={hadleSearch}
                />
              </div>
              <div className="control">
                <button className="button is-info">Buscar</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Table
        groupId={groupId}
        handleSelectAll={handleSelectAll}
        selectAll={selectAll}
        routes={routes}
        handleCheckboxes={handleCheckboxes}
        handleSortSelect={handleSortSelect}
        loading={loading}
      />
      <Note>
        <span>* El parque vehicular de referencia es de 100,000 vehiculos</span>
      </Note>

      {/* {!searching && <div className="loading" ref={loader} />} */}
    </div>
  );
};

export default Routes;

const Note = styled.div`
  text-align: center;
`;
