import React, { useState, useEffect } from 'react';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';

export const Spoiler = ({ title, children, ...rest }) => {
  const [show, setShow] = useState(false);
  const [icon, setIcon] = useState('angle-down');
  const [color, setColor] = useState('is-link');
  const [text, setText] = useState('Ver');

  const handleClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (show) {
      setIcon('times');
      setColor('is-info');
      setText('Cerrar');
    } else {
      setIcon('angle-down');
      setColor('is-link');
      setText('Ver');
    }
  }, [show]);

  return (
    <>
      <div className="columns is-multiline">
        <div className="column is-8">{title}</div>
        <div className="column is-4">
          <button className={`button is-small ${color}`} onClick={handleClick}>
            <span className="icon is-small">
              <FontAwesome name={icon} />
            </span>
            <span>{text}</span>
          </button>
        </div>
        {show && <div className="column is-12">{children}</div>}
      </div>
    </>
  );
};
