import React, { useEffect, useCallback, useState } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from '../../Map';
import { RouteForm } from './RouteForm';
import '../../sidebar&map.css';
import { createRoute, getHeatmap } from '../../../actions/routeActions';
import { clearGeometry, getRoute } from '../../../actions/sintraficoActions';
import Switch from 'react-switch';

import MapboxService from '../../../services/MapboxService';
const mapboxService = new MapboxService();
const API = process.env.REACT_APP_API_URL;

export const RouteCreate = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearGeometry());
  }, [dispatch]);

  const [form, setForm] = useState({
    vehicles: [],
    commodities: [],
    driver: '',
  });
  const [errors, setErrors] = useState({
    route_name: null,
    start: null,
    end: null,
    driver: null,
  });

  const handleForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm({ ...form, [name]: value });
  };

  const [start, setStart] = useState([]);
  const [end, setEnd] = useState([]);
  const [alternative, setAlternative] = useState(true);
  useEffect(() => {
    if (start && end) {
      if (!errors.start && start.length > 1 && !errors.end && end.length > 1) {
        dispatch(getRoute(start, end, alternative));
      }
    }
  }, [dispatch, start, end, errors, alternative]);

  const municipalities = useSelector(
    (state) => state.routeReducer.municipalities
  );
  useEffect(() => {
    if (end && start) {
      if (!errors.start && start.length > 1 && !errors.end && end.length > 1) {
        dispatch(getHeatmap(start, end, alternative));
      }
    }
  }, [dispatch, start, end, errors, alternative]);

  const [markers, setMarkers] = useState([]);
  const [startAdress, setStartAdress] = useState(null);
  const [endAdress, setEndAdress] = useState(null);
  const handleAdress = (name, option) => {
    if (name === 'start') {
      setStartAdress(option);
      const coordinate = [option.lat, option.lon];
      setStart(coordinate);
    }
    if (name === 'end') {
      setEndAdress(option);
      const coordinate = [option.lat, option.lon];
      setEnd(coordinate);
    }

    const marker = {
      name,
      latitude: option.lat,
      longitude: option.lon,
    };

    let newMarkers = markers.map((mark) => {
      if (mark.name === name) {
        return marker;
      } else {
        return mark;
      }
    });

    const names = markers.map((mark) => {
      return mark.name;
    });

    if (!names.includes(name)) {
      newMarkers = [...markers, marker];
    }

    setMarkers(newMarkers);
  };

  const [nameRoute, setNameRoute] = useState('');
  useEffect(() => {
    const re = /[0-9]*[,\s]*([\s\w]+),[\s\w]+,[\s\w]+/;
    let nameS = '';
    let nameE = '';
    if (startAdress) {
      nameS = re.exec(
        startAdress.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      )[1];
    }
    if (endAdress && endAdress.name) {
      nameE = re.exec(
        endAdress.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      )[1];
    }
    setNameRoute(`${nameS}-${nameE}`);
  }, [startAdress, endAdress]);

  const getName = async (lat, lon) => {
    const response = await mapboxService.placeName(lat, lon);
    if (response.features[0]) {
      return {
        name: response.features[0] && response.features[0].place_name,
        label: response.features[0].place_name,
        lat: response.features[0].center[0],
        lon: response.features[0].center[0],
        bbox: [],
      };
    }
  };

  const handleClickMap = async (e) => {
    const longitude = e.lngLat[0];
    const latitude = e.lngLat[1];

    const names = markers.map((mark) => {
      return mark.name;
    });
    if (!names.includes('start')) {
      setMarkers([
        ...markers,
        {
          name: 'start',
          latitude,
          longitude,
        },
      ]);
      setStart([latitude, longitude]);
      const name = await getName(latitude, longitude);
      setStartAdress(name);
      setErrors({
        ...errors,
        start: null,
      });
    } else if (!names.includes('end')) {
      setMarkers([
        ...markers,
        {
          name: 'end',
          latitude,
          longitude,
        },
      ]);
      setEnd([latitude, longitude]);
      const name = await getName(latitude, longitude);
      setEndAdress(name);
      setErrors({
        ...errors,
        end: null,
      });
    }
  };

  const onMarkerDragEnd = useCallback(
    async (event, name) => {
      const latitude = event.lngLat[1];
      const longitude = event.lngLat[0];
      const newMarkers = markers.map((marker) => {
        if (marker.name === name) {
          return { latitude, longitude, name };
        } else {
          return marker;
        }
      });
      setMarkers(newMarkers);

      if (name === 'end') {
        setEnd([latitude, longitude]);
        const name = await getName(latitude, longitude);
        setEndAdress(name);
        setErrors({
          ...errors,
          end: null,
        });
      } else {
        setStart([event.lngLat[1], event.lngLat[0]]);
        const name = await getName(latitude, longitude);
        setStartAdress(name);
        setErrors({
          ...errors,
          start: null,
        });
      }
    },
    [markers, errors]
  );

  const { groupId } = useParams();
  const [groupUrl, setGroupUrl] = useState(null);
  useEffect(() => {
    if (groupId === '0') {
      setGroupUrl(null);
    } else {
      setGroupUrl(`${API}group/${groupId}/`);
    }
  }, [groupId]);

  const geometry = useSelector((state) => state.routeReducer.geometry);
  const loading = useSelector((state) => state.routeReducer.loading);
  const handleSave = async () => {
    const summary = geometry.features.map((route) => route.properties.summary);
    const newRoute = {
      group: groupUrl,
      name: nameRoute,
      start: start,
      start_address: startAdress.name,
      end_address: endAdress.name,
      end: end,
      wp: null,
      geometry: geometry.data,
      alternative,
      risk: '',
      distance: summary[1],
      duration: summary[0],
      driver: false,
      risk_timetable: '',
      municipalities: '',
      vehicles: form.vehicles,
      commodities: form.commodities,
    };

    dispatch(createRoute(newRoute));
    if (loading) {
      handleCancel();
    }
  };

  const handleCancel = () => {
    dispatch(clearGeometry());
    setForm({
      vehicles: '',
      commodities: '',
      driver: '',
    });
    setNameRoute('');
    setEnd([]);
    setStart([]);
    setStartAdress('');
    setEndAdress('');
    setMarkers([]);
    setErrors({
      name: null,
      start: null,
      end: null,
      driver: null,
    });
  };

  const handleChangeAlternative = () => {
    setAlternative(!alternative);
  };

  const isCreated = useSelector((state) => state.routeReducer.isCreated);
  const routes = useSelector((state) => state.routeReducer.routes);

  if (isCreated) {
    return <Redirect to={`/estudio/${groupId}/routes/${routes[0].pk}/`} />;
  } else {
    return (
      <div>
        <div className="sidebar">
          <div className="columns">
            <div className="column is-6">
              <Link to={`/estudio/`}>atras</Link>
            </div>
            <div className="column is-6 text-alight-right">
              <label>
                <span>Ruta alterna: </span>
                <Switch
                  onChange={handleChangeAlternative}
                  checked={alternative}
                />
              </label>
            </div>
          </div>
          <RouteForm
            nameRoute={nameRoute}
            setNameRoute={setNameRoute}
            start={start}
            end={end}
            driver={form.driver}
            errors={errors}
            startAdress={startAdress}
            endAdress={endAdress}
            handleChange={handleForm}
            handleSave={handleSave}
            handleCancel={handleCancel}
            handleAdress={handleAdress}
          />
        </div>

        <div className="map">
          <Map
            onClickMap={handleClickMap}
            markers={markers}
            routes={geometry ? geometry : null}
            zones={municipalities}
            onMarkerDragEnd={onMarkerDragEnd}
            draggable={true}
          />
        </div>
      </div>
    );
  }
};
