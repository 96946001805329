import React from 'react';
import {
  Legend,
  LineChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled, { css } from 'styled-components';

import { DataTime } from '../General/DataTime';
import { RiskTag } from '../General/RiskTag';
import { CustomTooltip } from './CustomTooltip';

export const RiskChart = ({
  header,
  headerColor,
  viaje,
  distance,
  duration,
  data,
  incidentes,
}) => {
  return (
    <Container>
      <Title color={headerColor}>Ruta {header}</Title>
      <br />
      <Tikets>
        <span className={'tag is-primary is-light'}>{distance}</span>
        <DataTime duration={duration} size="small" />
        <RiskTag risk={incidentes} size="medium" />
      </Tikets>

      {data && (
        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            width={500}
            height={200}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="page" />
            <YAxis type="number" domain={['dataMin', 'dataMax']} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line type="monotone" dataKey="riesgo" stroke="#fb8072" unit="%" />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
`;

const Tikets = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Title = styled.span`
  font-weight: bold;
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `};
`;
