import apiClient from '../utils/apiClient';

import {
  NOTIFY_ERROR,
  NOTIFY_SUCCESS,
  NOTIFY_WARNING,
} from '../types/notifyTypes';

const API_URL = `file/`;

// TODO: pasar a axios
export const createFromCSV = (file) => async (dispatch) => {
  dispatch({
    type: NOTIFY_WARNING,
    id: 'createFromCSV',
    message: 'Procesando datos: esto puede demorar unos minutos',
  });

  const token = apiClient.defaults.headers['Authorization'];
  try {
    const response = await fetch(`${API_URL}`, {
      method: 'POST',
      body: file,
      headers: {
        Authorization: token,
      },
    });

    const data = await response.json();
    const status = response.status;

    if (status === 200) {
      dispatch({
        type: NOTIFY_SUCCESS,
        id: 'createFromCSV',
        message: 'Rutas cargadas',
      });
    }

    return { status: status, data: data };
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      id: 'createFromCSV',
      message: 'no se pudo obtener todas las rutas',
    });
  }
};
