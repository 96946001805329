import apiClient from '../utils/apiClient';

const API_URL = `municipality/get_municipalities_by_goverment/`;

export default class MunicipalityService {
  async search(government) {
    try {
      const response = await apiClient.get(
        `${API_URL}?code_government=${government}`
      );
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }
}
