import { UPDATE_COMMODITIES, UPDATE_VEHICLES } from '../types/categortyTypes';

const INITIAL_STATE = {
  commodities: [],
  vehicles: [],
};

export const categoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_COMMODITIES:
      return { ...state, commodities: action.commodities };
    case UPDATE_VEHICLES:
      return { ...state, vehicles: action.vehicles };
    default:
      return state;
  }
};
