/** @format */

const API_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

const AccessToken = process.env.REACT_APP_MAPBOX;

export default class MapboxService {
  async search(query) {
    let response = await fetch(
      `${API_URL}${query}.json?access_token=${AccessToken}&country=mx&language=es`
    );
    let json = await response.json();
    return json;
  }

  async placeName(lat, lng) {
    let response = await fetch(
      `${API_URL}${lng},${lat}.json?access_token=${AccessToken}`
    );
    let json = await response.json();
    return json;
  }
}
