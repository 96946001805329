import React, { useEffect, useCallback } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Login } from './Login';
import Home from './Login/Home';
import PrivateRoute from './Login/PrivateRoute';
import { Navbar } from './Navbar';
import { Info } from './General/Info';
import IncidentCreate from './Incident/IncidentCreate';
import { Import } from './Incident/Import';
import { IncidentCsv } from './Incident/IncidentCsv';
import { Estudio } from './Estudio';
import { Timetables } from './Estudio/Timetables';
import { Dashboard } from './Estudio/Dashboard';
import RouteList from './Estudio/Routes/List/RouteList';
import { RouteCreate } from './Estudio/Routes/RouteCreate';
import { RouteShow } from './Estudio/Routes/Show/RouteShow';
import { authCheckState, refreshToken } from '../actions/authActions';

export const App = ({ ...rest }) => {
  const dispatch = useDispatch();
  const checkAuthStatus = useCallback(async () => {
    await dispatch(authCheckState());
    await dispatch(refreshToken());
  }, [dispatch]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const loading = useSelector((state) => state.authReducer.loading);
  const is_staff = useSelector((state) => state.authReducer.is_staff);
  const notifications = useSelector(
    (state) => state.notifyReducer.notifications
  );

  return loading ? (
    <Login />
  ) : (
    <BrowserRouter>
      <Navbar />
      {notifications &&
        notifications.map((notification) => (
          <Info
            key={notification.id}
            type={notification.type}
            message={notification.message}
          >
            {notification.message}
          </Info>
        ))}
      <Switch>
        <PrivateRoute
          component={IncidentCreate}
          is_staff={is_staff}
          path="/incidentes/"
          exact
        />
        <PrivateRoute
          component={Import}
          is_staff={is_staff}
          path="/incidentes/import/"
          exact
        />
        <PrivateRoute
          component={IncidentCsv}
          is_staff={is_staff}
          path="/incidentes/csv/"
          exact
        />
        <Route exact path="/estudio/:groupId/routes/create/">
          <RouteCreate />
        </Route>
        <Route exact path="/estudio/">
          <Estudio />
        </Route>
        <Route exact path="/estudio/:groupId/routes/">
          <RouteList />
        </Route>
        <Route exact path="/estudio/:groupId/routes/:id/">
          <RouteShow />
        </Route>
        <Route exact path="/estudio/:groupId/dashboard/">
          <Dashboard />
        </Route>
        <Route exact path="/estudio/:groupId/horarios/">
          <Timetables />
        </Route>
        <Route path="/" exact>
          <Home is_staff={is_staff} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
