import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authLogin } from '../../actions/authActions';

export const Login = () => {
  const [form, setForm] = useState({
    username: null,
    password: null,
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm({ ...form, [name]: value });
  };

  const dispatch = useDispatch();
  const handleLogin = () => {
    const { username, password } = form;
    if (username !== null && password !== null) {
      dispatch(authLogin(username, password));
    }
  };

  return (
    <div className="hero is-fullheight is-link">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="column is-8 is-offset-2">
            <h3 className="title has-text-white">Iniciar sesión</h3>
            <div className="box">
              <div className="field">
                <div className="control">
                  <input
                    className="input is-large"
                    name="username"
                    type="text"
                    placeholder="Usuario"
                    autoFocus=""
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <input
                    className="input is-large"
                    name="password"
                    type="password"
                    placeholder="Contraseña"
                    onChange={handleInput}
                  />
                </div>
              </div>
              <button
                className="button is-block is-danger is-large is-fullwidth"
                onClick={handleLogin}
              >
                Iniciar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
