import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import {Spinner} from '../../General/Spinner'
import { Chart } from './Chart.js';
import { Map } from '../../Map';
import { getReportData } from '../../../actions/estudiosActions';
import './map.css';

export const Timetables = ({ ...rest }) => {
  const { groupId } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReportData(groupId));
  }, [dispatch, groupId]);

  const routes = useSelector((state) => state.estudiosReducer.routes);
  const group = useSelector((state) => state.estudiosReducer.group);
  const timetables = useSelector((state) => state.estudiosReducer.timetables);

  return (
    <div className="container is-fluid">
      <hr />
      <div className="columns is-centered">
        <div className="column is-6">
          {/* <div className="field is-horizontal"> */}
          {/*   <div className="field-label is-normal"> */}
          {/*     <label className="label">Filtrar por fecha</label> */}
          {/*   </div> */}
          {/*   <p className="control is-expanded"> */}
          {/*     <input className="input" type="month" /> */}
          {/*   </p> */}
          {/* </div> */}
          <h5 className="title is-5 has-text-centered" >Reporte de horarios</h5>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-2">
          {group ?
            group.map((route, index) => (
              <h5 key={v4()} className="title is-5 has-text-centered"style={{height: 300,}}>
                Ruta {index + 1}: {route.name}
              </h5>
            )): <Spinner />}
        </div>
        <div className="column is-5">
          {timetables ?
            timetables.map((timetable) => <Chart key={v4()} timetable={timetable} />): <Spinner />}
        </div>
        <div className="column is-5">
          <div className="mapa">
            {routes?
              routes.map((route) => (
                <Map key={v4()} routes={route.geometry} zones={route.municipalities} />
              )): <Spinner />}
          </div>
        </div>
      </div>
    </div>
  );
};
