import apiClient from '../utils/apiClient';

import { UPDATE_COMMODITIES, UPDATE_VEHICLES } from '../types/categortyTypes';
import { NOTIFY_ERROR } from '../types/notifyTypes';

export const getCommodities = () => async (dispatch) => {
  try {
    const response = await apiClient.get(`commodity/`);

    const commodities = response.data.results.map((commodity) => ({
      ...commodity,
      value: commodity.url,
      label: commodity.name,
    }));
    dispatch({ type: UPDATE_COMMODITIES, commodities });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      message: 'error al obtener tipos de carga',
    });
  }
};

export const getVehicles = () => async (dispatch) => {
  try {
    const response = await apiClient.get(`vehicle/`);

    const vehicles = response.data.results.map((vehicle) => ({
      ...vehicle,
      value: vehicle.url,
      label: vehicle.type,
    }));
    dispatch({ type: UPDATE_VEHICLES, vehicles });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      message: 'error al obtener tipos de vehiculo',
    });
  }
};
