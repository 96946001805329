/** @format */

import React from "react";

export default function headerSelectTable(props) {
  const { data, handleChange, form } = props;
  const header = [...data[0].data.keys()];

  return (
    <tr>
      {header.map((key) => (
        <td key={key}>
          <div className="control">
            <div className="select is-small">
              <select
                name={key}
                value={form[key]}
                onChange={handleChange}
                defaultValue=""
              >
                <option value="" disabled>
                  selecciona una opción
                </option>
                <option value="lat">Latitud</option>
                <option value="lon">Longitud</option>
                <option value="coordinate">Coordenadas</option>
                <option value="code_government">Codigo de estado</option>
                <option value="name_government">Nombre de estado</option>
                <option value="code_municipality">Codigo de municipio</option>
                <option value="name_municipality">Nombre de municipio</option>
                <option value="vehicle_id">Tipo de Vehículo</option>
                <option value="category">Categoria</option>
                <option value="commodity_id">Tipo de carga</option>
                <option value="timetable">Dia</option>
                <option value="month">Mes</option>
                <option value="year">Año</option>
                <option value="date">Fecha</option>
                <option value="time">Hora</option>
                <option value="description">Descripcion</option>
              </select>
            </div>
          </div>
        </td>
      ))}
    </tr>
  );
}
