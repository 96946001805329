import {
  NOTIFY_ERROR,
  NOTIFY_INFO,
  NOTIFY_SUCCESS,
  NOTIFY_UPDATE,
  NOTIFY_WARNING,
} from '../types/notifyTypes';

import _ from 'underscore';

const INITIAL_STATE = {
  notifications: [],
};

export const notifyReducer = (state = INITIAL_STATE, action) => {
  if (
    action.type === NOTIFY_INFO ||
    action.type === NOTIFY_WARNING ||
    action.type === NOTIFY_ERROR ||
    action.type === NOTIFY_SUCCESS
  ) {
    if (action.id) {
      if (state.notifications.some((noty) => noty.id === action.id)) {
        const newNotifications = state.notifications
          .map((noty) => {
            if (noty.id === action.id) {
              noty.message = action.message;
              noty.type = action.type;
              return noty;
            } else {
              return noty;
            }
          })
          .filter(Boolean);
        return {
          notifications: newNotifications,
        };
      } else {
        return {
          notifications: [
            ...state.notifications,
            {
              id: action.id,
              type: action.type,
              message: action.message,
            },
          ],
        };
      }
    } else {
      return {
        notifications: [
          ...state.notifications,
          {
            id: _.uniqueId('notify_'),
            type: action.type,
            message: action.message,
          },
        ],
      };
    }
  } else if (action.type === NOTIFY_UPDATE) {
    return {
      notifications: [...state.notifications],
    };
  } else {
    return {
      ...state,
    };
  }
};
