import React from 'react';
import styled from 'styled-components';

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Container>
        {payload[0] && (
          <>
            <p>
              {label} : {payload[0].value}
            </p>
            <p>
              Crecimiento:
              {Math.trunc(payload[0].payload.crecimiento.toFixed(2) * 100)}%
            </p>
          </>
        )}
      </Container>
    );
  }

  return null;
};

const Container = styled.div`
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
`;
