import React  from "react";

export const  Select = ({ options, value, onChange, name, contentLeft, ...rest }) => {
    return (
      <div className="control">
        <div className="select is-small">
          <select name={name} value={value} onChange={onChange}>
            <option value="" disabled>
              Selecciona una opción
            </option>
            {options.map((option) => (
              <option key={option["pk"]} value={option["pk"]}>
                {option[contentLeft]}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
}
