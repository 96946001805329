import React, { useState } from 'react';
import { v4 } from 'uuid';

export const DataTime = ({ duration, size, i, ...rest }) => {
  const [click, setClick] = useState(false);

  const minutesToHours = (time) => {
    const regex = /([0-9]+)min/.exec(time);
    const minutes = regex[1];
    const rawHour = parseInt(minutes) / 60;
    const hour = parseInt(rawHour);
    const min = parseInt((rawHour % 1) * 60);
    return `${hour}:${min}hr`;
  };
  if (size === 'small') {
    return (
      <span className="tag  is-info is-light" onClick={() => setClick(!click)}>
        Duracion {i}: {click ? duration : minutesToHours(duration)}
      </span>
    );
  } else {
    return (
      <button
        className="button info is-info is-light"
        onClick={() => setClick(!click)}
      >
        <span>
          <strong>Duracion:</strong>
          <br />
          {duration.map((d, index) => (
            <span key={v4()}>
              {index > 0 && <span> / </span>}r{index}:{' '}
              {click ? d : minutesToHours(d)}
            </span>
          ))}
        </span>
      </button>
    );
  }
};
