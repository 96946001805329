import React from 'react';
import { Link } from 'react-router-dom';
import PrivateLink from '../Login/PrivateLink';

export default function Menu(props) {
  return (
    <div className="navbar-end">
      <PrivateLink
        is_staff={props.is_staff}
        className="navbar-item"
        to="/incidentes/"
      >
        Crear Incidentes
      </PrivateLink>
      <PrivateLink
        is_staff={props.is_staff}
        className="navbar-item"
        to="/incidentes/import/"
      >
        importar Datos
      </PrivateLink>
      <PrivateLink
        is_staff={props.is_staff}
        className="navbar-item"
        to="/incidentes/csv/"
      >
        Cargar CSV Reporte
      </PrivateLink>
        <Link className="navbar-item" to="/estudio/0/routes/create/">
          Crear rutas
        </Link>
        <Link className="navbar-item" to="/estudio/">
          Ver rutas
        </Link>
    </div>
  );
}
