/** @format */

import React, { Component } from 'react';
import { CSVReader } from 'react-papaparse';

export default class InputFile extends Component {
    handleOnDrop = (data) => {
        this.props.handleData(data);
    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    handleOnRemoveFile = (data) => {
        const type = {
            target: {
                name: null,
            },
        };

        this.props.handleData(data);
        this.props.handleClickLoad(type);
    };

    render() {
        const { delimiter } = this.props;

        return (
            <>
                <CSVReader
                    onDrop={this.handleOnDrop}
                    onError={this.handleOnError}
                    addRemoveButton
                    onRemoveFile={this.handleOnRemoveFile}
                    config={{ delimiter }}
                >
                    <span>Arrastra el CSV o da click para subirlo.</span>
                </CSVReader>
            </>
        );
    }
}
