import { LOADING, GET_ALL, UPDATE_GROUPS } from '../types/groupTypes.js';

const INITIAL_STATE = {
  loading: false,
  groups: [],
  defaultGroups: [],
};

export const groupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_GROUPS:
      return {
        ...state,
        groups: action.groups,
        loading: false,
      }
    case GET_ALL:
      return {
        ...state,
        groups: action.groups,
        defaultGroups: action.defaultGroups,
        loading: action.loading,
      };
    default:
      return state;
  }
};
