import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import FontAwesome from 'react-fontawesome';
import { v4 } from 'uuid';
import { getRouteType } from '../../../../utils';

import { Map } from '../../../Map';
import { Spinner } from '../../../General/Spinner';
import { Spoiler } from '../General/Spoiler';
import { Municipalities } from './Municipalities';
import { RiskChart } from './RiskChart';
import { RiskIndicator } from './RiskIndicator';
import {
  getById,
  getRiskChart,
} from '../../../../actions/routeActions';
import { Commodities } from '../CategoryCharts/Commodities';
import { Daysweek } from '../CategoryCharts/Daysweek';
// import { Vehicles } from '../CategoryCharts/Vehicles';
// import { Categories } from '../CategoryCharts/categories';
import { COLORS_OF_ROUTES } from '../../../../configs';

import 'font-awesome/css/font-awesome.css';
import '../../../sidebar&map.css';
import '../../../print.css';
import '../../../button.css';
import '../../../modal.css';

import Modal from 'react-modal';
import DriverRouteService from '../../../../services/DriverRouteService';

export const RouteShow = ({ ...rest }) => {
  
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [newEmail, setEmail] = React.useState('');

  const drs = new DriverRouteService()
  

  function openModal() {
    setIsOpen(true);
  }

  const modalStyle = {
    content: {
      marginTop: '2rem',
    },
  }

  function closeModal() {
    setIsOpen(false);
  }

  const deleteEmail = async function(driverRoute) {
    const resp = await drs.unCreate(driverRoute, route);
    if (resp) {
      route.drivers = resp.drivers;
      setIsOpen(false);
      setIsOpen(true);

    }
  }

  async function addEmail() {
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    if (newEmail === '' || !emailRegex.test(newEmail)) {
      console.log('Se necesita un correo válido');
      return false;
    }
    const resp = await drs.create({
      driver_email: newEmail,
      route_id: route['pk'],
    }, route);
    if (resp) {
      route.drivers = resp.drivers;
      setEmail('');
      setIsOpen(false);
      setIsOpen(true);
      return true;

    }
    return false;
  }

  function setNewEmail(email) {
    setEmail(email.target.value);
  }

  const { groupId, id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getById(id));
    dispatch(getRiskChart(id));
  }, [dispatch, id]);

  const route = useSelector((state) => state.routeReducer.route);

  const [bbox, setBbox] = useState({
    minLng: null,
    minLat: null,
    maxLng: null,
    maxLat: null,
  });
  useEffect(() => {
    if (route.start && route.end) {
      const start = route.start;
      const end = route.end;
      const newBbox = {
        minLng: null,
        minLat: null,
        maxLng: null,
        maxLat: null,
      };
      if (start[0] > end[0]) {
        newBbox.minLat = parseFloat(end[0]);
        newBbox.maxLat = parseFloat(start[0]);
      } else {
        newBbox.maxLat = parseFloat(end[0]);
        newBbox.minLat = parseFloat(start[0]);
      }
      if (start[1] > end[1]) {
        newBbox.minLng = parseFloat(end[1]);
        newBbox.maxLng = parseFloat(start[1]);
      } else {
        newBbox.maxLng = parseFloat(end[1]);
        newBbox.minLng = parseFloat(start[1]);
      }
      setBbox(newBbox);
    }
  }, [route]);

  const handlePrint = () => {
    window.print();
  };

  const after = useSelector((state) => state.routeReducer.after);
  const before = useSelector((state) => state.routeReducer.before);

  const GMunicipalities = useSelector(
    (state) => state.routeReducer.municipalities
  );

  const [municipalities, setMunicipalities] = useState(null);
  useEffect(() => {
    if (GMunicipalities) {
      let municipalities = { type: 'FeatureCollection', features: [] };
      for (let i = 0; i < GMunicipalities.length; i++) {
        municipalities.features = [
          ...municipalities.features,
          ...GMunicipalities[i].features,
        ];
      }
      let isIn = [];
      municipalities.features = municipalities.features
        .map((m) => {
          if (isIn.indexOf(m.properties.gid) < 0) {
            isIn.push(m.properties.gid);
            return m;
          } else {
            return null;
          }
        })
        .filter(Boolean);
      setMunicipalities(municipalities);
    }
  }, [GMunicipalities]);

  const riskCharts = useSelector((state) => state.routeReducer.riskCharts);
  const riskIndicator = useSelector(
    (state) => state.routeReducer.riskIndicator
  );

  if (!Object.keys(route).length) {
    return <Spinner />;
  } else {
    let markers = null;
    if (route.start.length > 1) {
      const start = route.start;
      const end = route.end;
      markers = [
        {
          name: 'start',
          longitude: parseFloat(start[1]),
          latitude: parseFloat(start[0]),
        },
        {
          name: 'end',
          longitude: parseFloat(end[1]),
          latitude: parseFloat(end[0]),
        },
      ];
    }

    return (
      <div>
        <div className="sidebar">
          <div className="buttons are-small is-centered no-print">
            <Link to={`/estudio/`}>
              <button className="button">Atras</button>
            </Link>
            {before && (
              <Link to={`/estudio/${groupId}/routes/${before}/`}>
                <button className="button is-link">
                  <FontAwesome name="arrow-left" />
                  <span>...</span>
                  Anterior
                </button>
              </Link>
            )}
            {after && (
              <Link to={`/estudio/${groupId}/routes/${after}/`}>
                <button className="button is-link">
                  Siguiente
                  <span>...</span>
                  <FontAwesome name="arrow-right" />
                </button>
              </Link>
            )}
            <button className="button" onClick={handlePrint}>
              Imprimir
            </button>
          </div>
          <h3 className="title is-3">{route.name}</h3>
          <p className="center">
            <span className="table-header">Origen: </span>
            {route.start_address}
          </p>
          {route.start && (
            <p className="center">
              <span className="tag">{route.start[0]}</span>
              <span> , </span>
              <span className="tag">{route.start[1]}</span>
            </p>
          )}
          <br />
          <p className="center">
            <span className="table-header">Destino: </span>
            {route.end_address}
          </p>
          {route.end && (
            <p className="center">
              <span className="tag">{route.end[0]}</span>
              <span> , </span>
              <span className="tag">{route.end[1]}</span>
            </p>
          )}
          <hr />
          <div>
            <button onClick={openModal} className="button">
              <FontAwesome name="truck" />
              <span className='icon-margin-left'>Choferes autorizados</span>
            </button>
            <button onClick={openModal} className="button is-link icon-margin-left">
              <FontAwesome name="share" />
              <span className='icon-margin-left'>Compartir</span>
            </button>
            <Modal 
              isOpen={modalIsOpen}
              style={modalStyle}
              contentLabel="Choferes modal"
            >
              <div>
                <h2 className="title is-3">Choferes autorizados</h2>
                <button className="button button-close-modal" onClick={closeModal}>
                  <FontAwesome name="close" />
                </button>
              </div>
              <div className='list'>
                {
                  route.drivers !== null ? route.drivers.map((e)=>(
                    <div>
                      <p>{e.email}</p>
                      <span>
                        <button className='button is-danger' onClick={()=>deleteEmail(e)}>
                          <FontAwesome name="trash" />
                        </button>
                      </span>
                      <hr/>
                    </div>
                  )) : null
                }
              </div>
              <div className='newEmail'>
                <input type='email' placeholder='Agregar correo' onChange={(em)=>setNewEmail(em)}></input>
                <button className='button is-success' onClick={addEmail}>
                  <FontAwesome name="plus" />
                </button>
              </div>
            </Modal>
          </div>
          <hr />
          <div>
            {[...Array(route.geometry.features.length).keys()].map((number) => (
              <div key={v4()}>
                <RiskIndicator incidentes={riskIndicator[number]} />
                <RiskChart
                  incidentes={riskIndicator[number]}
                  header={getRouteType(number)}
                  headerColor={COLORS_OF_ROUTES[number]}
                  viaje={route.risk[number]}
                  distance={route.distance[number]}
                  duration={route.duration[number]}
                  data={riskCharts[number]}
                />
              </div>
            ))}
            <Note>* incidentes sobre la mediana</Note>
          </div>
          <hr />
          <div className="center">
            <Daysweek id={id} />
          </div>
          <div className="center">
            <Commodities id={id} />
          </div>
          <div className="center">{/* <Vehicles id={id} /> */}</div>
          <div className="center">{/* <Categories id={id} /> */}</div>
          <div className="content">
            <ul>
              {route.municipalities &&
                route.municipalities.map((municipality, index) => (
                  <div key={v4()}>
                    <Spoiler
                      i={index}
                      title={
                        <h5
                          className="title is-5"
                          style={{ color: COLORS_OF_ROUTES[index] }}
                        >
                          Municipios en ruta {getRouteType(index)}:
                        </h5>
                      }
                    >
                      <Municipalities key={v4()} data={municipality} />
                    </Spoiler>
                  </div>
                ))}
              <hr />
            </ul>
          </div>
        </div>

        <div className="map">
          <Map
            routes={route.geometry}
            bbox={bbox}
            zones={municipalities}
            markers={markers}
          />
        </div>
      </div>
    );
  }
};

const Note = styled.span`
  font-size: 0.7em;
`;
