import React, { useState, useEffect } from 'react';

export const Info = ({ children, type, message, id, ...rest }) => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    console.log('hola');
  };

  useEffect(() => {
    if (message) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [message]);

  if (click) {
    return (
      <div className={type} style={{ marginBottom: 0 }}>
        <button
          className="delete"
          onClick={() => {
            setClick(!click);
            handleClick();
          }}
        />
        {children}
      </div>
    );
  }

  return null;
};
