import apiClient from '../utils/apiClient';

import { LOADING, GET_ALL, UPDATE_GROUPS } from '../types/groupTypes.js';
import { NOTIFY_ERROR } from '../types/notifyTypes';

const API_URL = `group/`;

export const getAll = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await apiClient.get(`${API_URL}`);

    const rawGroups = response.data.results.map((group) => {
      group.isChecked = false;
      return group;
    });
    const groups = rawGroups.filter((x) => x.created_by);
    const defaultGroups = rawGroups.filter((x) => !x.created_by);
    dispatch({ type: GET_ALL, groups, defaultGroups, loading: false });
  } catch (e) {
    if (e.response.status === 401) {
      debugger;
    }

    dispatch({
      type: NOTIFY_ERROR,
      id: 'get_all_group',
      message: 'no se encontraron rutas',
    });
  }
};

export const update = (groups) => async (dispatch) => {
  dispatch({ type: UPDATE_GROUPS, groups });
};

export const create = (name) => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const {
    groupReducer: { groups },
  } = getState();

  try {
    const response = await apiClient.post(API_URL, { name });
    const newGroups = [...groups, response.data];
    dispatch({ type: GET_ALL, groups: newGroups, loading: false });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      id: 'create_group',
      message: 'error al crear el estudio',
    });
  }
};

export const borrar = (pk) => async (dispatch, getState) => {
  const {
    groupReducer: { groups },
  } = getState();

  dispatch({ type: LOADING });

  let responses = [];
  for (const group of groups) {
    if (group.isChecked) {
      try {
        const response = await apiClient.delete(`${API_URL}${group.pk}/`);

        if (response.status === 204) {
          responses.push(group.pk);
        }
      } catch (error) {
        dispatch({
          type: NOTIFY_ERROR,
          message: 'Error al borrar grupo.',
        });
      }
    }
  }
  const newGroups = groups
    .map((group) => {
      if (responses.indexOf(group.pk) >= 0) {
        return null;
      } else {
        return group;
      }
    })
    .filter(Boolean);

  dispatch({ type: UPDATE_GROUPS, groups: newGroups });
};
