import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDaysWeek } from '../../../../actions/routeActions';
import { Spinner } from '../../../General/Spinner';
import {
  Legend,
  LineChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export const Daysweek = ({ id, ...rest }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDaysWeek(id));
  }, [dispatch, id]);

  const loading = useSelector((state) => state.routeReducer.loadDaysweek);
  const daysweek = useSelector((state) => state.routeReducer.daysweek);

  if (loading) return <Spinner />;
  else if (daysweek['length'] < 1) {
    return <></>;
  } else {
    return (
      <>
        <p>Dispersion de incidentes en la semana</p>
        {daysweek && (
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              width={500}
              height={200}
              data={daysweek}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="page" />
              <YAxis type="number" domain={['dataMin', 'dataMax']} unit="%" />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="madrugada"
                stroke="#80b1d3"
                unit="%"
              />
              <Line
                type="monotone"
                dataKey="matutino"
                stroke="#80b1d3"
                unit="%"
              />
              <Line
                type="monotone"
                dataKey="vespertino"
                stroke="#bebada"
                unit="%"
              />
              <Line
                type="monotone"
                dataKey="nocturno"
                stroke="#fb8072"
                unit="%"
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </>
    );
  }
};
