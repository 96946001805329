import React, { useState, useEffect } from 'react';
import MiniChart from 'react-mini-chart';

export const Chart = ({ riesgos }) => {
  const [data, setData] = useState([]);
  const [grown, setGrown] = useState([]);
  useEffect(() => {
    if (riesgos) {
      const newData = riesgos.map((x) => x.risk);
      setData(newData);
      const newGrown = riesgos.map((x) => x.grown);
      setGrown(newGrown);
    }
  }, [riesgos]);

  const [color, setColor] = useState('#039be5');
  useEffect(() => {
    if (grown.length > 0) {
      if (grown[grown.length - 1] <= 0) {
        setColor('#FF6600');
      }
    }
  }, [grown]);

  return (
    <MiniChart strokeColor={color} width={50} height={10} dataSet={data} />
  );
};
