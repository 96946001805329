import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { logout } from '../../actions/authActions';

import Menu from './Menu';
import logo from './logo.png';

export const Navbar = ({ is_staff }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <nav
      className="navbar is-link"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand logo">
        <Link className="navbar-item" to="/estudio/">
          <img src={logo} width="112" height="28" alt="analyze-it" />
        </Link>

        <button
          className="button is-link navbar-burger burger"
          onClick={handleClick}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div className={'navbar-menu ' + (isActive ? 'is-active' : '')}>
        <div className="navbar-end">
          <Menu is_staff={is_staff} />
          <div className="navbar-item">
            <div className="buttons">
              <button onClick={handleLogout} className="button is-danger">
                <strong>Salir</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
