import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { getCommodities } from '../../../../actions/routeActions';
import { Spinner } from '../../../General/Spinner';
import { Spoiler } from '../General/Spoiler';
import { COLORS_OF_ROUTES } from '../../../../configs';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export const Commodities = ({ id, ...rest }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCommodities(id));
  }, [dispatch, id]);

  const isLoading = useSelector((state) => state.routeReducer.loadCommodities);
  const commodities = useSelector((state) => state.routeReducer.commodities);

  if (isLoading) return <Spinner />;
  else if (commodities['length'] < 1) return <></>;
  else {
    return (
      <>
        {commodities && (
          <Spoiler
            key={v4()}
            title={<h5 className="title is-5">Tipos de carga:</h5>}
          >
            <ResponsiveContainer width="100%" height={200}>
              <BarChart
                width={500}
                height={200}
                data={commodities.data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis unit='%'/>
                <Tooltip />
                {[...Array(commodities['length']).keys()].map((number) => (
                  <Bar
                    key={number}
                    dataKey={`ruta_${number}`}
                    fill={COLORS_OF_ROUTES[number]}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Spoiler>
        )}
      </>
    );
  }
};
