import apiClient from '../utils/apiClient';
const API_URL = `incident/`;

export default class IncidentService {
  async create(incident) {
    try {
      const response = await apiClient.post(API_URL, incident);

      return { status: response.status, data: response.data };
    } catch (e) {
      console.log(e);
    }
  }

  async importData(incidents) {
    try {
      const response = await apiClient.post(
        `${API_URL}import_data/`,
        incidents
      );

      return { status: response.status, data: response.data };
    } catch (e) {
      console.log(e);
    }
  }
}
