import React, { useState } from 'react';

export const ConfirDelete = ({ handleClick, ...rest }) => {
  const [modal, setModal] = useState(false);

  return (
    <div>
      <div className={modal ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">¿Estas seguro de borrar la ruta?</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                setModal(!modal);
              }}
            />
          </header>
          <section className="modal-card-body">
            <p className="buttons">
              <button className="button" onClick={() => setModal(!modal)}>
                Cancelar
              </button>
              <button className="button is-danger" onClick={handleClick}>
                Borrar
              </button>
            </p>
          </section>
        </div>
      </div>
      <button className="button is-danger" onClick={() => setModal(!modal)}>
        Borrar
      </button>
    </div>
  );
};
