import React, { useEffect, useState } from 'react';
import { zoneLayer } from '../../../Map';
import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const Municipality = ({
  percentil,
  goverment,
  name,
  duration,
  ...rest
}) => {
  const [color, setColor] = useState('#fff');
  const [textColor, setTextColor] = useState('#4a4a4a');

  useEffect(() => {
    const colors = zoneLayer['paint']['fill-color']['stops'];
    console.log(colors);

    if (percentil > colors[5][0]) {
      setColor(colors[5][1]);
      setTextColor('#fff');
    }
    if (percentil < colors[5][0] && percentil > colors[4][0]) {
      setColor(colors[4][1]);
    }
    if (percentil < colors[4][0] && percentil > colors[3][0]) {
      setColor(colors[3][1]);
    }
    if (percentil < colors[3][0] && percentil > colors[1][0]) {
      setColor(colors[2][1]);
    }
    if (percentil < colors[1][0] && percentil > colors[0][0]) {
      setColor(colors[1][1]);
    }
    if (percentil < colors[0][0]) {
      setColor(colors[0][1]);
      setTextColor('#fff');
    }
  }, [percentil]);

  return (
    <li>
      <Tag
        data-tip="incidentes sobre la mediana"
        color={color}
        textColor={textColor}
      >
        {Math.trunc(percentil)}
      </Tag>
      <ReactTooltip />
      <span> </span>
      {goverment}/{name}
      <span> </span>
      <span className="tag is-info is-light">
        {Math.trunc(duration / 60)}min
      </span>
    </li>
  );
};

const Tag = styled.span`
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75em;
  height: 2em;
  justify-content: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
      color: ${props.textColor};
    `};
`;
