import {
  AUTH_FAIL,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  REFRESH,
} from '../types/authTypes';
import moment from 'moment';
import apiClient from '../utils/apiClient';

const attachTokenToRequest = (token = null) => {
  apiClient.defaults.headers['Authorization'] = token ? `Bearer ${token}` : '';
};

const authSuccess = (is_staff, st_token) => {
  return {
    type: AUTH_SUCCESS,
    is_staff,
    st_token,
  };
};

const authFail = (error) => {
  return {
    type: AUTH_FAIL,
    error: error,
  };
};

export const authLogin = (username, password) => async (dispatch) => {
  try {
    const res = await apiClient.post('token/', {
      username,
      password,
    });
    const token = res.data.access;
    attachTokenToRequest(token);
    dispatch({ type: REFRESH, refresh: res.data.refresh });

    const response = await apiClient.get('current_user/');
    const user = response.data;
    dispatch(authSuccess(user.is_staff, user.st_token));
  } catch (err) {
    dispatch(authFail(err));
  }
};

export const authCheckState = () => {
  return async (dispatch) => {
    try {
      const res = await apiClient.get('current_user/');
      const user = res.data;

      dispatch(authSuccess(user.is_staff, user.st_token));
    } catch (err) {
      dispatch(authFail(err));
    }
  };
};

export const refreshToken = () => async (dispatch, getState) => {
  const {
    authReducer: { refresh },
  } = getState();
  try {
    const res = await apiClient.post('token/refresh/', {
      refresh,
    });

    const token = res.data.access;

    attachTokenToRequest(token);

    const now = moment();
    const expirationDate = moment(res.data.access_token_expiration);
    console.log(expirationDate);

    setTimeout(refreshToken, expirationDate.diff(now) - 500);
  } catch (err) {
    dispatch(logout());
  }
};

export const logout = () => {
  // apiClient.post('auth/logout/');

  // Removing authorization token from axios request
  attachTokenToRequest();
  return {
    type: AUTH_LOGOUT,
  };
};
