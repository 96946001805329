import { useEffect, useState } from 'react';

export const useColorRiskRoute = (risk) => {
  const [color, setColor] = useState('is-light');
  useEffect(() => {
    if (risk > 900) {
      setColor('is-danger');
    }
    if (risk < 800 && risk > 300) {
      setColor('is-warning');
    }
    if (risk < 300) {
      setColor('is-success');
    }
  }, [risk]);

  return color;
};
