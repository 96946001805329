import {
  REFRESH,
  AUTH_LOGOUT,
  AUTH_FAIL,
  AUTH_SUCCESS,
} from '../types/authTypes';

const INITIAL_STATE = {
  is_staff: false,
  is_login: false,
  loading: true,
  error: [],
  refresh: '',
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REFRESH:
      return {
        ...state,
        refresh: action.refresh,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        loading: true,
        is_staff: false,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        is_staff: action.is_staff,
        st_token: action.st_token,
      };
    case AUTH_FAIL:
      return { ...state, loading: true, error: action.error };
    default:
      return state;
  }
};

export default authReducer;
