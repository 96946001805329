import { GET_GROUP, GET_GEOMETRIES, GET_TIMETABLES } from '../types/estudiosTypes'

const INITIAL_STATE = {
  group: null,
  routes: null,
  timetables: null,
};

export const estudiosReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_GROUP:
      return {
        ...state,
        group: action.group
      }
    case GET_GEOMETRIES:
      return {
        ...state,
        routes: action.routes
      }
    case GET_TIMETABLES:
      return {
        ...state,
        timetables: action.timetables
      }
    default:
      return state;
  }
};
