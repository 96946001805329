import axios from 'axios';
import { COLORS_OF_ROUTES } from '../configs';

import {
  CLEAR_GEOMETRY,
  LOADING,
  UPDATE_GEOMETRY_ROUTE,
} from '../types/routeTypes';
import { NOTIFY_ERROR } from '../types/notifyTypes';

const API_URL = 'http://api.sintrafico.com/';

export const getRoute = (start, end, alternative) => async (
  dispatch,
  getState
) => {
  const {
    authReducer: { st_token },
  } = getState();
  dispatch({ type: LOADING });
  try {
    const response = await axios.get(
      `${API_URL}route?key=${st_token}&start=${start}&end=${end}&alternatives=${alternative}`
    );

    let counter = 0;
    const geometries = { type: 'FeatureCollection', features: [] };
    geometries['features'] = response.data.routes.map((route) => {
      return {
        type: 'Feature',
        properties: {
          color: COLORS_OF_ROUTES[counter++], // red
          summary: route.summary,
        },
        geometry: route.geometry,
      };
    });

    dispatch({ type: UPDATE_GEOMETRY_ROUTE, geometry: geometries });
  } catch (e) {
    dispatch({
      type: NOTIFY_ERROR,
      message: 'no se pudo obtener la ruta intente mas tarde',
    });
  }
};

export const clearGeometry = () => (dispatch) => {
  dispatch({ type: CLEAR_GEOMETRY });
};
