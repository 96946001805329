import React, { useEffect } from 'react';
import { SearchAddress } from '../../General/SearchAddress';
import { getCommodities, getVehicles } from '../../../actions/categoryActions';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { ButtonSelectAll } from './General/ButtonSelectAll';

export const RouteForm = ({
  nameRoute,
  setNameRoute,
  start,
  end,
  driver,
  errors,
  handleChange,
  handleSave,
  handleCancel,
  handleAdress,
  startAdress,
  endAdress,
  ...rest
}) => {
  const vehicles = useSelector((state) => state.categoryReducer.vehicles);
  const commodities = useSelector((state) => state.categoryReducer.commodities);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommodities());
    dispatch(getVehicles());
  }, [dispatch]);

  const handleSelect = (value, { action, removedValue, name }) => {
    const urls = value.map((categorie) => categorie.url);
    const data = { target: { name, value: urls } };
    handleChange(data);
  };

  return (
    <div>
      <h3 className="title is-3">Trazar ruta</h3>
      <div className="field">
        <label className="label">Origen:</label>
        <div className="control">
          <SearchAddress
            onChange={handleAdress}
            name="start"
            value={startAdress}
          />
        </div>
        {errors.start && (
          <p className="help is-danger">Escribe una cordenada correcta</p>
        )}
      </div>
      <div className="field">
        <label className="label">Destino:</label>
        <div className="control">
          <SearchAddress onChange={handleAdress} name="end" value={endAdress} />
        </div>
        {errors.end && (
          <p className="help is-danger">Escribe una cordenada correcta</p>
        )}
      </div>
      <div className="field">
        <label className="label">Vehículo:</label>
        <ButtonSelectAll>
          <Select
            onChange={handleSelect}
            name="vehicles"
            options={vehicles}
            className="basic-multi-select"
            classNamePrefix="select"
            isMulti
          />
        </ButtonSelectAll>
      </div>
      <div className="field">
        <label className="label">Tipo de carga:</label>
        <ButtonSelectAll>
          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            name="commodities"
            options={commodities}
            onChange={handleSelect}
          />
        </ButtonSelectAll>
      </div>
      {/* <div className="field"> */}
      {/*   <label className="label">Operador:</label> */}
      {/*   <div className="control"> */}
      {/*     <input */}
      {/*       className={'input is-small' + (errors.driver ? ' is-danger' : '')} */}
      {/*       type="text" */}
      {/*       onChange={handleChange} */}
      {/*       value={driver} */}
      {/*       name="driver" */}
      {/*     /> */}
      {/*   </div> */}
      {/*   {errors.driver && <p className="help is-danger">{errors.driver}</p>} */}
      {/* </div> */}
      <div className="field">
        <label className="label">Nombre:</label>
        <div className="control">
          <input
            className={
              'input is-small' + (errors.route_name ? ' is-danger' : '')
            }
            type="text"
            onChange={(e)=> setNameRoute(e.target.value)}
            value={nameRoute}
          />
        </div>
        {errors.name && <p className="help is-danger">{errors.name}</p>}
      </div>
      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button className="button is-link is-danger" onClick={handleCancel}>
            Cancelar
          </button>
        </div>
        <div className="control">
          <button className="button is-success" onClick={handleSave}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};
