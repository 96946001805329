import React from 'react';
import { Route } from './Route';
import { ButtonSort } from './ButtonSort';
import { Spinner } from '../../../General/Spinner';
import { v4 } from 'uuid';

export const Table = ({
  groupId,
  handleSelectAll,
  selectAll,
  routes,
  handleCheckboxes,
  handleSortSelect,
  loading,
  ...rest
}) => {
  return (
    <div className="container">
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth center margin-top">
        <thead>
          <tr>
            <td className="table-header">
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectAll}
              />
            </td>
            <td className="table-header has-text-centered">
              <ButtonSort
                type="is-link"
                onChange={handleSortSelect}
                name="name"
                iType="is-link"
              >
                Nombre
              </ButtonSort>
            </td>
            <td className="table-header has-text-centered">
              <ButtonSort
                type="is-link"
                onChange={handleSortSelect}
                name="created_at"
                iType="is-link"
              >
                Fecha
              </ButtonSort>
            </td>
            <td className="table-header has-text-centered">Direcciones</td>
            <td className="table-header has-text-centered">
              ordenar por:
              <div className="tags is-centered">
                <ButtonSort
                  type="is-danger"
                  onChange={handleSortSelect}
                  name="risk"
                  iType="is-success"
                >
                  <span> </span>
                  Riesgo
                </ButtonSort>
                <ButtonSort
                  type="is-success"
                  onChange={handleSortSelect}
                  name="distance"
                  iType="is-success"
                >
                  <span> </span>
                  Distancia
                </ButtonSort>
                <ButtonSort
                  type="is-info"
                  onChange={handleSortSelect}
                  name="duration"
                  iType="is-info"
                >
                  <span> </span>
                  Tiempo
                </ButtonSort>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Spinner />
          ) : (
            routes.map((route) => (
              <Route
                groupId={groupId}
                handleCheckboxes={handleCheckboxes}
                route={route}
                key={v4()}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};
