import React from 'react';
import { Provider } from 'react-redux';
import { App } from './components/App';
import { generateStore } from './reducers/store';

const store = generateStore();

export const Main = () => (
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
