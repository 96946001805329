import apiClient from '../utils/apiClient';

const API_URL = `vehicle/`;

export default class VehicleService {
  async all() {
    try {
      const response = await apiClient.get(API_URL);
      return response.data.results;
    } catch (e) {
      console.log('error');
    }
  }

  async create(vehicle) {
    try {
      const response = await apiClient.post(API_URL, vehicle);
      return { status: response.status, data: response.data };
    } catch (e) {
      console.log(e, 'error');
    }
  }
}
