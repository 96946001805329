/** @format */

import React, { useState } from 'react';

export default function InputDelimiter(props) {
    const [click, setClick] = useState(false);

    const { onChange, value } = props;

    return (
        <div>
            {!click && (
                <>
                <span>el delimitador por defecto es: "{value}" ¿quieres cambiarlo?</span>
                <button className='button' onClick={() => setClick(!click)}>
                    Cambiar
                </button>
                </>
                )}
            {click && (
                <div className='field is-horizontal'>
                    <div className='field-label'>
                        <label className='label'>delimitado por:</label>
                    </div>
                    <div className='field-body'>
                        <div className='field'>
                            <div className='control'>
                                <input
                                    className='input'
                                    type='text'
                                    name='delimiter'
                                    onChange={onChange}
                                    value={value}
                                    autoFocus
                                />
                            </div>
                        </div>
                    </div>
                    <button className='button' onClick={() => setClick(!click)}>
                        Guardar
                    </button>
                </div>
            )}
        </div>
    );
}
