/** @format */

import React, { Component } from "react";
import {Select} from "../General/Select";
import ReactSelect from "react-select";

import VehicleService from "../../services/VehicleService";
import CategoryService from "../../services/CategoryService";
import CommodityService from "../../services/CommodityService";
import MunicipalityService from "../../services/MunicipalityService";
import { governments } from "../../data";

const vehicleService = new VehicleService();
const categoryService = new CategoryService();
const commodityService = new CommodityService();
const municipalityService = new MunicipalityService();

export default class IncidentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      categories: [],
      commodities: [],
      municipalities: [],
      click: false,
      goverment: null,
    };
  }

  async componentDidMount() {
    const vehicles = await vehicleService.all();
    const categories = await categoryService.all();
    const commodities = await commodityService.all();

    this.setState({ vehicles, categories, commodities });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { goverment } = this.state;
    if (prevState.goverment !== goverment) {
      const municipalities = await municipalityService.search(goverment);
      this.setState({ municipalities });
    }
  }

  handleChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      const e = {
        target: {
          value: inputValue.value,
          name: actionMeta.name,
        },
      };
      this.props.onChange(e);
      this.setState({ goverment: e.target.value });
    }
    if (actionMeta.action === "clear") {
      const e = {
        target: {
          value: null,
          name: actionMeta.name,
        },
      };
      this.props.onChange(e);
      this.setState({ goverment: null });
    }
  };

  handleClick = () => {
    const e = {
      target: {
        value: null,
        name: "code_government",
      },
    };
    this.props.onChange(e);
    const event = {
      target: {
        value: null,
        name: "code_municipality",
      },
    };
    this.props.onChange(event);
    this.setState({ click: !this.state.click });
  };

  render() {
    const {
      vehicles,
      categories,
      commodities,
      click,
      municipalities,
    } = this.state;

    const {
      vehicle,
      category,
      commodity,
      time,
      description,
      lngLat,
      onChange,
      handleSave,
      handleCancel,
      errors,
    } = this.props;

    return (
      <div>
        <h3 className="title is-3">AGREGAR INCIDENTE</h3>
        <div className="field">
          <label className="label">Vehículo:</label>
          <Select
            onChange={onChange}
            value={vehicle}
            name="vehicle"
            contentLeft="type"
            contentRight="brand"
            options={vehicles}
            error={errors.vehicle_id}
          />
          {errors.vehicle_id && (
            <p className="help is-danger">Selecciona un vehículo</p>
          )}
        </div>
        <div className="field">
          <label className="label">Categoria:</label>
          <Select
            onChange={onChange}
            value={category}
            name="category"
            contentLeft="name"
            contentRight="description"
            options={categories}
            error={errors.category}
          />
          {errors.category && (
            <p className="help is-danger">Selecciona una categoria</p>
          )}
        </div>
        <div className="field">
          <label className="label">Tipo de carga:</label>
          <Select
            onChange={onChange}
            value={commodity}
            name="commodity"
            contentLeft="name"
            contentRight="description"
            options={commodities}
            error={errors.commodity_id}
          />
          {errors.commodity_id && (
            <p className="help is-danger">Selecciona el tipo de carga</p>
          )}
        </div>
        <div className="field">
          <label className="label">Hora:</label>
          <div className="control">
            <input
              className={"input is-small" + (errors.time ? " is-danger" : "")}
              type="time"
              onChange={onChange}
              value={time}
              name="time"
            />
          </div>
          {errors.time && <p className="help is-danger">Selecciona la hora</p>}
        </div>
        <div className="field">
          <label className="label">Geolocalización:</label>
          <div className="control">
            <input
              className={"input is-small" + (errors.lngLat ? " is-danger" : "")}
              type="text"
              onChange={onChange}
              value={lngLat}
              name="lngLat"
            />
          </div>
          {errors.lngLat && (
            <p className="help is-danger">
              Introduce o selecciona una localidad correcta
            </p>
          )}
        </div>
        {click && (
          <>
            <div className="field">
              <label className="label">Estado:</label>
              <div className="control">
                <ReactSelect
                  name="code_government"
                  options={governments}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Municipio:</label>
              <div className="control">
                <ReactSelect
                  name="code_municipality"
                  options={municipalities}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </>
        )}
        <div className="field">
          <label className="label">Descripcion:</label>
          <div className="control">
            <textarea
              className={
                "input is-small" + (errors.description ? " is-danger" : "")
              }
              onChange={onChange}
              value={description}
              name="description"
            />
          </div>
          {errors.description && (
            <p className="help is-danger">Escribe una descripcion</p>
          )}
        </div>
        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              className="button is-link is-small"
              onClick={this.handleClick}
            >
              Agregar municipio
            </button>
          </div>
          <div className="control">
            <button className="button is-link is-danger" onClick={handleCancel}>
              Cancelar
            </button>
          </div>
          <div className="control">
            <button className="button is-success" onClick={handleSave}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    );
  }
}
