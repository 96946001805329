/** @format */

export const secondToTime = (time) => {
  const sec_num = parseInt(time, 10); // don't forget the second param
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes;
};

export const timetableToHours = (timetable) => {
  const template = [];

  const hours_catalog = {
    '12pm': '00:00:00',
    '1am': '01:00:00',
    '2am': '02:00:00',
    '3am': '03:00:00',
    '4am': '04:00:00',
    '5am': '05:00:00',
    '6am': '06:00:00',
    '7am': '07:00:00',
    '8am': '08:00:00',
    '9am': '09:00:00',
    '10am': '10:00:00',
    '11am': '11:00:00',
    '12am': '12:00:00',
    '1pm': '13:00:00',
    '2pm': '14:00:00',
    '3pm': '15:00:00',
    '4pm': '16:00:00',
    '5pm': '17:00:00',
    '6pm': '18:00:00',
    '7pm': '19:00:00',
    '8pm': '20:00:00',
    '9pm': '21:00:00',
    '10pm': '22:00:00',
    '11pm': '23:00:00',
  };

  for (const name of Object.keys(hours_catalog)) {
    if (
      timetable.start_hour <= hours_catalog[name] &&
      timetable.end_hour > hours_catalog[name]
    ) {
      template.push({
        name: name,
        [timetable.name]: timetable.total,
      });
    }
  }
  return template;
};

export const getRouteType = (routeId) => {
  if (routeId === 0) {
    return 'Optima';
  } else {
    return 'Alterna';
  }
};
