import React, { useState, useEffect } from 'react';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
import { useDispatch } from 'react-redux';
import { RoutesFromCsv } from '../../../../actions/routeActions';
const API = process.env.REACT_APP_API_URL;

export const Import = ({ loading, estudio }) => {
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };

  useEffect(() => {
    if (loading) {
      setClick(false);
    }
  }, [loading]);

  const handleCancel = () => {
    setFile(null);
    setClick(false);
  };

  const [file, setFile] = useState('');
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const dispatch = useDispatch();
  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('estudio', `${API}group/${estudio}/`);
    dispatch(RoutesFromCsv(formData));
    setClick(false);
  };

  return (
    <>
      <div className={click ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Importar Rutas</p>
            <button
              className="delete"
              aria-label="close"
              onClick={handleCancel}
            />
          </header>
          <section className="modal-card-body">
            <div className="buttons has-addons is-centered">
              <a
                className="button"
                target="noreferrer"
                href={`${API}route/import_routes_csv`}
              >
                Descargar ejemplo
              </a>
            </div>
            <div className="field">
              <div className="file is-centered is-boxed has-name">
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="reporte"
                    onChange={handleFileChange}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <FontAwesome name="fas fa-upload" />
                    </span>
                    <span className="file-label">Seleccionar archivo</span>
                  </span>
                  <span className="file-name">{file && file.name}</span>
                </label>
              </div>
            </div>
            {file && (
              <div className="buttons has-addons is-centered">
                <button
                  className="button is-success is-centered"
                  onClick={handleUpload}
                >
                  Subir archivo
                </button>
              </div>
            )}
          </section>
        </div>
      </div>
      <button className="button" onClick={handleClick}>
        Importar
      </button>
    </>
  );
};
