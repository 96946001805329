import React from 'react';
import AsyncSelect from 'react-select/async';
import MapboxService from '../../services/MapboxService';

const mapboxService = new MapboxService();

export const SearchAddress = ({ onChange, name, value, ...rest }) => {
  const addLabels = (search) => {
    const options = search.features.map((feture) => {
      const option = {
        name: feture.place_name,
        label: feture.place_name,
        lon: feture.center[0],
        lat: feture.center[1],
        bbox: feture.bbox,
      };

      return option;
    });

    return options;
  };

  const loadOptions = async (search, callback) => {
    const searchValue = search.replace(/\W/g, '%20');
    const response = await mapboxService.search(searchValue);
    callback(addLabels(response));
  };

  const handleChange = (option) => {
    onChange(name, option);
  };

  return (
    <AsyncSelect
      value={value}
      placeholder="Buscar dirección"
      cacheOptions
      loadOptions={loadOptions}
      onChange={handleChange}
    />
  );
};
