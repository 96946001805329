import axios from 'axios';

const ENDPOINT = process.env.REACT_APP_API_URL;

const apiClient = () => {
  const defaultOptions = {
    baseURL: ENDPOINT,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return axios.create(defaultOptions);
};

export default apiClient();
