import { LOADING, SET_ALL } from '../types/municipalityTypes';

const INITIAL_STATE = {
  municipalities: [],
  loading: false,
};

export const municipalityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ALL:
      return {
        ...state,
        municipalities: action.municipalities,
      };
    case LOADING:
      return { ...state, loading: !state.loadding };
    default:
      return state;
  }
};
