import React, { useState } from 'react';
import BodyTable from './general/BodyTable';
import HeaderSelectTable from './general/HeaderSelectTable';
import { Info } from '../General/Info';
import InputDelimiter from './general/InputDelimiter';
import InputFile from './general/InputFile';
import IncidentService from '../../services/IncidentService';
import './Import.css';

const incidentService = new IncidentService();

export const Import = () => {
  const [data, setData] = useState(null);

  const handleData = (data) => {
    setData(data);
  };

  const [form, setForm] = useState({});
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setForm({ ...form, [name]: value });
  };

  const [delimiter, setDelimiter] = useState(',');
  const handleInputDelimiter = (e) => {
    const value = e.target.value;

    setDelimiter(value);
  };

  const [type, setType] = useState(null);
  const [clickLoad, setClickLoad] = useState(false);
  const handleClickLoad = (e) => {
    const name = e.target.name;
    setType(name);
    setClickLoad(!clickLoad);
  };

  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [load, setLoad] = useState(false);
  const [finish, setFinish] = useState(false);
  const handleSave = async () => {
    let errors = [];
    setTotal(data.length - 1);
    setCount(0);
    setLoad(true);
    setFinish(false);

    for (let i = 1; i < data.length; i++) {
      const newIncident = {
        data: data[i],
        type,
        form,
        header: data[0].data,
      };
      const response = await incidentService.importData(newIncident);
      if (response.status === 201) {
        setCount(i);
        errors = errors.concat(response.data.errors);
        console.log(count, errors);
      }
    }

    if (count === total) {
      setData(null);
      setForm({});
      setType(null);
      setLoad(false);
      setClickLoad(false);
      setFinish(true);
    }
  };

  return (
    <div>
      {load && (
        <Info type="is-warning" message={load}>
          <strong>
            Cargando datos {count} de {total}:
          </strong>{' '}
          esto puedo tardar unos minutos
        </Info>
      )}
      {finish && (
        <Info type="is-success" message={finish}>
          <strong>Datos Guardados</strong>
        </Info>
      )}
      <div className="container margin-top">
        {!clickLoad && (
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-full">
                <InputDelimiter
                  onChange={handleInputDelimiter}
                  value={delimiter}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <button
                  name="incidente"
                  className="button is-success is-fullwidth"
                  onClick={handleClickLoad}
                >
                  Importar incidentes
                </button>
              </div>
              <div className="column">
                <button
                  name="ejecutivo"
                  className="button is-info is-fullwidth"
                  onClick={handleClickLoad}
                >
                  Importar datos del Secretariado Ejecutivo
                </button>
              </div>
            </div>
          </div>
        )}
        {clickLoad && (
          <>
            <InputFile
              handleData={handleData}
              handleClickLoad={handleClickLoad}
              delimiter={delimiter}
            />
          </>
        )}
        {data && (
          <button className="button is-success" onClick={handleSave}>
            Guardar datos
          </button>
        )}
        <hr />
        <div className="table-container">
          <table className="table is-striped is-hoverable is-narrow center">
            <thead>
              {data && (
                <HeaderSelectTable
                  data={data}
                  handleChange={handleChange}
                  form={form}
                />
              )}
            </thead>
            {data && <BodyTable data={data} />}
          </table>
        </div>
      </div>
    </div>
  );
};
