import React from 'react';
import { v4 } from 'uuid';
import { useColorRiskRoute } from '../hooks/useColorRiskRoute';

const RiskText = ({ risk }) => {
  return (
    <>
      {risk} {risk > 1 ? 'Viajes*' : 'viaje*'}
    </>
  );
};

export const RiskTag = ({ risk, size, i, ...rest }) => {
  const color = useColorRiskRoute(risk);

  if (size === 'small') {
    return (
      <span className={'tag is-primary ' + color}>
        <RiskText risk={Math.trunc(risk) + 1} />
      </span>
    );
  } else if (size === 'medium') {
    return <span className={'tag is-primary ' + color}>{risk}*</span>;
  } else {
    return (
      <button className={'button info ' + color}>
        <span>
          <strong>Riesgo:</strong>
          <br />
          {risk &&
            risk.map((r, index) => (
              <span key={v4()}>
                {index > 0 && <span> / </span>}
                {Math.trunc(risk) + 1} punto
              </span>
            ))}
        </span>
      </button>
    );
  }
};
