import React from 'react';
import styled from 'styled-components';

export const RiskIndicator = ({ incidentes }) => {
  return (
    <Container>
      <Number>{incidentes}</Number>
      <Text>
        incidentes <br />
        sobre la <br />
        mediana
      </Text>
    </Container>
  );
};

const Container = styled.div`
  background-color: #ff6d6a;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 90px;
  height: 90px;
  flex-direction: column;
`;

const Number = styled.div`
  font-weight: bold;
  font-size: 120%;
`;

const Text = styled.div`
  font-size: 65%;
`;
