import apiClient from '../utils/apiClient';

const API_URL = `driver_route/`;

export default class DriverRouteService {
  async all() {
    try {
      const response = await apiClient.get(API_URL);
      return response.data.results;
    } catch (e) {
      console.log('error');
      return undefined;
    }
  }

  async create(driverRoute, route) {
    try {
      const emails = route['drivers'] ? route['drivers'] : [];

      if (!emails.find(e=>e.email === driverRoute.driver_email)) {
        const response = await apiClient.post(API_URL, driverRoute);
        if (response.status === 201) {
          const updateRoute = await this.addDriversMailToRoute(response.data['id'], driverRoute.driver_email, route);
          return updateRoute.data;
        }
      }
      return undefined;
    } catch (e) {
      console.log(e, 'error');
      return undefined;
    }
  }

  async unCreate(driverRoute, route) {
    try {
      
      const response = await apiClient.delete(`${API_URL}${driverRoute.id}/`);
      if (response.status === 204) {
        const resp = await this.deleteDriverEmailToRoute(driverRoute.id, route)
        return resp.data;
      };
      return undefined;
    } catch (e) {
      console.log(e, 'error');
      return undefined;
    }
  }

  async get_by_email(email) {
    try {
      const response = await apiClient.get(`get_route_by_email/${email}/`);
      return { status: response.status, data: response.data };
    } catch (e) {
      console.log(e, 'error');
    }
  }

  async addDriversMailToRoute(id, email, route) {
    try {
      const route_id = route['pk'];
      const emails = route['drivers'] ? route['drivers'] : [];

      if (!emails.find(e=>e.email === email)) {
        emails.push({id,email});
        const update = {
          drivers: emails,
        }
        const response = await apiClient.patch(`route/${route_id}/`, update);
        return response;
      }

    } catch (e) {
      console.log(e, 'error');
    }

    return undefined;
  }

  async deleteDriverEmailToRoute(id, route) {
    try {
      const route_id = route['pk'];
      const emails = route['drivers'] ? route['drivers'] : [];

      if (emails.length > 0) {
        const update = {
          drivers: emails.filter(e=>e.id !== id),
        }
        const response = await apiClient.patch(`route/${route_id}/`, update);
        return { status: response.status, data: response.data };
      }

    } catch (e) {
      console.log(e, 'error');
    }

    return undefined;
  }
}
